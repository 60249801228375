import { useState } from "react";
import FileUpload from "../../../../assets/common/FileUpload";
import { Alert } from "@material-tailwind/react";
import fileService from "../../../../services/fileService";
import useClient from "../../../../hooks/useClient";

export default function AddDocuments({ fetchClientDocuments, isToggle }) {
  const { activeClient } = useClient();
  const [uploadedFiles, setUploadedFiles] = useState();
  const token = localStorage.getItem("token");
  // const [uploadProgress, setUploadProgress] = useState(0);
  // const [uploadedFile, setUploadedFile] = useState(null);

  const handleUploadedFiles = (e) => {
    setUploadedFiles(e.target.files)
  }

  const uploadDocuments = async () => {
    if (uploadedFiles) {
      const file = uploadedFiles;
      console.log(file)
      const formData = new FormData();
      Array.from(uploadedFiles).forEach((file) => {
        formData.append(`file`, file);
      });

      try {
        const response = await fileService.uploadFile(activeClient.id, formData, token)
        console.log(response)
        isToggle();
        fetchClientDocuments(activeClient)
      }
      catch (error) {
        console.log(error)
      }
    }
  }
  return (
    <>
      <div className="w-full h-full bg-black opacity-40 fixed top-0 left-0 z-10"></div>
      <div className="w-4/5 lg:w-[500px] max-md:max-h-[500px] overflow-scroll mx-auto pb-16 bg-white fixed top-0 right-0 z-20">
        <div className="flex items-center justify-between border-b border-cyan-900 p-5 w-full">
          <div className="flex items-center w-full">
            <div className=" text-black text-lg">Attach Document</div>
          </div>
          <div onClick={isToggle} className="cursor-pointer" >
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.1726 5.7832L5.97229 16.9835"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.97217 5.78345L17.1725 16.9838"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="px-5">
          <div className="mt-7 text-lg"><span className="text-[#00B67A] mr-2">.pdf, .jpg, .png</span> Upload Only</div>
          <div className="border-2 border-[#00B67A] p-3 my-3">
            <input type="file" multiple onChange={handleUploadedFiles} accept=".pdf, .png, .jpeg, .jpg" />
            {/* <FileUpload
                token={token}
                uploadedFile={uploadedFile}
                setUploadedFile={setUploadedFile}
                uploadProgress={uploadProgress}
                setUploadProgress={setUploadProgress}
                showAlert={showAlert}
                isToggle={isToggle}
            /> */}
          </div>
          <button onClick={uploadDocuments} className="w-full my-4 text-white bg-emerald-500 text-base font-medium rounded-md pl-6 pr-7 py-4 max-md:px-5">
            Upload Document
          </button>
        </div>
      </div>
    </>
  );
}
