import React from "react";
import RightArrow from "../../../assets/basic/RightArrow";
import LeftArrow from "../../../assets/basic/leftArrow";

const SectionSubmit = ({ handlePrevious, handleNext }) => {
  return (
    <>
        <div className="mt-10 bg-[#E0A045] py-[1rem] rounded-lg flex justify-flex-start items-center px-5 border-2" id="termsConditionsBox">
            <input type="checkbox" id="termsAndPrivacyCheckbox" className="h-5 w-5 rounded-lg font-semibold"/>
            <label className="ml-5 text-[#244B69]" htmlFor="termsAndPrivacyCheckbox">
              I agree to the terms & conditions and the privacy policy.
            </label>
        </div>
        <div className="flex justify-between items-center mt-[5%]">
          <button onClick={handlePrevious} className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center">
            <LeftArrow />
          </button>
          <button onClick={handleNext} className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center">
            <RightArrow />
          </button>
        </div>
    </>
  );
}

export default SectionSubmit;