import { useNavigate, useParams } from "react-router";

export default function BackButtonUserArea() {
    const navigate = useNavigate();
    const params = useParams();
    const handleBack = () => {
        navigate(`/`);
    }

    return (
        <button 
            className="flex gap-4 items-center"
            onClick={handleBack}
        >
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.156 8.07202H1.15601" stroke="#7FC3C3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8.15601 15.072L1.15601 8.07202L8.15601 1.07202" stroke="#7FC3C3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <span className="text-[#7FC3C3]">Back to User Area</span>
        </button>
    )
}