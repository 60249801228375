import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import UserContext from "./userContext";
import clientService from "../../services/clientService";
import claimService from "../../services/claimService";
import incomeSourceService from "../../services/incomeSourceService";
import planService from "../../services/planService";
import userService from "../../services/userService";
import useClient from "../../hooks/useClient";
import { RenderRoutes } from "../../routes/RenderNavigation";
import { signal } from "@preact/signals-core";
import jwtDecode from "jwt-decode";

const checkAppLocals = () => localStorage.getItem("token") ? true : false
export const tokenSignal = signal(checkAppLocals());

const UserState = ({ children }) => {

  const [activeUser, setActiveUser] = useState({})
  const [userMode, setUserMode] = useState("");
  const [tokenCheck, setTokenCheck] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [userLogin, setUserLogin] = useState({
    userEmail: "",
    userPassword: "",
  });

  const { setActiveClient } = useClient()
  let navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const mode = localStorage.getItem('userMode');

    if (mode) {
      setUserMode(mode);
    }

    if (token && !userMode && !isTokenExpired(token)) {
      fetchUser(token).then(() => {
        setLoading(false);
      });
    } else {
      token && isTokenExpired(token) && logoutSession()
      setLoading(false);
    }
  }, [tokenCheck, userMode]);

  function isTokenExpired(token) {
    const decodedToken = jwtDecode(token);
    const expirationTime = decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    return expirationTime < currentTime;
  }

  const fetchUser = async token => {
    userService.getUserByToken(token).then(data => {
      setTokenCheck(true)
      setActiveUser(data);
      setClientfromUser(data)
    }).catch(error => {
      localStorage.clear();
    })
  }

  const setClientfromUser = async existingUser => {
    if (existingUser.role === "client") {
      const client = await clientService.getClientByUserId(existingUser.id)
      if (client) {
        console.log(client)
        await setActiveClient(client)
        updateUserMode("client")
      } else {
        console.log("error in setClientfromUser")
      }
    } else {
      updateUserMode("agent")
    }
  }

  const updateUserMode = mode => {
    localStorage.setItem('userMode', mode);
    setUserMode(mode)
  }

  useEffect(() => {
    console.log(userMode);
  }, [userMode])

  const handleLogin = async (e) => {
    e.preventDefault()
    console.log(userLogin)
    try {
      const loginResponse = await userService.loginUser(userLogin);
      if (loginResponse.user) {
        localStorage.setItem('token', loginResponse.token);
        tokenSignal.value = checkAppLocals()
        setTokenCheck(true)
        const client = await clientService.getClientByUserId(loginResponse.user.id)
        if (loginResponse.user.role === 'agent') {
          console.log(loginResponse.user.role)
          updateUserMode("agent")
          // navigate(`/crm`);
        } else {
          console.log(client)
          updateUserMode("client")
          await setActiveClient(client)
          // navigate(`/`);
        }
        navigate(`/`);
        setLoginError("")
      }
    }
    catch (error) {
      setTimeout(() => {
        setLoginError("")
      }, 3000);
      setLoginError("Your Password is incorrect! Please try again.");
      // setLoginError(error.response.data.error);
    }
  }

  const logoutSession = () => {
    // tokenSignal.value= false;
    setTokenCheck(false)
    localStorage.clear()
    tokenSignal.value = checkAppLocals()
    setActiveUser({})
    setActiveClient({})
    setUserMode("")
  }


  return (
    <UserContext.Provider value={{ activeUser, setActiveUser, tokenCheck, setTokenCheck, handleLogin, loginError, setLoginError, userLogin, setUserLogin, userMode, setUserMode, updateUserMode, loading, setLoading, logoutSession }}>
      <RenderRoutes />
    </UserContext.Provider>
  )
}

export default UserState