import { Link } from "react-router-dom";
import Modal from "../Modal";

export default function Minifooter(){
    return(
        <>
            <div className="p-4 font-poppins max-lg:mb-20">
               <ul className="flex items-center justify-around max-w-[25rem] mx-auto">
                 <li className="font-normal text-sm text-[#244B69]">Cookie policy</li>
                 <li className="font-normal text-sm text-[#244B69]"><Link to={"/privacy-policy"}>Privacy policy</Link></li>
                 <li className="font-normal text-sm text-[#244B69]"><Link to={"/terms-and-conditions"}>Terms of service</Link></li>
                 {/* <li className="font-normal text-sm text-[#244B69]"><Modal text="Privacy policy" privacy/></li>
                 <li className="font-normal text-sm text-[#244B69]"><Modal text="Terms of service" termsConditions/></li> */}
               </ul>
               <div className="font-normal text-sm text-[#244B69] text-center pt-2">© 2023 Tax Magpie. All rights reserved.</div>
            </div>
        </>
    )
}