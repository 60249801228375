import { Fragment, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackButtonUserArea from "./BackButtonUserArea";
import Article from "../../assets/common/Article";

function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    );
}

export default function Guides() {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <div className="font-poppins py-20" style={{background: 'linear-gradient(180deg, rgba(159, 210, 210, 0.36) 0%, rgba(159, 210, 210, 0.00) 108.54%)'}}>
                <div className="mx-auto max-w-7xl px-5 xl:px-0">
                    <BackButtonUserArea/>
                    <Fragment>
                        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                            <AccordionHeader
                            onClick={() => handleOpen(1)}
                            className="bg-[#244B69] p-10 text-white font-bold lg:text-[1.5rem] text-[1rem] py-5 mt-5 text-start"
                            >
                                Landlord
                            </AccordionHeader>
                            <AccordionBody className="px-10 p-10 text-start bg-white text-[#244B69] lg:text-lg text-sm font-medium flex flex-col space-y-5">
                                <Article filename="Rental Income and Taxation for UK Property Owners.pdf" />
                                <Article filename="Renting Out Property and Tax Considerations.pdf" />
                                <Article filename="Private Ownership of Property vs Limited Company.pdf" />
                                <Article filename="Joint Ownership of Rental Property Taxation and Options.pdf" />
                                <Article filename="A Guide to Renting Out Property as a Resident Landlord.pdf" />
                                <Article filename="Furnished Holiday Lettings (FHLs).pdf" />
                                <Article filename="Navigating the Tax Maze Furnished Holiday Lets vs Residential Lets.pdf" />
                                <Article filename="Navigating Furnished Holiday Lettings (FHLs).pdf" />
                            </AccordionBody>
                        </Accordion>


                        <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                            <AccordionHeader
                            onClick={() => handleOpen(2)}
                            className="bg-[#244B69] p-10 text-white font-bold lg:text-[1.5rem] text-[1rem] py-5 mt-5 text-start"
                            >
                                Self-employed
                            </AccordionHeader>
                            <AccordionBody className="px-10 p-10 text-start bg-white text-[#244B69] lg:text-lg text-sm font-medium flex flex-col space-y-5">
                                <Article filename="Reporting covid grants and payments.pdf" />
                                <Article filename="A Guide to Deducting Business Expenses for Self-Employed Individuals.pdf" />
                                <Article filename="Small Business Tax Tips – What Records to Keep and Why.pdf" />
                                <Article filename="Self-Assessment for Foreigners in the UK – Reporting Foreign Income.pdf" />
                                <Article filename="Understanding What Expenses Can't Be Claimed on Your Tax Return.pdf" />
                                <Article filename="Vehicle Use and Allowances for Businesses.pdf" />
                                <Article filename="Capital Allowances.pdf" />
                                <Article filename="Understanding Flat Rates for Self-Employed Individuals in the UK.pdf" />
                                <Article filename="Capital Allowances in the UK for Businesses.pdf" />
                                <Article filename="Cash Basis vs Traditional Accounting.pdf" />
                                <Article filename="37 Do I need to keep receipts.pdf" />
                            </AccordionBody>
                        </Accordion>


                        <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                            <AccordionHeader
                            onClick={() => handleOpen(3)}
                            className="bg-[#244B69] p-10 text-white font-bold lg:text-[1.5rem] text-[1rem] py-5 mt-5 text-start"
                            >
                                Employed
                            </AccordionHeader>
                            <AccordionBody className="px-10 p-10 text-start bg-white text-[#244B69] lg:text-lg text-sm font-medium flex flex-col space-y-5">
                                <Article filename="Claiming Tax Relief for Job Expenses in the UK.pdf" />
                                <Article filename="Navigating the High Income Child Benefit Charge in the UK.pdf" />
                                <Article filename="why do I need to include my employment on my self assessment.pdf" />
                                <Article filename="Claiming tax relief if employed.pdf" />
                            </AccordionBody>
                        </Accordion>


                        <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                            <AccordionHeader
                            onClick={() => handleOpen(4)}
                            className="bg-[#244B69] p-10 text-white font-bold lg:text-[1.5rem] text-[1rem] py-5 mt-5 text-start"
                            >
                                Understanding of your finances
                            </AccordionHeader>
                            <AccordionBody className="px-10 p-10 text-start bg-white text-[#244B69] lg:text-lg text-sm font-medium flex flex-col space-y-5">
                                <Article filename="Understanding Taxes on Stocks Dividends and Bonuses in the UK.pdf" />
                                <Article filename="Adding Pension Contributions to Your Tax Return.pdf" />
                                <Article filename="Declaring Student Loans on Your Self-Assessment Tax Return.pdf" />
                                <Article filename="Guide to Capital Gains Tax (CGT).pdf" />
                                <Article filename="Understanding Married Couple's Allowance in the UK.pdf" />
                                <Article filename="Cash Gifts and Tax in the UK.pdf" />
                                <Article filename="Marriage Allowance in the UK.pdf" />
                                <Article filename="Navigating Tax Reporting for Gilt-Edged and Other UK Securities.pdf" />
                                <Article filename="Reporting of Trust Income on a UK Self-Assessment Tax Return.pdf" />
                                <Article filename="Bank interest – what needs to be calculated.pdf" />
                                <Article filename="ISA.pdf" />
                                <Article filename="Pensions, Annuities, and Benefits on Your Tax Return.pdf" />
                                <Article filename="Demystifying Dividends – A Guide to Shareholder Earnings.pdf" />
                                <Article filename="Bonuses on investments.pdf" />
                            </AccordionBody>
                        </Accordion>


                        <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                            <AccordionHeader
                            onClick={() => handleOpen(5)}
                            className="bg-[#244B69] p-10 text-white font-bold lg:text-[1.5rem] text-[1rem] py-5 mt-5 text-start"
                            >
                                Additional Information
                            </AccordionHeader>
                            <AccordionBody className="px-10 p-10 text-start bg-white text-[#244B69] lg:text-lg text-sm font-medium flex flex-col space-y-5">
                                <Article filename="Claiming Tax Relief for Job Expenses in the UK.pdf" />
                                <Article filename="Understanding Unique Tax Reference (UTR).pdf" />
                                <Article filename="Blind persons allowance.pdf" />
                                <Article filename="Charitable giving in the UK.pdf" />
                                <Article filename="Gains from  life insurance.pdf" />
                                <Article filename="Understanding your tax bill.pdf" />
                                <Article filename="Demystifying the UTR Number Your Key to Self Assessment.pdf" />
                            </AccordionBody>
                        </Accordion>
                    </Fragment>
                </div>
            </div>
        </>
    )
}