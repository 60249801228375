
const ValidateDate = (value) => {
    const currentDate = new Date();
    const enteredDate = new Date(value);
    if (enteredDate > currentDate) {
      return 'Invalid date format';
    } else {
      return '';
    }
};

export default ValidateDate;
