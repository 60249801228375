export default function Surety(){
    return(
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 bg-[#7FC3C3] p-12">
            <div className="grid md:grid-cols-3 grid:grid-rows-3 text-center">
                <div>
                    <div className="font-bold lg:text-xl text-base pb-4">Painless tax returns</div>
                    <p className="md:text-sm text-xs max-w-[17rem] mx-auto pb-2">It’s a simple online process. Fast, efficient
                        and a whole lot less scary than doing it
                        yourself.
                    </p>
                </div>
                <div>
                    <div className="font-bold lg:text-xl text-base pb-4">Peace of mind</div>
                    <p className="md:text-sm text-xs max-w-[17rem] mx-auto pb-2">No more worrying about missing a rebate or
                        making a mistake. Get your return drafted by
                        a real, accredited accountant.
                    </p>
                </div>
                <div>
                    <div className="font-bold lg:text-xl text-base pb-4">Tailored</div>
                    <p className="md:text-sm text-xs max-w-[17rem] mx-auto pb-2">
                    Our different pricing structures are budget friendly and allow you to only pay for the services you need.
                    </p>
                </div>
            </div>
          </div>
        </>
    )
}