import RightArrow from "../../assets/basic/RightArrow";
import Surety from "../../assets/Surety";
export default function FormSix({ onNext }) {
  return (
    <>
      <div className="mx-auto max-w-7xl bg-[#D9D9D9]/[29%] py-10 mt-5 font-poppins md:px-10 px-5">
        <div className="md:text-3xl text-lg font-medium text-white bg-[#E0A045] w-full py-6 md:px-10 px-5">
          What happens now?.
        </div>
        <div className="font-semibold md:text-lg text-base py-5">
          We will be in touch via email to let you know what information we
          need. In the meantime if you can gather all of the following
          information as necessary:
        </div>
        <ul className="">
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              This is your unique taxpayer reference number or Tax reference.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              It is a 10 digit code provided to you by HMRC when you register
              for self assessment.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              You do need this in order to submit your self assessment.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              This is your unique taxpayer reference number or Tax reference.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-semibold md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              It is a 10 digit code provided to you by HMRC when you register
              for self assessment.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-semibold md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              You do need this in order to submit your self assessment.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-semibold md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              This is your unique taxpayer reference number or Tax reference.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/yellowEllipse.png" alt="" />
            <li className="font-semibold md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              It is a 10 digit code provided to you by HMRC when you register
              for self assessment.
            </li>
          </div>
        </ul>
      </div>
      <div className=" mx-auto max-w-7xl flex justify-end my-10 lg:px-[10rem] px-[2rem]">
        <button onClick={onNext} className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center">
          <RightArrow />
        </button>
      </div>
      <div className="my-10">
        <Surety />
      </div>
    </>
  );
}
