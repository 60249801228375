import { Link, Routes, Route, Navigate } from "react-router-dom";
import useUser from "../hooks/useUser";
import { nav } from "../routes/navigation"
import Main from "../assets/common/Main";
import UserArea from "../components/userPage/userArea";
import CRM from "../components/crm/CRM";
import Home from "../views/HomeView";
import { tokenSignal } from "../context/user/UserState";

export const RenderRoutes = () => {
    const { userMode, tokenCheck } = useUser()

    // console.log(tokenSignal.value)

    return (
        <Routes>
            <Route element={<Main/>}>
                { nav.map((route, index) => {
                    if (tokenSignal.value) {
                        // if (route.isPrivate) {
                            if (userMode === "agent" && route.isAgent) {
                                return <Route key={index} path={route.path} element={route.element}/>
                            } else if (tokenCheck && userMode === "client" && route.isClient) {
                                return <Route key={index} path={route.path} element={route.element}/>
                            } else return false
                        // }
                    } else if (!route.isPrivate) {
                        return <Route key={index} path={route.path} element={route.element}/>
                    } else return false
                })}
                
                <Route path="/*" element={(userMode === "agent") ? <CRM /> : (userMode === "client") ? <UserArea /> : <Home /> } />
            </Route>
        </Routes>
    )
}