import { useState, useRef, useEffect } from "react";
import { AddressFinder } from "@ideal-postcodes/address-finder";
import SectionOne from "./FormTwo/SectionOne";
import SectionTwo from "./FormTwo/SectionTwo";
import SectionThree from "./FormTwo/SectionThree";
import SectionSubmit from "./FormTwo/SectionSubmit";
import ValidateDate from "../../utils/ValidateDate";
import ValidateEmail from "../../utils/ValidateEmail";
import ValidateNi from "../../utils/ValidateNi";
import ValidateMobileNumber from "../../utils/ValidateMobileNumber";
import IsObjectEmpty from "../../utils/IsObjectEmpty";
import useClient from "../../hooks/useClient";
import useUser from "../../hooks/useUser";
import clientService from "../../services/clientService";
import ValidateName from "../../utils/ValidateName";
import IsObjectFull from "../../utils/IsObjectFull";
import { toast } from "react-toastify";
// import { PostcodeLookup } from '@ideal-postcodes/postcode-lookup';

export default function FormTwo({ onNext, onPrevious }) {
  const { setActiveClient, setActiveClaim, formData, activeClaim } = useClient();
  const { setLoading } = useUser();

  const [userOption, setUserOption] = useState({
    taxType: formData.userOption?.taxType || "",
  });
  const [userInfo, setUserInfo] = useState({
    firstname: formData.userInfo?.firstname || "",
    lastname: formData.userInfo?.lastname || "",
    address: formData.userInfo?.address || "",
    postcode: formData.userInfo?.postcode || "",
    dob: formData.userInfo?.dob || "",
    nic: formData.userInfo?.nic || "",
    emailAddress: formData.userInfo?.emailAddress || "",
    telephone: formData.userInfo?.telephone || "",
  });

  const [dataError, setDataError] = useState({
    firstname: "",
    lastname: "",
    dob: "",
    nic: "",
    emailAddress: "",
    telephone: ""
  })

  const [userData, setUserData] = useState({
    sourceOfIncome: formData.userData?.sourceOfIncome || [],
  });
  // const [telephoneValidation, setTelephoneValidation] = useState({
  //   isValid: false,
  //   errorMessage: "",
  // });
  // const [telephoneValidated, setTelephoneValidated] = useState(false);

  const shouldRenderAddress = useRef(true);

  useEffect(() => {
    // if (userInfo.telephone && userInfo.telephone.length === 11) {
    //   validateTelephone(userInfo.telephone);
    // } 

    if (!shouldRenderAddress.current) return;
    shouldRenderAddress.current = false;

    AddressFinder.watch({
      apiKey: "ak_llc71tx7m2oFfSJkQG2BgQNtRPHV9",
      outputFields: {
        line_1: "#address",
        postcode: "#postcode"
      },
      detectCountry: false,
      hideToolbar: true,
      onAddressPopulated: (returnedAddressData) => {
        console.log(returnedAddressData)
        const mergedAddress = `${returnedAddressData.line_1}, ${returnedAddressData.line_2} ${returnedAddressData.line_3}, ${returnedAddressData.post_town}`;
        setUserInfo((prevFormData) => ({
          ...prevFormData,
          address: mergedAddress,
          postcode: returnedAddressData.postcode
        }));
      }
    });
  }, []);

  // const validateTelephone = async (value) => {
  //   let { validation, validated } = await ValidateMobileNumber(value);
  //   setTelephoneValidation(prev => ({ ...prev, ...validation }));
  //   setTelephoneValidated(validated)
  // }

  const handleChange1 = (event) => {
    document.getElementById("taxHeading").classList.remove("text-red-500")
    const { name, value, type, checked } = event.target;
    setUserOption((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleChange2 = async (event) => {
    event.target.classList.remove("border-red-500")
    let { name, value } = event.target;
    let error = ""
    if (name === "firstname" || name === "lastname") {
      const validationError = ValidateName(value);
      if (value.length < 2) {
        error = "Please enter a name with at least 2 characters."
      } else if (validationError) {
        error = validationError
        return;
      } else {
        error = ""
      }
    }
    else if (name === 'dob') {
      error = ValidateDate(value)
    }
    else if (name === "telephone") {
      if (value.length > 11) {
        return;
      }
      else if (value.length === 11) {
        error = ValidateMobileNumber(value)
      }
      else if (value.length < 11) {
        error = "Incomplete mobile number"
      }
    }
    else if (name === 'emailAddress') {
      error = ValidateEmail(value)
    }
    else if (name === 'nic') {
      value = value.toUpperCase();
      error = ValidateNi(value)
    }
    setUserInfo((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setDataError((prevDataError) => ({
      ...prevDataError,
      [name]: error,
    }))
  };
  const handleChange = (event) => {
    document.getElementById("incomeHeading").classList.remove("text-red-500")
    const value = event.target.id;
    if (userData.sourceOfIncome.includes(value)) {
      setUserData((prevState) => {
        return {
          ...prevState,
          sourceOfIncome: prevState.sourceOfIncome.filter(
            (item) => item !== value
          ),
        };
      });
    } else {
      setUserData((prevState) => {
        return {
          ...prevState,
          sourceOfIncome: [...prevState.sourceOfIncome, value],
        };
      });
    }
  };


  const niRefs = {
    first: useRef(null),
    second: useRef(null),
    third: useRef(null),
    fourth: useRef(null),
    fifth: useRef(null),
  };

  const inputRefs = useRef([]);
  const handleKeyDown = (event, index) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      event.preventDefault();
      const nextIndex = (index + 1) % inputRefs.current.length;
      const nextInput = inputRefs.current[nextIndex];
      console.log(nextInput);
      if ( nextInput === inputRefs.current[4] ) {
        niRefs.first.current.getInputDOMNode().focus();
      } else {
        nextInput.focus();
      }
    }
    if (event.target.name === "address" && (event.keyCode === 8 || event.key === "Backspace")) {
      setUserInfo(prevUserInfo => ({
        ...prevUserInfo,
        address: "",
        postcode: ""
      }))
    }
  };

  const focusElement = (element) => {
    setTimeout(() => {
      element.classList.add('border-red-500');
      element.focus();
    }, 700);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  function userTaxSelection() {
    return Object.values(userOption).some((checked) => checked);
  }
  function isAtLeastOneCheckboxChecked() {
    return Object.values(userData.sourceOfIncome).some((checked) => checked);
  }
  const handleNext = () => {
    const checkboxElement = document.getElementById('termsAndPrivacyCheckbox');
    const taxHeading = document.getElementById('taxHeading');
    const incomeHeading = document.getElementById('incomeHeading');
    const termsConditionsBox = document.getElementById('termsConditionsBox');

    console.log(userInfo);
    if (!userTaxSelection()) {
      taxHeading.classList.add("text-red-500")
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return false;
    }

    else if (IsObjectFull(userInfo)) {
      for (let i = 0; i < inputRefs.current.length; i++) {
        if (i === 4) {
          const inputElement = inputRefs.current[i].getInputDOMNode();
          const inputValue = inputElement.value; // Get the value
          if (!inputValue) {
            focusElement(inputElement);
            return false;
          }
        }
        else if (!inputRefs.current[i].value) {
          focusElement(inputRefs.current[i]);
          return false;
        }
      }
    }

    else if (!IsObjectEmpty(dataError)) {
      for (const key of Object.keys(dataError)) {
        if (dataError[key] !== "") {
          let inputElement;
          if (key === 'dob') {
            inputElement = inputRefs.current[3]
          } else if (key === 'nic') {
            inputElement = inputRefs.current[4].getInputDOMNode();
          } else if (key === 'emailAddress' || key === 'telephone') {
            inputElement = inputRefs.current[key === 'emailAddress' ? 5 : 6];
          } else {
            const inputIndex = Object.keys(dataError).indexOf(key);
            inputElement = inputRefs.current[inputIndex];
          }
          focusElement(inputElement);
          return false;
        }
      }
    }

    else if (!isAtLeastOneCheckboxChecked()) {
      incomeHeading.classList.add("text-red-500")
      incomeHeading.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
      return false;
    }

    else if (!checkboxElement.checked) {
      termsConditionsBox.classList.add('border-red-500')
      focusElement(checkboxElement);
      toast.warning("Please check the box to agree to the terms & conditions and privacy policy.")
      return false;
    }

    setLoading(true);
    clientService.createOrUpdate({ ...userInfo, ...userOption, claimId: activeClaim.id }).then(data => {
      console.log(data)
      setActiveClient(data.client)
      setActiveClaim(data.claim)
      onNext({ userData, userInfo, userOption });
    }).catch((error) => {
      setLoading(false)
      toast.error("An error occured.")
      console.log(error);
    })

  };

  const handlePrevious = () => {
    onPrevious();
  }

  return (
    <>
      <div className="mx-auto max-w-7xl lg:px-[10rem] px-[2rem] bg-[#D9D9D9]/[29%] py-20 mt-5 font-poppins">
        <SectionOne userOption={userOption} handleChange1={handleChange1} />
        <SectionTwo
          userInfo={userInfo}
          setUserInfo={setUserInfo}
          handleChange2={handleChange2}
          dataError={dataError}
          setDataError={setDataError}
          handleKeyDown={handleKeyDown}
          inputRefs={inputRefs}
          niRefs={niRefs}
        />
        <SectionThree userData={userData} handleChange={handleChange} />
        <SectionSubmit handlePrevious={handlePrevious} handleNext={handleNext} />
      </div>
    </>
  );
}
