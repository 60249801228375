

const SingleDocument = ({ doc, deleteFile }) => {

  const handleDownload = (doc) => {
    // Create a temporary anchor element to trigger the download
    const a = document.createElement("a");
    a.href = doc.path;
    a.download = doc.originalname;
    a.style.display = "none";

    // Trigger the download by simulating a click
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const openImage = (doc) => {
    const newTab = window.open("", "_blank");
    newTab.document.write(`<html>
    <head>
      <style>
        body {
          margin: 0;
          overflow: hidden;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          display: block;
          margin: auto;
        }
      </style>
    </head>
    <body>
      <img src="${doc.path}" />
    </body>
  </html>`);
  }

  return (
    <>
      <div className="relative">
        {doc.originalname.substr(-3) === "pdf" ? (
          <div
            className="flex flex-col justify-center items-center p-5 lg:shadow-[2px_3px_3.4px_0px_rgba(0,0,0,0.25)] h-full w-full min-h-[10rem] max-h-[12rem] cursor-pointer"
            onClick={() => window.open(doc.path, "_blank")}>
            <img src="../images/pdf-image.png" className="w-32" alt="PDF" />
          </div>
        ) : (
          <img
            src={doc.path}
            alt="Documents Preview"
            className="lg:shadow-[2px_3px_3.4px_0px_rgba(0,0,0,0.25)] object-cover h-full w-full min-h-[10rem] max-h-[12rem] cursor-pointer"
            onClick={() => openImage(doc)}
          />
        )}
        <div className="absolute top-0 w-full flex items-center gap-10 justify-between p-2 bg-white/10 opacity-0 hover:opacity-100 backdrop-blur-sm">
          <div className="w-1/2">
            <p className=" whitespace-nowrap overflow-hidden text-ellipsis">{doc.originalname}</p>
          </div>
          <div className="flex gap-2">
            <button onClick={() => handleDownload(doc)}
            // onClick={
            // doc.fileType === "pdf" ? handleDownloadPdf : handleDownloadImage
            // }
            >
              <svg
                width="19"
                height="20"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.125 8.22919V10.5347C11.125 10.8405 11.0035 11.1337 10.7874 11.3499C10.5712 11.5661 10.278 11.6875 9.97222 11.6875H1.90278C1.59704 11.6875 1.30383 11.5661 1.08764 11.3499C0.871453 11.1337 0.75 10.8405 0.75 10.5347V8.22919"
                  stroke="#00B67A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M3.05542 5.34723L5.93736 8.22917L8.81931 5.34723"
                  stroke="#00B67A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.9375 8.22917V1.3125"
                  stroke="#00B67A"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button onClick={() => deleteFile(doc)}>
              <svg
                width="18"
                height="23"
                viewBox="0 0 20 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 5.89938H3H19"
                  stroke="#B60C0C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17 5.89938V19.8994C17 20.4298 16.7893 20.9385 16.4142 21.3136C16.0391 21.6887 15.5304 21.8994 15 21.8994H5C4.46957 21.8994 3.96086 21.6887 3.58579 21.3136C3.21071 20.9385 3 20.4298 3 19.8994V5.89938M6 5.89938V3.89938C6 3.36895 6.21071 2.86024 6.58579 2.48517C6.96086 2.1101 7.46957 1.89938 8 1.89938H12C12.5304 1.89938 13.0391 2.1101 13.4142 2.48517C13.7893 2.86024 14 3.36895 14 3.89938V5.89938"
                  stroke="#B60C0C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 10.8994V16.8994"
                  stroke="#B60C0C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12 10.8994V16.8994"
                  stroke="#B60C0C"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>

          </div>
        </div>
      </div>
    </>
  );
};

export default SingleDocument;
