
import { useEffect, useState } from "react";
import BackButtonUserArea from "./BackButtonUserArea";
import fileService from "../../services/fileService";
import useClient from "../../hooks/useClient";
import DocumentsTab from "../crm/crm-component/crmTabs/DocumentsTab";
import CrmTabsState from "../../context/tabs/CrmTabsState";

export default function Documents() {

    return (
        <>
            <div className="font-poppins py-20 min-h-screen" style={{ background: 'linear-gradient(180deg, rgba(159, 210, 210, 0.36) 0%, rgba(159, 210, 210, 0.00) 108.54%)' }}>
                <div className="mx-10 bg-white p-10 min-h-[inherit]">
                    <BackButtonUserArea />
                    <CrmTabsState>
                        <DocumentsTab/>
                    </CrmTabsState>
                </div>
            </div>
        </>
    )
}