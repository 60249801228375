import { isEmail } from 'validator';

const ValidateEmail = (value) => {
    if (!isEmail(value)) {
      return 'Invalid email address';
    } else {
      return '';
    }
};

export default ValidateEmail;
