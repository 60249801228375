import { useState } from "react";
import RightArrow from "../../assets/basic/RightArrow";
import LeftArrow from "../../assets/basic/leftArrow";
import useClient from "../../hooks/useClient";
import incomeSourceService from "../../services/incomeSourceService";
import useUser from "../../hooks/useUser";
import { toast } from "react-toastify";

export default function FormThree({ onPrevious, onNext }) {

  const { formData, activeClaim, activeClient } = useClient();
  const { setLoading } = useUser();
  const [inputAmount, setInputAmount] = useState({
      self_employment : {
        income: formData.inputAmount?.self_employment?.income || "",
        expenditure: formData.inputAmount?.self_employment?.expenditure || "",
      },
      employment : {
        income: formData.inputAmount?.employment?.income || "",
      },
      property : {
        income: formData.inputAmount?.property?.income || "",
        expenditure: formData.inputAmount?.property?.expenditure || "",
      },
      saving_investment : {
        income: formData.inputAmount?.saving_investment?.income || "",
      },
      pension : {
        income: formData.inputAmount?.pension?.income || "",
      },
      furnished_holiday : {
        income: formData.inputAmount?.furnished_holiday?.income || "",
        expenditure: formData.inputAmount?.furnished_holiday?.expenditure || "",
      },
      trusts : {
        income: formData.inputAmount?.trusts?.income || "",
      },
      rent_room : {
        income: formData.inputAmount?.rent_room?.income || "",
        expenditure: formData.inputAmount?.rent_room?.expenditure || "",
      },
      minister_of_religion : {
        income: formData.inputAmount?.minister_of_religion?.income || "",
      },
      non_resident : {
        income: formData.inputAmount?.non_resident?.income || "",
      },
      partnership : {
        income: formData.inputAmount?.partnership?.income || "",
        expenditure: formData.inputAmount?.partnership?.expenditure || "",
      },
      foreign : {
        income: formData.inputAmount?.foreign?.income || "",
      },
      capital_gains : {
        income: formData.inputAmount?.capital_gains?.income || "",
        expenditure: formData.inputAmount?.capital_gains?.expenditure || "",
      },
      donations : {
        income: formData.inputAmount?.donations?.income || "",
      },

  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const [source, type] = name.split("-");

    setInputAmount((prevFormData) => ({
      ...prevFormData,
      [source]: {
        ...prevFormData[source],
        [type]: value,
      },
    }));
  };

  function allInputsFilled() {
    const inputs = document.querySelectorAll('input');
  
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.closest('.visibleSource') && !input.value.trim()) {
        return false;
      }
    }
  
    return true;
  }

  const handleNext = () => {

    if (!allInputsFilled()) {
      toast.warning("Please fill all the fields.")
      return false;
    }

    setLoading(true)
    incomeSourceService.createOrUpdate({...formData?.userData, ...inputAmount, claim: activeClaim.id, client: activeClient.id}).then((data) => {
      console.log(data);
      onNext({ inputAmount });
    })
    .catch((error) => {
      setLoading(false)
      console.log(error);
    })
  };

  const handlePrevious = () => {
    onPrevious();
  };
  console.log(formData);

  return (
    <div className="mx-auto max-w-7xl lg:px-[10rem] px-[2rem] bg-[#D9D9D9]/[29%] py-20 mt-5 font-poppins min-h-[90vh]">
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-x-[2rem] gap-y-[4rem] text-center">
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("self_employment") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Self employment
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("self_employment") && "visibleSource"} arrow-hidden`}
            placeholder="Income"
            name="self_employment-income"
            value={inputAmount.self_employment.income}
            onChange={handleChange}
          />
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("self_employment") && "visibleSource"}`}
            placeholder="Expenditures"
            name="self_employment-expenditure"
            value={inputAmount.self_employment.expenditure}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("employment") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Employment
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("employment") && "visibleSource"}`}
            placeholder="Income"
            name="employment-income"
            value={inputAmount.employment.income}
            onChange={handleChange}
            id="tax"
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("property") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Property
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("property") && "visibleSource"}`}
            placeholder="Income"
            name="property-income"
            value={inputAmount.property.income}
            onChange={handleChange}
          />
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("property") && "visibleSource"}`}
            placeholder="Expenditures"
            name="property-expenditure"
            value={inputAmount.property.expenditure}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("saving_investment") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Saving & Investment
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("saving_investment") && "visibleSource"}`}
            placeholder="Income"
            name="saving_investment-income"
            value={inputAmount.saving_investment.income}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("pension") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Pension
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("pension") && "visibleSource"}`}
            placeholder="Income"
            name="pension-income"
            value={inputAmount.pension.income}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("furnished_holiday") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Furnished holiday lettings
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("furnished_holiday") && "visibleSource"}`}
            placeholder="Income"
            name="furnished_holiday-income"
            value={inputAmount.furnished_holiday.income}
            onChange={handleChange}
          />
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("furnished_holiday") && "visibleSource"}`}
            placeholder="Expenditures"
            name="furnished_holiday-expenditure"
            value={inputAmount.furnished_holiday.expenditure}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("trusts") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Trusts
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("trusts") && "visibleSource"}`}
            placeholder="Income"
            name="trusts-income"
            value={inputAmount.trusts.income}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("rent_room") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Rent a Room
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("rent_room") && "visibleSource"}`}
            placeholder="Income"
            name="rent_room-income"
            value={inputAmount.rent_room.income}
            onChange={handleChange}
          />
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("rent_room") && "visibleSource"}`}
            placeholder="Expenditures"
            name="rent_room-expenditure"
            value={inputAmount.rent_room.expenditure}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("minister_of_religion") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Minister of religion
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("minister_of_religion") && "visibleSource"}`}
            placeholder="Income"
            name="minister_of_religion-income"
            value={inputAmount.minister_of_religion.income}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("non_resident") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Non resident
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("non_resident") && "visibleSource"}`}
            placeholder="Income"
            name="non_resident-income"
            value={inputAmount.non_resident.income}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("partnership") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Partnership
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("partnership") && "visibleSource"}`}
            placeholder="Income"
            name="partnership-income"
            value={inputAmount.partnership.income}
            onChange={handleChange}
          />
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("partnership") && "visibleSource"}`}
            placeholder="Expenditures"
            name="partnership-expenditure"
            value={inputAmount.partnership.expenditure}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("foreign") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Foreign
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("foreign") && "visibleSource"}`}
            placeholder="Income"
            name="foreign-income"
            value={inputAmount.foreign.income}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("capital_gains") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Capital gains
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("capital_gains") && "visibleSource"}`}
            placeholder="Income"
            name="capital_gains-income"
            value={inputAmount.capital_gains.income}
            onChange={handleChange}
          />
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("capital_gains") && "visibleSource"}`}
            placeholder="Expenditures"
            name="capital_gains-expenditure"
            value={inputAmount.capital_gains.expenditure}
            onChange={handleChange}
          />
        </div>
        <div
          className={`${
            formData.userData.sourceOfIncome.includes("donations") ? "block" : "hidden"
          } text-center`}
        >
          <div className="bg-[#244B69] text-white h-[91px] flex items-center justify-center">
            Donations/Gift aid
          </div>
          <input
            type="number"
            className={`text-center border border-black outline-none w-full py-8 ${formData.userData.sourceOfIncome.includes("donations") && "visibleSource"}`}
            placeholder="Income"
            name="donations-income"
            value={inputAmount.donations.income}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="flex justify-between items-center mt-[5%]">
        <button onClick={handlePrevious} className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center">
          <LeftArrow />
        </button>
        <button onClick={handleNext} className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center">
          <RightArrow />
        </button>
      </div>
    </div>
  );
}
