import './App.css';
import ClientState from "./context/clients/ClientState";
import { BrowserRouter } from "react-router-dom";
import UserState from './context/user/UserState';
import { Worker } from '@react-pdf-viewer/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

<style>
  {/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap'); */}
</style>

function App() {
  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <ClientState>
            <UserState />
          </ClientState>
          <ToastContainer autoClose={3000} theme="colored" draggable />
        </BrowserRouter>
      </Worker>
    </>
  );
}

export default App;
