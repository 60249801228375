import React, { useEffect, useState } from 'react'
import useClient from '../../hooks/useClient'
import BackButtonUserArea from './BackButtonUserArea';
import FormatDate from '../../utils/FormatDate';
import ProfileChangePopup from './ProfileChangePopup';
import Backdrop from '../../assets/common/Backdrop';

const Profile = () => {
    const { activeClient } = useClient();
    const [clientDob, setClientDob] = useState("");
    const [toggleChange, setToggleChange] = useState(false)

    useEffect(() => {
        setClientDob(FormatDate(activeClient?.dob, "/"))
    }, [activeClient])

    const handleToggleChange = () => {
        setToggleChange(!toggleChange)
    }

    return (
        <div className="font-poppins py-20" style={{ background: 'linear-gradient(180deg, rgba(159, 210, 210, 0.36) 0%, rgba(159, 210, 210, 0.00) 108.54%)' }}>
            <div className="mx-auto max-w-7xl px-5 xl:px-0">
                <BackButtonUserArea />
                <div className='bg-white p-5 mt-10'>
                    <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                First Name:
                            </p>
                            <input
                                type="text"
                                className="bg-neutral-100 rounded-md h-16 px-5"
                                name="firstname"
                                defaultValue={activeClient.first_name}
                                readOnly
                            // onChange={handleInfoChange}
                            />
                            {/* {dataError.firstname && <p className="text-red-500">{dataError.firstname}</p>} */}
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                Last Name:
                            </p>
                            <input
                                type="text"
                                className="bg-neutral-100 rounded-md h-16 px-5"
                                name="lastname"
                                defaultValue={activeClient.last_name}
                                readOnly
                            // onChange={handleInfoChange}
                            />
                            {/* {dataError.lastname && <p className="text-red-500">{dataError.lastname}</p>} */}
                        </div>
                    </div>

                    <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                Email:
                            </p>
                            <input
                                type="text"
                                className="bg-neutral-100 rounded-md h-16 px-5"
                                name="emailAddress"
                                defaultValue={activeClient.email_address}
                                readOnly
                            // onChange={handleInfoChange}
                            />
                            {/* {dataError.emailAddress && <p className="text-red-500">{dataError.emailAddress}</p>} */}
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                Mobile Number:
                            </p>
                            <input
                                type="text"
                                className="bg-neutral-100 rounded-md h-16 px-5"
                                name="telephone"
                                defaultValue={activeClient.phone_number}
                                readOnly
                            // onChange={handleInfoChange}
                            />
                            {/* {dataError.telephone && <p className="text-red-500">{dataError.telephone}</p>} */}
                        </div>
                    </div>

                    <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                Address:
                            </p>
                            <input
                                type="text"
                                className="bg-neutral-100 rounded-md h-16 px-5 w-full"
                                id="address"
                                name="address"
                                defaultValue={activeClient.address}
                                readOnly
                            // defaultValue={userInfo.address}
                            // onChange={handleInfoChange}
                            // ref={postcodeRef.address}
                            // onKeyDown={checkAddress}
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                Postcode:
                            </p>
                            <input
                                type="text"
                                className="bg-neutral-100 rounded-md h-16 px-5"
                                id="postcode"
                                name="postcode"
                                defaultValue={activeClient.postcode}
                                // defaultValue={userInfo.postcode}
                                // onChange={handleInfoChange}
                                // ref={postcodeRef.postcode}
                                readOnly
                            />
                        </div>
                    </div>

                    <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                Date of Birth:
                            </p>
                            <input
                                className="bg-neutral-100 rounded-md h-16 px-5 uppercase"
                                name="dob"
                                type="text"
                                defaultValue={clientDob}
                                readOnly
                            // onChange={handleInfoChange}
                            />
                            {/* {dataError.dob && <p className="text-red-500">{dataError.dob}</p>} */}
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className="text-neutral-500 font-light lg:text-xl">
                                NI Number:
                            </p>
                            <input
                                // mask="aa999999a"
                                // maskChar=""
                                placeholder="AA######A"
                                className="bg-neutral-100 rounded-md h-16 px-5"
                                name="nic"
                                defaultValue={activeClient?.ni_number}
                                readOnly
                            // onChange={handleInfoChange}
                            />
                            {/* {dataError.nic && <p className="text-red-500">{dataError.nic}</p>} */}
                        </div>
                    </div>
                </div>

                <div className='flex justify-center mt-5'>
                    <button
                        className='bg-emerald-500 text-white p-2 rounded-md w-60 text-lg'
                        onClick={handleToggleChange}>
                        Request Change
                    </button>
                </div>

                {toggleChange &&

                    <div className="w-full h-full flex place-content-center">
                        <Backdrop />
                        <ProfileChangePopup handleToggleChange={handleToggleChange}/>
                    </div>
                }
            </div>
        </div>

    )
}

export default Profile
