import { matches } from 'validator';

const ValidateNi = (value) => {
  value = value.toUpperCase();
  const isValidNIC = matches(
    value,
    /^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVo][0-9]{6}[ABCD]$/
  );
  if (!isValidNIC) {
    return "Invalid NI format";
  } else {
    return "";
  }
};

export default ValidateNi;
