import { AddressFinder } from "@ideal-postcodes/address-finder";
const ADDRESS_API = "ak_llc71tx7m2oFfSJkQG2BgQNtRPHV9"

const AddressFinderSetup = () => {

    let mergedAddress = ""
    let postcode = ""
    let town = ""

    try {
        AddressFinder.setup({
            apiKey: ADDRESS_API,
            detectCountry: false,
            hideToolbar: true,
            outputFields: {
                line_1: "#address",
                postcode: "#postcode",
                post_town: "#city"
            },
            onAddressPopulated: (returnedAddressData) => {
                console.log(returnedAddressData)
                mergedAddress = returnedAddressData.line_1
                postcode = returnedAddressData.postcode
                town = returnedAddressData.post_town
            }
        });
    }
    catch (error) {
        console.log(error)
    }

    return { mergedAddress, postcode, town };
}

export default AddressFinderSetup;
