import { useState } from "react";
import ClientContext from "./clientContext";

const ClientState = ({ children }) => {

    const [activeClient, setActiveClient] = useState({})
    const [activeClaim, setActiveClaim] = useState({})
    const [formData, setFormData] = useState({});

    return (
        <ClientContext.Provider value={{activeClient, setActiveClient, activeClaim, setActiveClaim, formData, setFormData}}>
            {children}
        </ClientContext.Provider>
    )
}

export default ClientState