import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const Tooltip = ({text, content}) => (
    <Popup
      trigger={open => (
        <button className="bg-[#244B69] text-white w-full p-2 hover:bg-white hover:text-[#244B69] shadow-md">{text}</button>
      )}
      position="bottom center"
      closeOnDocumentClick
    >
      <span> {content} </span>
    </Popup>
);

export default Tooltip;

