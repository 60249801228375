import { useState } from "react"
export default function CapitalGainsCal(){
    const [incomeSource, setIncomeSource] = useState('Shares');
    const [profitFromCapitalGains, setProfitFromCapitalGains] = useState(20000);
    const [annualSalary, setAnnualSalary] = useState(49000);
    const [otherIncome, setOtherIncome] = useState(0);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
            if (name === 'incomeSource') {
                setIncomeSource(value);
            } else if (name === 'profitFromCapitalGains') {
                setProfitFromCapitalGains(Number(value));
            } else if (name === 'annualSalary') {
                setAnnualSalary(Number(value));
            } else if (name === 'otherIncome') {
                setOtherIncome(Number(value));
            }
        }
        console.log( "otherIncome" , otherIncome)

        const taxPay = profitFromCapitalGains - 6000
        const totalIncome = annualSalary + otherIncome; 
        const taxFree = taxPay + totalIncome
        let taxRate;
        if (incomeSource === 'Property'){
            taxRate = totalIncome < 50270 ? (50270 - totalIncome) * 0.18 + (taxFree - 50270) * 0.28 :  taxPay * 0.28
        } else {
            taxRate = totalIncome < 50270 ? (50270 - totalIncome) * 0.10 + (taxFree - 50270) * 0.20 :  taxPay * 0.20
        }
        taxRate = Math.max(0, taxRate)
        const totalProfit = profitFromCapitalGains - taxRate
        
        console.log(totalIncome)



    return(
        <div className="grid grid-cols-5 text-[#444A4C]">
        <div className="md:col-span-3 col-span-5 bg-[#7FC3C3]/[11%] md:p-10 p-5">
            <div className="text-[#274A69] text-center font-semibold text-2xl">Your situation</div>
             <div>
                <p className="md:text-base text-xs font-normal text-[#444A4C] mt-5">How did you make money?</p>
                <div className="grid grid-cols-2 mt-5">
                    <div>
                        <input type="radio" name="incomeSource" value="Shares" onChange={handleInputChange} defaultChecked/>
                        <label className="text-[#444A4C] ml-2 md:text-base text-xs">Shares</label>
                    </div>
                    <div>
                        <input type="radio" name="incomeSource" value="Property" onChange={handleInputChange}/>
                        <label className="text-[#444A4C] ml-2 md:text-base text-xs">Property</label>
                    </div>
                    <div className="mt-5">
                        <input type="radio" name="incomeSource" value="Crypto" onChange={handleInputChange}/>
                        <label className="text-[#444A4C] ml-2 md:text-base text-xs">Crypto</label>
                    </div>
                    <div className="mt-5"> 
                        <input type="radio" name="incomeSource" value="Other" onChange={handleInputChange}/>
                        <label className="text-[#444A4C] ml-2 md:text-base text-xs">Other</label>
                    </div>
                </div>
                <div className="mt-5 flex items-center justify-between">
                    <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Profit from capital gains</label>
                    <div className="flex items-center"> 
                        <svg className="mr-2" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 15.4921C12.6421 15.4921 16 12.1848 16 8.10516C16 4.02549 12.6421 0.718262 8.5 0.718262C4.35786 0.718262 1 4.02549 1 8.10516C1 12.1848 4.35786 15.4921 8.5 15.4921Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.31763 5.8891C6.49395 5.39541 6.84199 4.97912 7.30009 4.71395C7.75819 4.44877 8.2968 4.35184 8.82051 4.44032C9.34422 4.52879 9.81924 4.79697 10.1614 5.19734C10.5036 5.59771 10.6909 6.10445 10.6901 6.62779C10.6901 8.10517 8.44013 8.84386 8.44013 8.84386" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.5 11.7986H8.507" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <div className="relative">
                           <span className="absolute inset-y-0 left-0 flex items-center pl-2">£</span>
                           <input type="number" name="profitFromCapitalGains" onChange={handleInputChange} value={profitFromCapitalGains} className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]" />
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex items-center justify-between">
                    <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Annual salary</label>
                    <div className="flex items-center"> 
                        <svg className="mr-2" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 15.4921C12.6421 15.4921 16 12.1848 16 8.10516C16 4.02549 12.6421 0.718262 8.5 0.718262C4.35786 0.718262 1 4.02549 1 8.10516C1 12.1848 4.35786 15.4921 8.5 15.4921Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.31763 5.8891C6.49395 5.39541 6.84199 4.97912 7.30009 4.71395C7.75819 4.44877 8.2968 4.35184 8.82051 4.44032C9.34422 4.52879 9.81924 4.79697 10.1614 5.19734C10.5036 5.59771 10.6909 6.10445 10.6901 6.62779C10.6901 8.10517 8.44013 8.84386 8.44013 8.84386" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.5 11.7986H8.507" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <div className="relative">
                            <span className="absolute inset-y-0 right-0 flex items-center pl-2">£</span>
                            <input type="number" name="annualSalary" value={annualSalary} onChange={handleInputChange} className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"/>
                        </div>
                    </div>
                </div>
                <div className="mt-5 flex items-center justify-between">
                    <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Other income</label>
                    <div className="flex items-center"> 
                        <svg className="mr-2" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 15.4921C12.6421 15.4921 16 12.1848 16 8.10516C16 4.02549 12.6421 0.718262 8.5 0.718262C4.35786 0.718262 1 4.02549 1 8.10516C1 12.1848 4.35786 15.4921 8.5 15.4921Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.31763 5.8891C6.49395 5.39541 6.84199 4.97912 7.30009 4.71395C7.75819 4.44877 8.2968 4.35184 8.82051 4.44032C9.34422 4.52879 9.81924 4.79697 10.1614 5.19734C10.5036 5.59771 10.6909 6.10445 10.6901 6.62779C10.6901 8.10517 8.44013 8.84386 8.44013 8.84386" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.5 11.7986H8.507" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">£</span>
                            <input type="number" name="otherIncome" value={otherIncome} onChange={handleInputChange} className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"/>
                        </div>
                    </div>
                </div>
             </div>
        </div>
        <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] md:py-10 md:pr-10 md:pl-[4rem] p-5 relative">
            <div className="text-[#274A69] text-center font-semibold text-2xl">Tax and profit</div>
            <div className="flex items-center justify-between mt-10">
              <div className="text-[#444A4C]">Your profit from shares</div>
              <div className="text-[#444A4C]">£ {profitFromCapitalGains}</div>
            </div>
            <div className="flex items-center md:mt-[18%] mt-[5%]">
                <p className="font-light text-[10px]">£6000 tax free CGT allowance</p>
                <svg className="ml-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 12.9095C10.3137 12.9095 13 10.2637 13 6.99997C13 3.73624 10.3137 1.09045 7 1.09045C3.68629 1.09045 1 3.73624 1 6.99997C1 10.2637 3.68629 12.9095 7 12.9095Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M5.25415 5.22712C5.39521 4.83217 5.67364 4.49913 6.04012 4.28699C6.4066 4.07486 6.83749 3.99731 7.25646 4.06809C7.67542 4.13887 8.05544 4.35341 8.3292 4.67371C8.60295 4.99401 8.75278 5.3994 8.75215 5.81807C8.75215 6.99998 6.95215 7.59093 6.95215 7.59093" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 9.95474H7.0056" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="flex items-center justify-between md:mt-[16%] mt-[5%]">
              <div className="text-[#444A4C]">Capital Gains Tax to pay</div>
              <div className="text-[#444A4C]">£ {taxRate.toFixed()}</div>
            </div>
            <div className="flex items-center justify-between md:mt-[27%] mt-[5%]">
              <div className="text-[#444A4C] font-bold">Profit after tax</div>
              <div className="text-[#444A4C] font-bold">£ {totalProfit.toFixed()}</div>
            </div>
          <div className="absolute top-[25%] left-[7%] lg:block hidden">
            <svg width="23" height="260" viewBox="0 0 23 260" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="11.5" cy="11.5" r="11.5" fill="white"/>
                    <circle cx="11.5" cy="155.5" r="11.5" fill="white"/>
                    <circle cx="11.5" cy="82.5" r="4.5" fill="white"/>
                    <path d="M12 10.5V250" stroke="white"/>
                    <circle cx="11.5" cy="248.5" r="11.5" fill="white"/>
                    <path d="M12 7V15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 11H16" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 155H15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M14.694 246.832V247.91H7.414V246.832H14.694ZM14.694 249.842V250.92H7.414V249.842H14.694Z" fill="black"/>
                </svg>
          </div>

        </div>
        </div>
    )
}