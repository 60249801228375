import React from "react";

function SectionOne({ userOption, handleChange1 }) {
  return (
    <>
      <div className="font-normal xl:text-2xl text-xl text-[#244B69] pb-2 lg:pb-5" id="taxHeading">
        1. Select
      </div>
      <div className="items-center justify-evenly grid grid-cols-12 text-center">
        <div className="md:col-span-5 col-span-12">
          <input
            type="radio"
            className="hidden"
            id="personalTax"
            onChange={handleChange1}
            name="taxType"
            checked={userOption.taxType === "personalTax"}
            value="personalTax"
          />
          <label
            htmlFor="personalTax"
            className="color3 text-[#244B69] border border-black xl:text-2xl lg:text-xl text-base font-medium my-[3%] cursor-pointer flex justify-center items-center h-16 lg:h-24 xl:h-28"
          >
            Personal tax return
          </label>
        </div>
        <div className="md:col-span-2 col-span-12 text-[#244B69] xl:text-4xl text-2xl ">
          OR
        </div>
        <div className="md:col-span-5 col-span-12">
          <input
            type="radio"
            className="hidden"
            id="partnershipTax"
            onChange={handleChange1}
            name="taxType"
            checked={userOption.taxType === "partnershipTax"}
            value="partnershipTax"
          />
          <label
            htmlFor="partnershipTax"
            className="color3 text-[#244B69] border border-black xl:text-2xl lg:text-xl text-base font-medium my-[3%] cursor-pointer flex justify-center items-center h-16 lg:h-24 xl:h-28"
          >
            Partnership tax return
          </label>
        </div>
      </div>
    </>
  );
}

export default SectionOne;