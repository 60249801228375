
import { NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import useUser from "../../../hooks/useUser";
import useClient from "../../../hooks/useClient";

export default function Header() {
  const { tokenCheck, setTokenCheck, setActiveUser, userMode, logoutSession } = useUser();

  const { activeClient, setActiveClient } = useClient();

  // const logOut = () => {
  //   setTokenCheck(false)
  //   localStorage.clear()
  //   setActiveUser({})
  //   setActiveClient({})
  // }

  return (
    <Disclosure as="nav">
      {({ open, close }) => (
        <>
          <div className="mx-auto max-w-7xl py-4 px-2 sm:px-6 lg:px-8 font-poppins">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center md:justify-between sm:justify-evenly">
                <NavLink to="/" className="flex flex-shrink-0 items-center">
                  <img
                    className="w-28 h-full"
                    src="../images/FullColourLogo.png"
                    alt="Tax Magpie"
                  />
                </NavLink>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4 items-center navbar">
                    <NavLink to={"/"} className="lg:px-3 px-1 py-2 text-sm font-medium text-[#274A69] hover:border-b-[4px] border-[#274A69]">Home</NavLink>
                    {!tokenCheck &&
                      <>
                        <NavLink to="services" className="lg:px-3 px-1 py-2 text-sm font-medium text-[#274A69] hover:border-b-[4px] border-[#274A69]">Services</NavLink>
                        <NavLink to="resources" className="lg:px-3 px-1 py-2 text-sm font-medium text-[#274A69] hover:border-b-[4px] border-[#274A69]">Resources</NavLink>
                        <NavLink to="about-us" className="lg:px-3 px-1 py-2 text-sm font-medium text-[#274A69] hover:border-b-[4px] border-[#274A69]">About us</NavLink>
                        <NavLink to="faqs" className="lg:px-3 px-1 py-2 text-sm font-medium text-[#274A69] hover:border-b-[4px] border-[#274A69]">FAQ's</NavLink>
                      </>
                    }
                    {!tokenCheck
                      ? <NavLink to="login" className="lg:px-3 px-1 py-2 text-sm font-medium text-[#274A69] hover:border-b-[4px] border-[#274A69]" >Log in</NavLink>
                      : <NavLink to="login" onClick={logoutSession} className="text-white px-3 py-2 text-sm font-medium bg-[#244B69] rounded-md flex items-center gap-5" >
                        <span>Logout</span>
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.51465 10H1.51465C1.24943 10 0.995078 9.89464 0.807542 9.70711C0.620005 9.51957 0.514648 9.26522 0.514648 9V2C0.514648 1.73478 0.620005 1.48043 0.807542 1.29289C0.995078 1.10536 1.24943 1 1.51465 1H3.51465" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M7.01465 8L9.51465 5.5L7.01465 3" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M9.51465 5.5H3.51465" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>


                      </NavLink>
                    }
                    {!tokenCheck &&
                      <NavLink to="form" className="lg:text-white text-[#274A69] lg:px-3 px-1 py-2 text-sm lg:font-medium font-bold lg:bg-[#244B69]">Get started</NavLink>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden mt-5">
            <div className="space-y-1 px-2 pb-3 pt-2 text-center">
              <div className="flex flex-col">
                <NavLink
                  to={"/"}
                  className="px-3 py-2 text-sm font-medium text-[#274A69] hover:bg-[#274A69] hover:text-white"
                  onClick={() => close()}
                >
                  Home
                </NavLink>
                {!tokenCheck &&
                  <>
                    <NavLink
                      to="services"
                      className="px-3 py-2 text-sm font-medium text-[#274A69] hover:bg-[#274A69] hover:text-white"
                      onClick={() => close()}
                    >
                      Services
                    </NavLink>
                    <NavLink
                      to="resources"
                      className="px-3 py-2 text-sm font-medium text-[#274A69] hover:bg-[#274A69] hover:text-white"
                      onClick={() => close()}
                    >
                      Resources
                    </NavLink>
                    <NavLink
                      to="about-us"
                      className="px-3 py-2 text-sm font-medium text-[#274A69] hover:bg-[#274A69] hover:text-white"
                      onClick={() => close()}
                    >
                      About us
                    </NavLink>
                    <NavLink
                      to="faqs"
                      className="px-3 py-2 text-sm font-medium text-[#274A69] hover:bg-[#274A69] hover:text-white"
                      onClick={() => close()}
                    >
                      FAQ's
                    </NavLink>
                  </>
                }
                {!tokenCheck
                  ? <NavLink
                    to="login"
                    className="px-3 py-2 text-sm font-medium text-[#274A69] hover:bg-[#274A69] hover:text-white"
                    onClick={() => close()}
                  >
                    Log in
                  </NavLink>
                  : <NavLink
                    to="login"
                    className="px-3 py-2 text-sm font-medium bg-[#274A69] text-white"
                    onClick={() => { close(); logoutSession() }}
                  >
                    Logout
                  </NavLink>}
                {!tokenCheck &&
                  <NavLink
                    to="form"
                    className="text-white px-3 py-2 text-sm font-medium bg-[#244B69]"
                    onClick={() => close()}
                  >
                    Get started
                  </NavLink>}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}





