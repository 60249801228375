import React, { useState } from 'react'
import { toast } from 'react-toastify'

const ProfileChangePopup = ({handleToggleChange}) => {
    const [ userDetails, setUserDetails ] = useState({
        firstName: false,
        lastName: false,
        emailAddress: false,
        mobilePhone: false,
        address: false,
        dob: false,
        niNumber: false,
    })

    const handleUserDetails = ({target: {name, checked}}) => {
        setUserDetails({...userDetails, [name]: checked})
    }

    const checkUserDetails = () => {
        return Object.values(userDetails).some(value => value === true)
    }

    const sendRequest = () => {
        if (!checkUserDetails()) {
            toast.warning("Please select atleast one field")
        }

        console.log("good to go");
        // handleToggleChange();
    }

    return (
        <div className="w-[90%] lg:w-2/4 mx-auto bg-white fixed top-36 z-50 drop-shadow-md rounded-md">
            <div className="flex flex-col px-6 py-10 w-full gap-5">
                <div className='flex justify-between'>
                    <p className=' lg:text-xl text-gray-500'>Request changes</p>
                    <div className="cursor-pointer flex items-center rounded-md px-1" onClick={handleToggleChange}>
                        <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M17.1726 5.7832L5.97229 16.9835"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5.97217 5.78345L17.1725 16.9838"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div className='grid lg:grid-cols-2 border rounded-md'>
                    <label className='custom-radio flex gap-5 w-full select-none p-3 hover:bg-gray-200 cursor-pointer'>
                        <input type="checkbox" name='firstName' onChange={handleUserDetails} checked={userDetails?.firstName === true}/>
                        <span>First Name</span>
                    </label>
                    <label className='custom-radio flex gap-5 w-full select-none p-3 hover:bg-gray-200 cursor-pointer'>
                        <input type="checkbox" name='lastName' onChange={handleUserDetails} checked={userDetails?.lastName === true}/>
                        <span>Last Name</span>
                    </label>
                    <label className='custom-radio flex gap-5 w-full select-none p-3 hover:bg-gray-200 cursor-pointer'>
                        <input type="checkbox" name='emailAddress' onChange={handleUserDetails} checked={userDetails?.emailAddress === true}/>
                        <span>Email Address</span>
                    </label>
                    <label className='custom-radio flex gap-5 w-full select-none p-3 hover:bg-gray-200 cursor-pointer'>
                        <input type="checkbox" name='mobilePhone' onChange={handleUserDetails} checked={userDetails?.mobilePhone === true}/>
                        <span>Mobile Phone</span>
                    </label>
                    <label className='custom-radio flex gap-5 w-full select-none p-3 hover:bg-gray-200 cursor-pointer'>
                        <input type="checkbox" name='address' onChange={handleUserDetails} checked={userDetails?.address === true}/>
                        <span>Address</span>
                    </label>
                    <label className='custom-radio flex gap-5 w-full select-none p-3 hover:bg-gray-200 cursor-pointer'>
                        <input type="checkbox" name='dob' onChange={handleUserDetails} checked={userDetails?.dob === true}/>
                        <span>Date of Birth</span>
                    </label>
                    <label className='custom-radio flex gap-5 w-full select-none p-3 hover:bg-gray-200 cursor-pointer'>
                        <input type="checkbox" name='niNumber' onChange={handleUserDetails} checked={userDetails?.niNumber === true}/>
                        <span>NI Number</span>
                    </label>
                </div>
                <button className='bg-emerald-500 p-3 text-white rounded-md lg:text-lg' onClick={sendRequest}>Send</button>
            </div>
        </div>
    )
}

export default ProfileChangePopup
