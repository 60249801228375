import axios from 'axios'
const baseUrl = `/api/clients`

const getLimitedClients = (page, pageSize) => {
    const request = axios.get(`${baseUrl}?page=${page}&pageSize=${pageSize}`)
    return request.then(response => response.data)
}

const getClientByRef = ref => {
    const request = axios.get(`${baseUrl}/${ref}`)
    return request.then(response => response.data)
}

const getClientByUserId = userId => {
    const request = axios.get(`${baseUrl}/getbyuser/${userId}`)
    return request.then(response => response.data)
}

const createOrUpdate = newObject => {
  const request = axios.post(`${baseUrl}/createOrUpdateClient`, newObject)
  return request.then(response => response.data)
}

const remove = id => {
    const request = axios.delete(`${baseUrl}/delete/${id}`);
    return request.then(response => response.data);
};

const deleteAll = () => {
    const request = axios.delete(`${baseUrl}/deleteAll`);
    return request.then(response => response.data);
};

// eslint-disable-next-line
export default { getLimitedClients, getClientByRef, getClientByUserId, remove, createOrUpdate, deleteAll };