import useCrmTabs from "../../../../hooks/useCrmTabs";
import useUser from "../../../../hooks/useUser";
import FormatTime from "../../../../utils/FormatTime";

const SingleMessage = ({message, lastMessage}) => {
    const {deletePopup} = useCrmTabs();
    const {activeUser} = useUser();

    const checkMessageDeletable = () => {
        const currentDate = new Date();
        const messageDate = new Date(lastMessage.created_at);
        const millisecondsInDay = 24 * 60 * 60 * 1000;
    
        const differenceInMilliseconds = currentDate.getTime() - messageDate.getTime();
    
        return differenceInMilliseconds <= millisecondsInDay;
    }

    return (
        <>
        <div className="rounded-md flex items-end gap-5 content-wrapper w-full max-md:text-sm">
            <div className={`flex flex-col w-4/5 gap-3 relative`}>
                <p className={`flex w-full capitalize ${message.sender === "agent" ? "text-[#274A69] justify-start" : "text-emerald-500 justify-end"} font-light px-2`}>{message.sender}</p>
                <div className={`${message.sender === "agent" ? "bg-[#EBEFF1] chat-left-wrapper rounded-r-md" : "bg-[#EEF8F4] chat-right-wrapper rounded-l-md"} rounded-b-md shadow-[2px_3px_3.4px_0px_rgba(0,0,0,0.25)] px-5 pt-2 pb-8 relative`}>
                    <p>{message.text}</p>
                    <span className="absolute bottom-1 text-[.6rem] text-[#274A69]">{FormatTime(message.created_at)}</span>
                </div>
            </div>
            {message.sender === activeUser.role && checkMessageDeletable() && message.id === lastMessage.id && (
                <div className="mb-1">
                    <button
                        onClick={(e) => deletePopup(e, message)}
                        className="flex"
                    >
                        <svg
                            width="18"
                            height="23"
                            viewBox="0 0 20 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 5.89938H3H19"
                                stroke="#B60C0C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M17 5.89938V19.8994C17 20.4298 16.7893 20.9385 16.4142 21.3136C16.0391 21.6887 15.5304 21.8994 15 21.8994H5C4.46957 21.8994 3.96086 21.6887 3.58579 21.3136C3.21071 20.9385 3 20.4298 3 19.8994V5.89938M6 5.89938V3.89938C6 3.36895 6.21071 2.86024 6.58579 2.48517C6.96086 2.1101 7.46957 1.89938 8 1.89938H12C12.5304 1.89938 13.0391 2.1101 13.4142 2.48517C13.7893 2.86024 14 3.36895 14 3.89938V5.89938"
                                stroke="#B60C0C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M8 10.8994V16.8994"
                                stroke="#B60C0C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M12 10.8994V16.8994"
                                stroke="#B60C0C"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            )}
        </div>
        </>
    );
};

export default SingleMessage;
