
const PaymentTab = ({plan, colorScheme}) => {
    return (
        <>
            <div className="lg:mt-7 border border-[#244B69] rounded-md px-4 py-5 h-96 lg:h-[45rem]">
                { plan.type
                ?    <div className={`lg:w-2/5 border border-${colorScheme} text-${colorScheme} rounded-md`}>
                        <div className="flex flex-col w-full justify-center items-center">
                            <p className="h-20 w-full flex items-center justify-center">{plan.type}</p>
                            <div className={`bg-${colorScheme} text-white font-semibold text-center w-full px-4 py-8 rounded-b-md`}>
                                {plan.price}<br/>
                                ({plan.description})
                            </div>
                        </div>
                    </div>
                :   <div className="text-[#244B69] text-xl flex w-full h-full items-center justify-center">
                        <p>No Payment Plan Available</p>
                    </div>
                }
            </div>
      </>
    )
}

export default PaymentTab;
