import axios from 'axios';
const baseUrl = `/api/fileUpload`

const fetchFile = (clientId, token) => {
    const request = axios.get(`${baseUrl}/${clientId}?token=${token}`)
    return request.then(response => response.data)
}

const uploadFile = (clientId, formData, token) => {
    formData.append('clientId', clientId);
    const request = axios.post(`${baseUrl}?token=${token}`, formData);
    return request.then(response => response.data)
};

const updateFile = (formData, onUploadProgress, token) => {
    const request = axios.put(`${baseUrl}/update`, formData, {
        onUploadProgress,
        params: { token },
    });
    return request.then(response => response.data)
};

const remove = (id, token) => {
    const request = axios.delete(`${baseUrl}/${id}?token=${token}`);
    return request.then(response => response.data);
};

// eslint-disable-next-line
export default { uploadFile, updateFile, fetchFile, remove }
