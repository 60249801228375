import axios from 'axios'
const baseUrl = `/api/users`

const getUserByToken = async token => {
    const request = axios.get(`${baseUrl}/find?token=${token}`)
    return request.then(response => response.data)
}

const loginUser = async newObject => {
    const request = axios.post(`${baseUrl}/login`, newObject)
    return request.then(response => response.data)
}

const update = async (newObject) => {
  const request = axios.put(`${baseUrl}/update`, newObject)
  return request.then(response => response.data)
}

const remove = async id => {
    const request = axios.delete(`${baseUrl}/delete/${id}`);
    return request.then(response => response.data);
};

// eslint-disable-next-line
export default { getUserByToken, loginUser, update, remove };