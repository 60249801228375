import { Link, useNavigate } from "react-router-dom";
import useClient from "../../../hooks/useClient";
import FormatDate from "../../../utils/FormatDate";
import CopyToClipboard from "../../../utils/CopyToClipboard";
import useCrmTabs from "../../../hooks/useCrmTabs";
import Warning from "./crmPopups/Warning";
import Backdrop from "../../../assets/common/Backdrop";
import EmailIconLight from "../../../assets/images/EmailTabIcon-Light.svg"
import EmailIconDark from "../../../assets/images/EmailTabIcon-Dark.svg"
import SMSIconLight from "../../../assets/images/SMSTabIcon-Light.svg"
import SMSIconDark from "../../../assets/images/SMSTabIcon-Dark.svg"
import NotesIconLight from "../../../assets/images/NotesTabIcon-Light.svg"
import NotesIconDark from "../../../assets/images/NotesTabIcon-Dark.svg"
import LogsIconLight from "../../../assets/images/LogsTabIcon-Light.svg"
import LogsIconDark from "../../../assets/images/LogsTabIcon-Dark.svg"
import CopyIcon from "../../../assets/images/CopyIcon.svg"
import calculateTimeDifference from "../../../utils/calculateTimeDifference";

const CRMSideBar = ({ colorScheme, handleTabClick, activeTab }) => {
  const { activeClient } = useClient();
  const { isWarningVisible, toggleWarningVisibility, deleteClient, deletePopup } = useCrmTabs();
  const navigate = useNavigate()
  return (
    <>
      <div className="col-span-1 py-10 px-5 xl:p-10 border border-neutral-100] sidebar-wrapper">
        <div className="flex items-center justify-center flex-col mt-5 lg:mt-10">
          <div className={`bg-${colorScheme} text-white font-semibold text-xl rounded-full w-20 h-20 flex items-center justify-center uppercase`}>
            {activeClient?.first_name?.substring(0, 1)}{activeClient?.last_name?.substring(0, 1)}
          </div>
          <div className="font-semibold text-xl mt-2 capitalize">{activeClient?.first_name}</div>
          <div className="font-light capitalize">{activeClient?.last_name}</div>
        </div>
        <div className="grid grid-cols-3 justify-center py-5 mt-3">
          <button onClick={() => handleTabClick("email")} className="flex flex-col items-center">
            <img src={activeTab === "email" ? EmailIconDark : EmailIconLight} alt="" />
            <div>Email</div>
          </button>
          <button onClick={() => handleTabClick("sms")} className="flex flex-col items-center">
            <img src={activeTab === "sms" ? SMSIconDark : SMSIconLight} alt="" />
            <div>SMS</div>
          </button>
          <button onClick={() => handleTabClick("4")} className="flex flex-col items-center">
            <img src={activeTab === "4" ? NotesIconDark : NotesIconLight} alt="" />
            <div>Notes</div>
          </button>
          {/* <button onClick={() => handleTabClick("logs")} className="flex flex-col items-center">
            <img src={activeTab === "logs" ? LogsIconDark : LogsIconLight} alt="" />
            <div>Logs</div>
          </button> */}
        </div>
        <div className="border-y-2 neutral-100">
          <button onClick={() => navigate("/addClient")} className="flex items-center justify-center border border-[#244B69] w-full mt-5 p-3 text-xl rounded-md">
            Edit
          </button>
          <div className={`border border-[#244B69] rounded-md my-7`}>
            <div className={`bg-${colorScheme} text-white font-light p-4 rounded-t-md`}>
              Quick View:
            </div>
            <div className="mt-2 py-3 px-5">
              <div className="text-[#1E1E1E] font-light">Created</div>
              <div className="mt-2 flex justify-between">
                  <p>{FormatDate(activeClient?.created_at)}</p>
                  <p className="text-gray-500 font-light">({calculateTimeDifference(activeClient?.created_at)})</p>
                </div>
            </div>
            <div className="flex py-3 px-5 justify-between items-end">
              <div>
                <div className="text-[#1E1E1E] font-light">Client Ref:</div>
                <div className="mt-2">{activeClient?.ref}</div>
              </div>
              <button className="relative copy-btn" onClick={() => CopyToClipboard(activeClient?.ref)}>
                <img src={CopyIcon} alt="" />
                <span className="absolute -top-7 -left-5 text-xs bg-[#244B69] text-white px-2 py-1 rounded hidden">Copied</span>
              </button>
            </div>
            <div className="flex py-3 px-5 justify-between items-end mb-5">
              <div>
                <div className="text-[#1E1E1E] font-light">
                  Mobile Number:
                </div>
                <div className="mt-2">{activeClient?.phone_number}</div>
              </div>
              <button onClick={() => CopyToClipboard(activeClient?.phone_number)}>
                <img src={CopyIcon} alt="" />
              </button>
            </div>
          </div>
        </div>
        <button onClick={(e) => deletePopup(e, activeClient)} className="flex items-center justify-between w-full p-4 border border-[#B60C0C] rounded-md mt-7">
          <div className="text-[#B60C0C]">Delete</div>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 2.32397L2 14.324"
              stroke="#B60C0C"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 2.32397L14 14.324"
              stroke="#B60C0C"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {isWarningVisible &&
        <div className="w-screen h-screen absolute inset-0 max-md:left-auto flex place-content-center">
          <div className="max-lg:hidden"><Backdrop /></div>
          <Warning toggle={toggleWarningVisibility} deleteContent={deleteClient} />
        </div>
      }
    </>
  )
}

export default CRMSideBar;