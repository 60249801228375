import Surety from "../../../../assets/Surety";
import Topbtn from "../../../../assets/basic/Topbtn";
import CalTopHead from "../allCalProps.jsx/CalTopHead";
import LandlordCal from "../allCalProps.jsx/LandlordCal";
export default function LandlordPage(){
    return(
    <>
        <div className="mx-auto max-w-[1001px] md:px-10 px-5 font-poppins">
            <CalTopHead
                title="Landlord Calculator"
                para="Landlord earning rental income from a tenant in your home or a buy-to-let property? Quickly calculate how much you can expect to pay in rental income tax."
            />
            <LandlordCal/>
        </div>
        <div className="pt-10">
          <Surety />
        </div>
        <div className="max-w-7xl flex justify-end p-5">
            <div className="w-[98px]">
                <Topbtn />
            </div>
        </div>
    </>
    )
}