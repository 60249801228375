import React, { useState } from "react";
export default function TaxReliefCal() {
  const [Income, setIncome] = useState(41000);
  const [propertyIncome, setPropertyIncome] = useState(12000);
  const [allowableExpenses, setAllowableExpenses] = useState(1000);
  const [interestFinance, setInterestFinance] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "Income") {
      setIncome(Number(value));
    } else if (name === "propertyIncome") {
      setPropertyIncome(Number(value));
    } else if (name === "allowableExpenses") {
      setAllowableExpenses(Number(value));
    } else if (name === "interestFinance") {
      setInterestFinance(Number(value));
    }
  };
  const RPI = propertyIncome - allowableExpenses;
  const tax = Income + RPI;
  const minVal = Math.min(RPI, interestFinance);
  console.log(minVal,"min number");

  return (
    <div className="grid grid-cols-5 text-[#444A4C]">
      <div className="md:col-span-3 col-span-5 bg-[#7FC3C3]/[11%] md:p-10 p-5">
        <div className="text-[#274A69] text-center font-semibold md:text-2xl text-xl">
          Your situation
        </div>
        <div>
          <div className="mt-10 flex items-center justify-between">
            <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
              Income
            </label>
            <input
              type="number"
              name="Income"
              value={Income}
              onChange={handleInputChange}
              className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
              placeholder="20000"
            />
          </div>
          <div className="mt-10 flex items-center justify-between">
            <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
              Property Income
            </label>
            <input
              type="number"
              name="propertyIncome"
              value={propertyIncome}
              onChange={handleInputChange}
              className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
              placeholder="0"
            />
          </div>
          <div className="mt-10 flex items-center justify-between">
            <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
              Allowable expenses
            </label>
            <input
              type="number"
              name="allowableExpenses"
              value={allowableExpenses}
              onChange={handleInputChange}
              className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
              placeholder="0"
            />
          </div>
          <div className="mt-10 flex items-center justify-between">
            <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
              Interest on finance costs
            </label>
            <input
              type="number"
              name="interestFinance"
              value={interestFinance}
              onChange={handleInputChange}
              className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
              placeholder="0"
            />
          </div>
        </div>
      </div>
      <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] md:py-10 md:pr-10 md:pl-[4rem] p-5 relative">
        <div className="text-[#274A69] text-center font-semibold text-2xl">
          Tax and profit
        </div>
        {/* <div className="flex items-center justify-between mt-[13%]">
						<div className="text-[#444A4C]">Total earnings</div>
						<div className="text-[#444A4C]">£70,000</div>
				</div>
				<div className="md:mt-[2%]">
						<div className="md:mt-[5%]">
								<p className="font-light text-[10px]">£8,778 already sorted by your employer</p>
						</div>
						<div className="md:mt-[5%]">
								<p className="font-light text-[10px]">£2,000 self-employment expenses</p>
						</div>
				</div>
				<div className="flex items-center justify-between md:mt-[17%] mt-[5%]">
						<div className="text-[#444A4C]">Tax to pay</div>
						<div className="text-[#444A4C]">£10,604</div>
				</div>
				<div className="mt-[8%]">
						<div className="md:mt-[5%]">
								<p className="font-light text-[10px]">£9,146 income tax</p>
						</div>
						<div className="md:mt-[5%]">
								<p className="font-light text-[10px]">£179 class 2 National Insurance</p>
						</div>
						<div className="md:mt-[5%]">
								<p className="font-light text-[10px]">£1,279 class 4 National Insurance</p>
						</div>
				</div>
				<div className="flex items-center justify-between md:mt-[10%] mt-[5%]">
						<div className="text-[#444A4C] font-bold">What you're left with</div>
						<div className="text-[#444A4C] font-bold">£48,618</div>
				</div>
				<div className="absolute top-[25%] left-[7%] lg:block hidden">
						<svg width="23" height="288" viewBox="0 0 23 288" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="11.5" cy="11.5" r="11.5" fill="white"/>
								<circle cx="11.5" cy="134.5" r="11.5" fill="white"/>
								<circle cx="11.5" cy="85.5" r="4.5" fill="white"/>
								<circle cx="11.5" cy="48.5" r="4.5" fill="white"/>
								<circle cx="11.5" cy="205.5" r="4.5" fill="white"/>
								<circle cx="11.5" cy="178.5" r="4.5" fill="white"/>
								<circle cx="11.5" cy="232.5" r="4.5" fill="white"/>
								<path d="M11 18L12 274" stroke="white"/>
								<circle cx="11.5" cy="276.5" r="11.5" fill="white"/>
								<path d="M14.694 274.832V275.91H7.414V274.832H14.694ZM14.694 277.842V278.92H7.414V277.842H14.694Z" fill="black"/>
								<path d="M8 135H15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M11 7.5V15.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M7 11.5H15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
						</svg>               
				</div> */}
      </div>
    </div>
  );
}
