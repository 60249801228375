import PdfToolbar from "../../assets/common/PdfToolbar"
import PrivacyPolicyPdf from "../../assets/legal-docs/Tax_Magpie_Privacy_policy_26.01.24.pdf"

export default function PrivacyPolicy() {
  return (
    <div className="xl:w-[90%] mx-auto">
        <PdfToolbar file={PrivacyPolicyPdf} />
    </div>
  )
}
