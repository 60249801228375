import Surety from "../../../../assets/Surety";
import Topbtn from "../../../../assets/basic/Topbtn";
import CalTopHead from "../allCalProps.jsx/CalTopHead";
import TaxReliefCal from "../allCalProps.jsx/TaxReliefCal";
export default function TaxReliefPage() {
  return (
    <>
      <div className="mx-auto max-w-[1001px] md:px-10 px-5 font-poppins">
        <CalTopHead
          title="Tax Relief for residential landlords"
          para="Quickly calculate how much you can claim for using your vehicle for work. Cars, Bikes, or motorcycles. Give it a go!"
        />
        <TaxReliefCal/>
      </div>
      <div className="pt-10">
        <Surety />
      </div>
      <div className="max-w-7xl flex justify-end p-5">
        <div className="w-[98px]">
          <Topbtn />
        </div>
      </div>
    </>
  );
}
