import { useRef, useState } from "react";
import IsObjectEmpty from "../../../../utils/IsObjectEmpty";
export default function SearchFilters({ isToggle, handleFilteredCleints }) {

  const [clientFilter, setClientFilter] = useState({
    clientRef: "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    mobilePhone: "",
    postcode: "",
    dob: ""
  })

  const [claimFilter, setClaimFilter] = useState({
    claimRef: "",
    claimType: ""
  })

  const [incomeSource, setIncomeSource] = useState("")
  const [plan, setPlan] = useState("")

  const handleFilter = e => {
    const { name, value } = e.target

    if (name === "claimRef" || name === "claimType") {
      setClaimFilter(prevClaimFilter => ({
        ...prevClaimFilter,
        [name]: value
      }))
    } else {
      setClientFilter(prevClientFilter => ({
        ...prevClientFilter,
        [name]: value
      }))
    }
  }

  const filterRef = {
    clientRef: useRef(),
    claimRef: useRef(),
    claimType: useRef(),
    firstName: useRef(),
    lastName: useRef(),
    emailAddress: useRef(),
    mobilePhone: useRef(),
    postcode: useRef(),
    dob: useRef(),
    incomeSource: useRef(),
    planType: useRef()
  }

  const handleResetClick = () => {
    console.log(Object.values(filterRef))
    Object.values(filterRef).forEach(ref => {
      console.log(ref.current.value)
      ref.current.value = ""
    })
  };

  const handleSearch = () => {
    if (IsObjectEmpty(clientFilter) && IsObjectEmpty(claimFilter) && plan === "" && incomeSource === "") {
      return false;
    } else {
      handleFilteredCleints({ ...clientFilter, ...claimFilter, plan, incomeSource })
      isToggle()
    }
  }

  return (
    <>
      <div className="w-full h-full bg-black opacity-40 fixed top-0 left-0 z-10"></div>
      <div className="md:w-[500px] max-md:max-h-[500px] mx-auto px-5 bg-white fixed top-0 right-0 z-20 overflow-scroll">
        <div className="flex items-center justify-between border-b border-cyan-900 py-3 w-full">
          <div className="flex items-center w-full">
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.8042 17.3834C14.2225 17.3834 17.8042 13.8017 17.8042 9.38342C17.8042 4.96514 14.2225 1.38342 9.8042 1.38342C5.38592 1.38342 1.8042 4.96514 1.8042 9.38342C1.8042 13.8017 5.38592 17.3834 9.8042 17.3834Z"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M19.8042 19.3834L15.4542 15.0334"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div>
              <p className="ml-5 text-black text-lg outline-none">Search Filters</p>
            </div>
          </div>
          <div className="cursor-pointer" onClick={isToggle}>
            <svg
              width="23"
              height="23"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.1726 5.7832L5.97229 16.9835"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.97217 5.78345L17.1725 16.9838"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div>
          <div className="bg-neutral-100 mt-5 rounded-md border">
            <input
              name="clientRef"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="text"
              placeholder="Ref"
              ref={filterRef.clientRef}
              value={clientFilter.clientRef}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border">
            <input
              name="claimRef"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="text"
              placeholder="Claim Ref"
              ref={filterRef.claimRef}
              value={claimFilter.claimRef}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border text-[#244B69] pr-5">
            <select
              name="claimType"
              className=" outline-none p-3 bg-neutral-100 w-full text-[#000]/[40%]"
              ref={filterRef.claimType}
              value={claimFilter.claimType}
              onChange={handleFilter}>
              <option value="">Claim Type</option>
              <option value="personalTax">Personal tax return</option>
              <option value="partnershipTax">Partnership tax return</option>
            </select>
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border">
            <input
              name="firstName"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="text"
              placeholder="First Name"
              ref={filterRef.firstName}
              value={clientFilter.firstName}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border">
            <input
              name="lastName"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="text"
              placeholder="Last Name"
              ref={filterRef.lastName}
              value={clientFilter.lastName}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border">
            <input
              name="emailAddress"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="text"
              placeholder="Email Address"
              ref={filterRef.emailAddress}
              value={clientFilter.emailAddress}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border">
            <input
              name="mobilePhone"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="number"
              placeholder="Mobile Phone"
              ref={filterRef.mobilePhone}
              value={clientFilter.mobilePhone}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border">
            <input
              name="postcode"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="text"
              placeholder="Postcode"
              ref={filterRef.postcode}
              value={clientFilter.postcode}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border">
            <input
              name="dob"
              className="bg-neutral-100 p-3 outline-none w-full"
              type="number"
              placeholder="Date of Birth"
              ref={filterRef.dob}
              value={clientFilter.dob}
              onChange={handleFilter}
            />
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border text-[#000]/[40%] pr-5">
            <select
              name="incomeSource"
              className=" outline-none p-3 bg-neutral-100 w-full"
              ref={filterRef.incomeSource}
              value={incomeSource}
              onChange={(e) => { setIncomeSource(e.target.value) }}>
              <option value="">Source of Income</option>
              <option value="self_employment">Self employment</option>
              <option value="employment">Employment</option>
              <option value="property">Property</option>
              <option value="saving_investment">Savings & investments</option>
              <option value="pension">Pension</option>
              <option value="furnished_holiday">Furnished holiday lettings</option>
              <option value="trusts">Trusts</option>
              <option value="rent_room">Rent a Room</option>
              <option value="minister_of_religion">Minister of Religion</option>
              <option value="non_resident">Non Resident</option>
              <option value="partnership">Partnership</option>
              <option value="foreign">Foreign</option>
              <option value="capital_gains">Capital gains</option>
              <option value="donations">Donations/Gift aid</option>
            </select>
          </div>
          <div className="bg-neutral-100 mt-2 rounded-md border text-[#000]/[40%] pr-5">
            <select
              name="plan"
              className=" outline-none p-3 bg-neutral-100 w-full"
              ref={filterRef.planType}
              value={plan}
              onChange={(e) => setPlan(e.target.value)}>
              <option value="">Plan Type</option>
              <option value="Independent">Independent</option>
              <option value="Enhanced">Enhanced</option>
              <option value="Professional">Professional</option>
            </select>
          </div>
          {/* <div className="bg-neutral-100 mt-2 rounded-md border">
          <input
            className="bg-neutral-100 p-3 outline-none w-full"
            type="text"
            placeholder="Plan Type"
          />
        </div> */}
        </div>
        <div className="flex justify-between gap-0 max-md:justify-center py-3">
          <button onClick={handleSearch} className="justify-center text-white bg-emerald-500 text-base font-medium leading-4 whitespace-nowrap border grow items-stretch rounded-md pl-6 pr-7 py-4 max-md:px-5">
            Search Filter
          </button>
          <div onClick={handleResetClick} className=" cursor-pointer pl-5 pr-7 py-4">
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/db013d48dab756ffeda605f1939085876b3c026a8773fbdb74ddce37b9a9ace8?"
              className=""
            />
          </div>
          <div className="cursor-pointer bg-[#D9D9D9] px-7 py-4 rounded-md" onClick={isToggle}>
            <div className="opacity-40 text-base font-medium leading-4">
              Close
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
