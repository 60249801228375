export default function RentRoomCal(){
    return(
            <div className="grid grid-cols-5 text-[#444A4C]">
                <div className="md:col-span-3 col-span-5 bg-[#7FC3C3]/[11%] md:p-10 p-5">
                    <div className="text-[#274A69] text-center font-semibold md:text-2xl text-xl">Your situation</div>
                        <div>
                            <div className="mt-10">
                                <input type="radio" id="Shares" name="money-source" value="Shares"/>
                                <label for="Shares" className="text-[#444A4C] ml-2 md:text-base text-xs">Buy to let(including holiday let)</label>
                            </div>
                            <div className="mt-10 flex items-center justify-between">
                                <label htmlFor="capitalgainsProfit" className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Monthly rental income</label>
                                <input type="text" name="" id="capitalgainsProfit" className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]" placeholder="20000" />
                            </div>
                            <div className="mt-10 flex items-center justify-between">
                                <label htmlFor="capitalgainsProfit" className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Annual salary</label>
                                <div className="flex items-center"> 
                                    <svg className="mr-2" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.5 15.4921C12.6421 15.4921 16 12.1848 16 8.10516C16 4.02549 12.6421 0.718262 8.5 0.718262C4.35786 0.718262 1 4.02549 1 8.10516C1 12.1848 4.35786 15.4921 8.5 15.4921Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6.31763 5.8891C6.49395 5.39541 6.84199 4.97912 7.30009 4.71395C7.75819 4.44877 8.2968 4.35184 8.82051 4.44032C9.34422 4.52879 9.81924 4.79697 10.1614 5.19734C10.5036 5.59771 10.6909 6.10445 10.6901 6.62779C10.6901 8.10517 8.44013 8.84386 8.44013 8.84386" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8.5 11.7986H8.507" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <input type="text" name="" id="capitalgainsProfit" className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]" placeholder="20000" />
                                </div>
                            </div>
                            <div className="mt-10 flex items-center justify-between">
                                <label htmlFor="capitalgainsProfit" className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Other income</label>
                                <div className="flex items-center"> 
                                    <svg className="mr-2" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.5 15.4921C12.6421 15.4921 16 12.1848 16 8.10516C16 4.02549 12.6421 0.718262 8.5 0.718262C4.35786 0.718262 1 4.02549 1 8.10516C1 12.1848 4.35786 15.4921 8.5 15.4921Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M6.31763 5.8891C6.49395 5.39541 6.84199 4.97912 7.30009 4.71395C7.75819 4.44877 8.2968 4.35184 8.82051 4.44032C9.34422 4.52879 9.81924 4.79697 10.1614 5.19734C10.5036 5.59771 10.6909 6.10445 10.6901 6.62779C10.6901 8.10517 8.44013 8.84386 8.44013 8.84386" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M8.5 11.7986H8.507" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <input type="text" name="" id="capitalgainsProfit" className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]" placeholder="20000" />
                                </div>
                            </div>
                        </div>
                    </div>
                <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] md:py-10 md:pr-10 md:pl-[4rem] p-5 relative">
                    <div className="text-[#274A69] text-center font-semibold text-2xl">Tax and profit</div>
                    <div className="flex items-center justify-between mt-[13%]">
                        <div className="text-[#444A4C]">Earnings from rent</div>
                        <div className="text-[#444A4C]">£70,000</div>
                    </div>
                    <div className="md:mt-[12%]">
                        <p className="font-light text-[10px]">£1,000 tax-free property allowance</p>
                    </div>
                    <div className="flex items-center justify-between md:mt-[10%] mt-[5%]">
                        <div className="text-[#444A4C]">Property tax to pay</div>
                        <div className="text-[#444A4C]">£10,604</div>
                    </div>
                    <div className="flex items-center justify-between md:mt-[15%] mt-[5%]">
                        <div className="text-[#444A4C] font-bold">After-tax rental income</div>
                        <div className="text-[#444A4C] font-bold">£48,618</div>
                    </div>
                    <div className="absolute top-[25%] left-[7%] lg:block hidden">
                       <svg width="23" height="185" viewBox="0 0 23 185" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="11.5" cy="11.5" r="11.5" fill="white"/>
                            <circle cx="11.5" cy="110.5" r="11.5" fill="white"/>
                            <circle cx="11.5" cy="61.5" r="4.5" fill="white"/>
                            <path d="M12 10.5L11 177" stroke="white"/>
                            <circle cx="11.5" cy="173.5" r="11.5" fill="white"/>
                            <path d="M14.694 171.832V172.91H7.414V171.832H14.694ZM14.694 174.842V175.92H7.414V174.842H14.694Z" fill="black"/>
                            <path d="M8 110.5H15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M11.5 7V15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.5 11H15.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>           
                    </div>
                </div>
            </div>
     )
}