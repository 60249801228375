import clientService from "../../services/clientService";
import stripeService from "../../services/stripeService";
import FormOne from "./FormOne"
import FormTwo from "./FormTwo";
import FormThree from "./FormThree";
import FormFour from "./FormFour"
import FormFive from "./FormFive"
import FormSix from "./FormSix"
import FormSeven from "./FormSeven"
import { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom'
import '../../Loader.css'
import useUser from "../../hooks/useUser";
import useClient from "../../hooks/useClient";
import Loader from "../../assets/common/Loader";
import planService from "../../services/planService";
//const [currentPage, setCurrentPage] = useState(1);
//const [userData, setUserData] = useState({

const MultiStepForm = () => {

  const [step, setStep] = useState(1);
  const [searchParams] = useSearchParams();
  console.log(searchParams)
  const { loading, setLoading } = useUser();
  const { activeClient, setActiveClient, formData, setFormData } = useClient()

  // Handle initial state based on search params
  useEffect(() => {
    if (searchParams.size === 0) {
      return; // No need to proceed if no searchParams
    }
    fetchSavedData();
  }, [searchParams]);

  const fetchSavedData = async () => {
    try {
      const formDataString = JSON.parse(localStorage.getItem('formData'));
      if (formDataString) {
        await setFormData(formDataString);
        localStorage.clear();
      }
      console.log(formDataString.clientRef)
      const existingClient = await clientService.getClientByRef(formDataString.clientRef);
      setActiveClient(existingClient);

      if (searchParams.get('canceled')) {
        setStep(4);
      } else if (searchParams.get('success')) {
        console.log("Moving towards step 6")
        setStep(6);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    console.log(step)
    if (step === 5) {
      initializeStripeService()
    } else if (step === 6) {
      setLoading(true)
      planService.create({ ...formData?.bankState, client: activeClient.id }).then(data => {
        console.log(data);
        setLoading(false)
      }).catch(error => {
        console.log(error);
        setLoading(false)
      })
    }
  }, [step])

  const initializeStripeService = async () => {
    formData.clientRef = activeClient.ref
    localStorage.setItem('formData', JSON.stringify(formData));
    await stripeService
      .create(formData.price)
      .then(response => {
        console.log(response.url)
        window.location.href = response.url;
      })
      .catch(error => {
        console.error('Error creating checkout session:', error);
      });
  }

  const handleNext = (data) => {
    console.log(data)
    // window.scrollTo({ top: 0, behavior: "smooth" });
    setFormData({ ...formData, ...data });
    setStep(step + 1);
    setLoading(false);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };
  console.log(formData, "Form Data From Parent")

  return (
    <div>
      {loading
        ? <Loader />
        : <>
          {step === 1 && (
            <FormOne
              onNext={handleNext}
            />
          )}
          {step === 2 && (
            <FormTwo
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
          {step === 3 && (
            <FormThree
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
          {step === 4 && (
            <FormFour
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
          {step === 6 && (
            <FormFive
              onNext={handleNext}
            />
          )}
          {step === 7 && (
            <FormSix
              onNext={handleNext}
            />
          )}
          {step === 8 && (
            <FormSeven />
          )}
        </>}
    </div>
  );
};

export default MultiStepForm;

// {
//   step > 3 && (<h1>Not Found</h1>)
// }