import { Suspense, lazy, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import clientService from "../../../services/clientService";
import useUser from "../../../hooks/useUser";
import useClient from "../../../hooks/useClient";
import CRMSideBar from "./CRMSideBar";
import ClientTab from "./crmTabs/ClientTab";
import PaymentTab from "./crmTabs/PaymentTab";
import planService from "../../../services/planService";
import CrmTabsState from "../../../context/tabs/CrmTabsState";

import ClientInfoTabIcon from "../../../assets/images/ClientInfoTabIcon.svg"
import ClaimTabIcon from "../../../assets/images/ClaimTabIcon.svg"
import DocumentsTabIcon from "../../../assets/images/DocumentsTabIcon.svg"
import NotesTabIcon from "../../../assets/images/NotesTabIcon.svg"
import MessagesTabIcon from "../../../assets/images/MessagesTabIcon.svg"
import PaymentsTabIcon from "../../../assets/images/PaymentsTabIcon.svg"
import EmailTab from "./crmTabs/EmailTab";
import SmsTab from "./crmTabs/SmsTab";
import LogsTab from "./crmTabs/LogsTab";

const MessagesTab = lazy(() => import("./crmTabs/MessagesTab"))
const NotesTab = lazy(() => import("./crmTabs/NotesTab"))
const DocumentsTab = lazy(() => import("./crmTabs/DocumentsTab"))
const ClaimTab = lazy(() => import("./crmTabs/ClaimTab"))
export default function ClientInfo() {
  const tabs = [
    { id: "1", label: "Client Info", icon: ClientInfoTabIcon },
    { id: "2", label: "Claims", icon: ClaimTabIcon },
    { id: "3", label: "Documents", icon: DocumentsTabIcon },
    { id: "4", label: "Notes", icon: NotesTabIcon },
    { id: "5", label: "Messages", icon: MessagesTabIcon },
    { id: "6", label: "Payment", icon: PaymentsTabIcon },
  ];
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const [pseudoPosition, setPseudoPosition] = useState("0");
  const [plan, setPlan] = useState([])
  const [colorScheme, setColorScheme] = useState("[#244B69]")
  const [toggleSideBar, setToggleSideBar] = useState(false)

  const params = useParams();

  const { setActiveClient } = useClient();
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientCrm()
  }, []);

  // useEffect(() => {
  //   return () => {
  //     setActiveClient({});
  //   };
  // }, []);

  const fetchClientCrm = async () => {
    const client = await clientService.getClientByRef(params.ref);
    client ? (await setActiveClient(client)) : navigate("/")
    console.log(client);
    await fetchClientPlan(client)
  };

  const fetchClientPlan = async (client) => {
    try {
      const returnedPlan = await planService.getPlan(client.id);
      returnedPlan && (await setPlan(returnedPlan));
      console.log(returnedPlan);
      handleColorScheme(returnedPlan.type)
    }
    catch (error) {
      console.log("no payment plans for this client")
    }
  };

  const handleColorScheme = (planType) => {
    setColorScheme((planType === "Independent") ? "independent" : (planType === "Enhanced") ? "enhanced" : "professional")
  }

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    var cases = {
      1: "0%",
      2: "15%",
      3: "32%",
      4: "49%",
      5: "65%",
      6: "80%",
    };

    switch (tabId) {
      case "1":
      case "2":
      case "3":
      case "4":
      case "5":
      case "6":
        setPseudoPosition(cases[tabId]);
        break;
      default:
        break;
    }
  };
  return (
    <div className="bg-neutral-100 xl:px-16">
      <div className="bg-white grid grid-cols-4 relative">
        <div className="absolute top-5 left-3 lg:left-5">
          <Link to="/">
            <svg
              width="34"
              height="35"
              viewBox="0 0 34 35"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.0677 32.7587C25.4776 32.7587 32.2952 25.9411 32.2952 17.5312C32.2952 9.12138 25.4776 2.30383 17.0677 2.30383C8.65788 2.30383 1.84033 9.12138 1.84033 17.5312C1.84033 25.9411 8.65788 32.7587 17.0677 32.7587Z"
                fill="#00B67A"
                stroke="#00B67A"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M17.0678 11.4403L10.9768 17.5312L17.0678 23.6222"
                fill="#00B67A"
              />
              <path
                d="M17.0678 11.4403L10.9768 17.5312L17.0678 23.6222"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M23.1587 17.5312H10.9768"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
        {toggleSideBar && <div className="max-md:absolute max-md:w-full max-md:min-h-[inherit] max-md:inset-0 max-md:bg-cyan-900/30 max-md:z-10"></div>}
        <div className={`max-lg:flex max-lg:items-start max-lg:absolute max-lg:z-10 max-lg:right-0 ${toggleSideBar ? "max-lg:translate-x-0" : "max-lg:translate-x-[15.8rem]"} transition-all`}>
          <button className="flex justify-center items-center lg:hidden mt-4 bg-[--base-color] text-white py-1 w-6 text-2xl rounded-l-md" onClick={(e) => setToggleSideBar(!toggleSideBar)}>{toggleSideBar ? "-" : "+"}</button>
          <div className="max-lg:bg-white">
            <CrmTabsState>
              <CRMSideBar colorScheme={colorScheme} handleTabClick={handleTabClick} activeTab={activeTab} />
            </CrmTabsState>
          </div>
        </div>
        <div className="col-span-4 lg:col-span-3 px-3 lg:px-5 xl:px-10 py-20 border border-[#D9D9D9] min-h-screen">
          <div className="fixed bottom-0 left-0 w-full max-lg:bg-white max-lg:border-t-2 lg:relative text-sm lg:text-lg font-normal text-[#1E1E1E] flex justify-around items-center lg:border-b-2 border-[#D9D9D9] lg:pb-5 z-20">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={`tab ${activeTab === tab.id ? "active max-lg:bg-[#7099bd]" : ""} max-lg:p-4 max-lg:h-full`}
                style={{ "--pseudo-position": pseudoPosition }}
              >
                <span className="max-lg:hidden">{tab.label}</span>
                <span className="lg:hidden"><img src={tab.icon} alt="" width={"40rem"} /></span>
              </button>
            ))}
          </div>

          {tabs.map((tab) => (
            <div
              key={tab.id}
              id={`content${tab.id}`}
              className={`tab-content ${activeTab === tab.id ? "" : "hidden"}`}
            >
              {tab.id === "1" && <ClientTab />}

              {tab.id === "2" &&
                <Suspense>
                  <CrmTabsState>
                    <ClaimTab />
                  </CrmTabsState>
                </Suspense>}

              {tab.id === "3" &&
                <Suspense>
                  <CrmTabsState>
                    <DocumentsTab />
                  </CrmTabsState>
                </Suspense>}

              {tab.id === "4" &&
                <Suspense>
                  <CrmTabsState>
                    <NotesTab />
                  </CrmTabsState>
                </Suspense>}

              {tab.id === "5" &&
                <Suspense>
                  <CrmTabsState>
                    <MessagesTab activeTab={activeTab} />
                  </CrmTabsState>
                </Suspense>}

              {tab.id === "6" &&
                <Suspense>
                  <PaymentTab plan={plan} colorScheme={colorScheme} />
                </Suspense>}
            </div>
          ))}

          {activeTab === "email" &&
            <EmailTab />
          }

          {activeTab === "sms" &&
            <SmsTab />
          }

          {/* {activeTab === "logs" &&
            <LogsTab clientDate={activeClient.created_at} />
          } */}
        </div>
      </div>

    </div>
  );
}
