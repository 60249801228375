import { Outlet } from "react-router";
import Header from "./includes/Header";
import Footer from "./includes/Footer";
import Minifooter from "./includes/Minifooter";
import useUser from "../../hooks/useUser";
import AuthHeader from "./includes/AuthHeader";

export default function Main() {
  const { userMode } = useUser();
  return (
    <div className="flex flex-col justify-between relative">
      {userMode === "" ? <Header /> : <AuthHeader />}
      <Outlet />
      <Footer />
      <Minifooter />
    </div>
  );
}
