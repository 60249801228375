import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import LeftArrow from "../../assets/basic/leftArrow";
import Priceplan from "../../assets/common/Priceplan";

export default function UpgradePlan({ text, plan }) {
  return (
    <div>
      <Popup
        trigger={
          <button className="bg-[#244B69] text-white w-full p-2 hover:bg-white hover:text-[#244B69] shadow-md">
            {text}
          </button>
        }
        modal
      >
        {(close) => (
          <div className="w-full bg-[rgba(36,75,105,0.40)] lg:pt-20 lg:pb-32">
            <div className="mx-auto lg:w-4/5 w-full">
              <div className="flex justify-start text-xl text-[--primary-color] py-5">
                <button
                  onClick={close}
                  className="w-[15%] h-[70px] bg-[#244B69] flex justify-center items-center"
                >
                  <LeftArrow />
                </button>
              </div>
              <div className="pricingModal">
                <Priceplan upPlan={true} plan={plan} />
                {/* <div className="bg-[#7FC2C2]/[29%] px-10 py-[5rem]">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 text-center max-w-[990px]">
                  <div className="w-[152px] h-[152px] mx-auto">
                    <img
                      src="../images/priceimg.png"
                      alt=""
                      className="w-full"
                    />
                  </div>
                  <div className="text-[#00B67A] font-bold md:text-[2.5rem] text-[1.5rem] border-b-[4px] py-10 border-[#444A4C] ">
                    Price Plans
                  </div>
                  <div>
                    <div className="py-5 border-b-[1px] border-[#444A4C]">
                      <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
                        £64.99 +VAT
                      </div>
                      <p className="text-[#244B69] md:text-2xl text-base">
                        (Without accountant help)
                      </p>
                      <div className="py-5">
                        <input
                          type="radio"
                          className="hidden"
                          id="option1"
                          onChange={handleChange}
                          name="price"
                          checked={bankState.price === "£64.99 +VAT"}
                          value="£64.99 +VAT"
                        />
                        <label
                          htmlFor="option1"
                          className="pay-price text-[#ECBD41] font-medium md:text-2xl text-xl bg-[#8E8E8E;] py-3 px-10 my-5"
                        >
                          Get started
                        </label>
                      </div>
                    </div>
                    <div className="py-5 border-b-[1px] border-[#444A4C]">
                      <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
                        £99 +VAT
                      </div>
                      <p className="text-[#244B69] md:text-2xl text-base">
                        (With accountant help)
                      </p>
                      <div className="py-5">
                        <input
                          type="radio"
                          className="hidden"
                          id="option2"
                          onChange={handleChange}
                          name="price"
                          checked={bankState.price === "£99 +VAT"}
                          value="£99 +VAT"
                        />
                        <label
                          htmlFor="option2"
                          className="pay-price text-white font-medium md:text-2xl text-xl bg-[#8E8E8E;] py-3 px-10 my-5"
                        >
                          Get started
                        </label>
                      </div>
                    </div>
                    <div className="py-5">
                      <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
                        £220 +VAT
                      </div>
                      <p className="text-[#244B69] md:text-2xl text-base">
                        (Accountant help plus consultation)
                      </p>
                      <div className="py-5">
                        <input
                          type="radio"
                          className="hidden"
                          id="option3"
                          onChange={handleChange}
                          name="price"
                          checked={bankState.price === "£220 +VAT"}
                          value="£220 +VAT"
                        />
                        <label
                          htmlFor="option3"
                          className="pay-price text-white font-medium md:text-2xl text-xl bg-[#8E8E8E;] py-3 px-10 my-5"
                        >
                          Get started
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}
