import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import clientService from "../../services/clientService";
import useClient from "../../hooks/useClient";
import useUser from "../../hooks/useUser";
import userService from "../../services/userService";
export default function SetPasswordForm() {
  const params = useParams();
  const navigate = useNavigate()
  const { handleLogin, setUserLogin, handleUser, setLoading } = useUser()
  const { activeClient, setActiveClient, formData, setFormData } = useClient()
  const [ password, setPassword ] = useState({
    pass: "",
    cPass: ""
  })
  const [ passwordError, setPasswordError ] = useState("")

  useEffect(()=> {
    fetchClientUsingRef()
  }, [params.ref])

  const fetchClientUsingRef = async () => {
    const client = await clientService.getClientByRef(params.ref)
    console.log(client);
    setActiveClient(client)
      // if (client.password !== "") {
      //   console.log(client)
      //   navigate("/login")
      // }
  }

  function handleChange(event) {
    setPasswordError("")
    setPassword((prevPassword) => {
      return {
        ...prevPassword,
        [event.target.name]: event.target.value,
      };
    });
  }

  const handlePassword = async (e) => {
    e.preventDefault()
    if (password.pass !== password.cPass) {
      setPasswordError("Passwords do not match")
      return;
    } else {
      setUserLogin({
        userEmail: activeClient.email_address,
        userPassword: password.pass
      })

      const data = {
        ...formData,
        userInfo: {
          userPassword: password.pass,
          emailAddress: activeClient.email_address
        }
      }
      setFormData(data)
      console.log(data)
      setLoading(true)
      userService.update({...data?.userInfo}).then(data => {
        console.log(data);
        // await handleLogin(e)
        navigate('/login')
        setLoading(false)
      }).catch(error => {
        console.log(error);
        setLoading(false)
      })
    }
  }

  return (
    <div className="relative">
      <div className="bg-[#9FD2D2] font-poppins">
        <form className="flex justify-center items-center flex-col h-[70vh] z-10 relative">
          <div className="lg:w-2/5 w-11/12 mt-10 mb-5 flex items-center">
            <div className="absolute lg:left-[31%] left-7">
              <svg
                width="26"
                height="29"
                viewBox="0 0 26 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 13.25H4.5C3.11929 13.25 2 14.3693 2 15.75V24.5C2 25.8807 3.11929 27 4.5 27H22C23.3807 27 24.5 25.8807 24.5 24.5V15.75C24.5 14.3693 23.3807 13.25 22 13.25Z"
                  stroke="#184074"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.99988 13.25V8.25C6.99988 6.5924 7.65836 5.00269 8.83046 3.83058C10.0026 2.65848 11.5923 2 13.2499 2C14.9075 2 16.4972 2.65848 17.6693 3.83058C18.8414 5.00269 19.4999 6.5924 19.4999 8.25V13.25"
                  stroke="#184074"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input
              placeholder="Enter password"
              value={password.pass}
              name="pass"
              type="password"
              onChange={handleChange}
              required
              className="w-full py-6 lg:py-8 lg:pl-[4rem] pl-14 pr-5 rounded-lg"
            />
          </div>
          <div className="lg:w-2/5 w-11/12 flex items-center">
            <div className="absolute lg:left-[31%] left-7">
              <svg
                width="26"
                height="29"
                viewBox="0 0 26 29"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 13.25H4.5C3.11929 13.25 2 14.3693 2 15.75V24.5C2 25.8807 3.11929 27 4.5 27H22C23.3807 27 24.5 25.8807 24.5 24.5V15.75C24.5 14.3693 23.3807 13.25 22 13.25Z"
                  stroke="#184074"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.99988 13.25V8.25C6.99988 6.5924 7.65836 5.00269 8.83046 3.83058C10.0026 2.65848 11.5923 2 13.2499 2C14.9075 2 16.4972 2.65848 17.6693 3.83058C18.8414 5.00269 19.4999 6.5924 19.4999 8.25V13.25"
                  stroke="#184074"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <input
              placeholder="Confirm password"
              value={password.cPass}
              name="cPass"
              type="password"
              onChange={handleChange}
              required
              className="w-full py-6 lg:py-8 lg:pl-[4rem] pl-14 pr-5 rounded-lg"
            />
          </div>
          {passwordError && <p className="text-red-500 text-xs mt-5">{passwordError}</p>}
          <button
            className="lg:w-2/5 w-11/12 bg-[#ECBD41] text-[#fff] mt-5 flex justify-center items-center py-4 border-8 rounded-2xl border-[#fff] text-2xl"
            onClick={handlePassword}
          >
            Confirm
          </button>
        </form>
      </div>
      <div className="absolute top-[20%] left-[5%] hidden lg:block">
        <img src="../images/bird-full.svg" alt="" />
      </div>
      <div className="absolute top-[70%] right-[10%] hidden lg:block">
        <img src="../images/bird-half.svg" alt="" />
      </div>
    </div>
  );
}
