import { useState, useEffect } from "react";
export default function EmployedCal() {
  const [annualSalary, setAnnualSalray] = useState(40000);
  const [selfEmployedIncome, setSelfEmployedIncome] = useState(30000);
  const [selfEmployedExpenses, setSelfEmployedExpenses] = useState(2000);
  const [incomeTax, setIncomeTax] = useState(0);
  const [niTax, setNITax] = useState(0);
  const [selfEmployedincomeTax, setSelfEmployedincomeTax] = useState(0);
  const [selfEmployedniTax, setSelfEmployedniTax] = useState(0);

  let total = 0;
  const income = Number(selfEmployedIncome) || 0;
  const expenses = Number(selfEmployedExpenses) || 0;
  const anualSalary = Number(annualSalary) || 0;

  const remainingIncome = income - expenses;
  total = remainingIncome + anualSalary;
  const subtotal = income + anualSalary;

 
  const handleAnnualSalrayChange = (event) => {
   const  annualSalary = event.target.value;
    setAnnualSalray(annualSalary);
  };

	useEffect(()=>{
		if (annualSalary <= 12570) {
      setIncomeTax(0);
      setNITax(0);
    } else if (annualSalary <= 50270) {
      setIncomeTax((annualSalary - 12570) * 0.2);
      setNITax((annualSalary - 12570) * 0.12);
			console.log("2nd condition")
    } else {
      setIncomeTax((50270 - 12570) * 0.2 + (annualSalary - 50270) * 0.4);
      setNITax((50270 - 12570) * 0.12 + (annualSalary - 50270) * 0.02);
			console.log("in 3rd condition")
    }
	},[annualSalary])

  const totalAnnualTax = incomeTax + niTax;

	console.log(incomeTax)
	console.log(niTax)
	console.log(annualSalary)

  // ********2nd INPUT ***********

  const handleSelfEmployedIncomeChange = (event) => {
    const selfEmployedIncome = event.target.value;
    setSelfEmployedIncome(selfEmployedIncome);
  };
  useEffect(() => {

    if (total <= 12570) {
      setSelfEmployedincomeTax(0);
      setSelfEmployedniTax(0);
    } else if (total <= 50270) {
      const selfEmployedincomeTax = (50270 - annualSalary) * 0.2;
      const selfEmployedniTax = (50270 - annualSalary) * 0.09;
      setSelfEmployedincomeTax(selfEmployedincomeTax);
      setSelfEmployedniTax(selfEmployedniTax);
			console.log("SECOND CONDITION")
    } else if (total <= 125140) {
      const selfEmployedincomeTax = (50271 - annualSalary) * 0.2 + (total - 50271) * 0.4;
      const selfEmployedniTax = (50271 - annualSalary) * 0.09 + (total - 50271) * 0.02;
      setSelfEmployedincomeTax(selfEmployedincomeTax);
      setSelfEmployedniTax(selfEmployedniTax);
			console.log("THIRD CONDITION")
    }
    else {
        const selfEmployedincomeTax = (50270 - annualSalary) * 0.2 + (total - 50270) * 0.4 + (total - 125140) * 0.45;
        const selfEmployedniTax = (50270 - annualSalary) * 0.09 + (total - 125140) * 0.02;
        setSelfEmployedincomeTax(selfEmployedincomeTax);
        setSelfEmployedniTax(selfEmployedniTax);
    }
    console.log("here", 29000 * 0.02)
		console.log("selfEmployedincomeTax", selfEmployedincomeTax, "selfEmployedniTax", selfEmployedniTax)
  }, [total, annualSalary, selfEmployedincomeTax, selfEmployedniTax]);

	
  const totalTaxToPay = (selfEmployedincomeTax + selfEmployedniTax) + 179;
  const leftProfit = subtotal - totalAnnualTax - expenses - totalTaxToPay;

  // console.log( subtotal - (totalAnnualTax - expenses - totalTaxToPay))

  const handleSelfEmployedExpensesChange = (event) => {
    const expenses = event.target.value;
    setSelfEmployedExpenses(expenses);
    // if (expenses <= 1000) {
      
    // }
  };

  return (
    <>
      <div className="grid grid-cols-5 text-[#444A4C]">
        <div className="md:col-span-3 col-span-5 bg-[#7FC3C3]/[11%] md:p-10 p-5">
          <div className="text-[#274A69] text-center font-semibold md:text-2xl text-xl">
            Your situation
          </div>
          <div>
            <div className="mt-10 flex items-center justify-between">
              <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
                Annual salary (gross)
              </label>
							<div className="relative">
							  <span className="absolute inset-y-0 right-0 flex items-center pr-2">£</span>
								<input
									type="number"
									name="annualSalary"
									onChange={handleAnnualSalrayChange}
									className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
									value={anualSalary}
								/>
							</div>
            </div>
            <div className="mt-10 flex items-center justify-between">
              <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
                Self-employed income
              </label>
							<div className="relative">
								<input
									type="number"
									name="selfEmployedIncome"
									onChange={handleSelfEmployedIncomeChange}
									className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
									value={selfEmployedIncome}
								/>
								<span className="absolute inset-y-0 right-0 flex items-center pr-2">£</span>
							</div>
            </div>
            <div className="mt-10 flex items-center justify-between">
              <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
                Self-employed expenses
              </label>
							<div className="relative">
						   	<span className="absolute inset-y-0 right-0 flex items-center pr-2">£</span>
								<input
									type="number"
									name="selfEmployedExpenses"
									onChange={handleSelfEmployedExpensesChange}
									className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
								value={selfEmployedExpenses}
								/>
							</div>
            </div>
          </div>
        </div>
        <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] md:py-10 md:pr-10 md:pl-[4rem] p-5 relative">
          <div className="text-[#274A69] text-center font-semibold text-2xl">
            Tax and profit
          </div>
          <div className="flex items-center justify-between mt-[13%]">
            <div className="text-[#444A4C]">Total earnings</div>
            <div className="text-[#444A4C]">£ {subtotal}</div>
          </div>
          <div className="md:mt-[2%]">
            <div className="md:mt-[5%]">
              <p className="font-light text-[10px]">
                £ {totalAnnualTax.toFixed()} already sorted by your employer
              </p>
            </div>
            <div className="md:mt-[5%]">
							{expenses > 1000 ? (
									<p className="font-light text-[10px]">
										£ {expenses} self-employment expenses
									</p>
								) : (
									<p className="font-light text-[10px]">
										£1,000 tax-free Trading Allowance
									</p>
								)}
            </div>
          </div>
          <div className="flex items-center justify-between md:mt-[17%] mt-[5%]">
            <div className="text-[#444A4C]">Tax to pay</div>
            <div className="text-[#444A4C]">£ {totalTaxToPay.toFixed()}</div>
          </div>
          <div className="mt-[8%]">
            <div className="md:mt-[5%]">
              <p className="font-light text-[10px]">
                £ {selfEmployedincomeTax.toFixed()} income tax
              </p>
            </div>
            <div className="md:mt-[5%]">
              <p className="font-light text-[10px]">
                £179 class 2 National Insurance
              </p>
            </div>
            <div className="md:mt-[5%]">
              <p className="font-light text-[10px]">
                £ {selfEmployedniTax.toFixed()} class 4 National Insurance
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between md:mt-[10%] mt-[5%]">
            <div className="text-[#444A4C] font-bold">
              What you're left with
            </div>
            <div className="text-[#444A4C] font-bold">£ {leftProfit.toFixed()}</div>
          </div>
          <div className="absolute top-[25%] left-[7%] lg:block hidden">
            <svg
              width="23"
              height="288"
              viewBox="0 0 23 288"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
              <circle cx="11.5" cy="134.5" r="11.5" fill="white" />
              <circle cx="11.5" cy="85.5" r="4.5" fill="white" />
              <circle cx="11.5" cy="48.5" r="4.5" fill="white" />
              <circle cx="11.5" cy="205.5" r="4.5" fill="white" />
              <circle cx="11.5" cy="178.5" r="4.5" fill="white" />
              <circle cx="11.5" cy="232.5" r="4.5" fill="white" />
              <path d="M11 18L12 274" stroke="white" />
              <circle cx="11.5" cy="276.5" r="11.5" fill="white" />
              <path
                d="M14.694 274.832V275.91H7.414V274.832H14.694ZM14.694 277.842V278.92H7.414V277.842H14.694Z"
                fill="black"
              />
              <path
                d="M8 135H15"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 7.5V15.5"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 11.5H15"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  );
}
