import Home from "../views/HomeView";
import Getstarted from "../views/GetstartedView";
import AboutUs from "../views/AboutView";
import UserArea from "../views/UserView";
import Guides from "../views/GuidesView";
import FAQS from "../views/FAQSView";
import Service from "../views/ServiceView";
import LoginView from "../views/LoginView";
import ResourcesView from "../views/ResourcesView";
import CapitalgainPage from "../components/resource/calPages/calPages/CapitalgainsPage";
import NationalInsurancePage from "../components/resource/calPages/calPages/NationalInsurancePage";
import EmployedPage from "../components/resource/calPages/calPages/EmployedPage";
import PensionTaxReliefPage from "../components/resource/calPages/calPages/PensionTaxReliefPage";
import WFHCal from "../components/resource/calPages/calPages/WFHCal";
import LandlordPage from "../components/resource/calPages/calPages/LandlordPage";
import RentRoomPage from "../components/resource/calPages/calPages/RentRoomPage";
import MileagePage from "../components/resource/calPages/calPages/MileagePage";
import TaxReliefPage from "../components/resource/calPages/calPages/TaxReliefPage";
import PasswordView from "../views/PasswordView";
import CRM from "../components/crm/CRM"
import ClientInfo from "../components/crm/crm-component/ClientInfo";
import AddUpdateClient from "../components/crm/crm-component/AddUpdateClient";
import Messages from "../components/userPage/Messages";
import Documents from "../components/userPage/Documents";
import TermsConditionsView from "../views/TermsConditionsView";
import PrivacyPolicyView from "../views/PrivacyPolicyView";
import Profile from "../components/userPage/Profile";

export const nav = [
    {path: "/", name: "Home", element: <Home/>, isPrivate: false, isAgent: false, isClient: false},
    {path: "/services", name: "Services", element: <Service/>, isPrivate: false, isAgent: false, isClient: false},
    {path: "/about-us", name: "AboutUs", element: <AboutUs/>, isPrivate: false, isAgent: true, isClient: true},
    {path: "/faqs", name: "FAQS", element: <FAQS/>, isPrivate: false, isAgent: false, isClient: false},
    {path: "/terms-and-conditions", name: "TermsAndConditions", element: <TermsConditionsView/>, isPrivate: false, isAgent: true, isClient: true},
    {path: "/privacy-policy", name: "PrivacyPolicy", element: <PrivacyPolicyView/>, isPrivate: false, isAgent: true, isClient: true},
    
    {path: "/resources", name: "Resources", element: <ResourcesView/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/capitalgainsCal", name: "CapitalGainsCal", element: <CapitalgainPage/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/nationalinsuranceCal", name: "NationalInsuranceCal", element: <NationalInsurancePage/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/employedCal", name: "EmployedCal", element: <EmployedPage/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/pensionReliefCal", name: "PensionReliefCal", element: <PensionTaxReliefPage/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/WFHCal", name: "WFHCal", element: <WFHCal/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/landlordCal", name: "LandlordCal", element: <LandlordPage/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/rentroomCal", name: "RentRoomCal", element: <RentRoomPage/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/milageCal", name: "MileageCal", element: <MileagePage/>, isPrivate: false, isAgent: false, isClient: true},
    {path: "/resources/residentiallandlordCal", name: "TaxReliefCal", element: <TaxReliefPage/>, isPrivate: false, isAgent: false, isClient: true},
    
    {path: "/login", name: "Login", element: <LoginView/>, isPrivate: false, isAgent: false, isClient: false},
    {path: "/set-password/:ref", name: "SetPassword", element: <PasswordView/>, isPrivate: false, isAgent: false, isClient: false},
    {path: "/form", name: "GetStarted", element: <Getstarted/>, isPrivate: false, isAgent: false, isClient: false},

    {path: "/", name: "ClientView", element: <UserArea/>, isPrivate: true, isAgent: false, isClient: true},
    {path: "/guides", name: "ClientViewGuides", element: <Guides/>, isPrivate: true, isAgent: false, isClient: true},
    {path: "/messages", name: "ClientViewMessages", element: <Messages/>, isPrivate: true, isAgent: false, isClient: true},
    {path: "/documents", name: "ClientViewDocuments", element: <Documents/>, isPrivate: true, isAgent: false, isClient: true},
    {path: "/profile", name: "ClientViewProfile", element: <Profile/>, isPrivate: true, isAgent: false, isClient: true},
    
    {path: "/", name: "CRM", element: <CRM/>, isPrivate: true, isAgent: true, isClient: false},
    {path: "/:ref", name: "CRMSingleClient", element: <ClientInfo/>, isPrivate: true, isAgent: true, isClient: false},
    {path: "/addClient", name: "CRMAddClient", element: <AddUpdateClient/>, isPrivate: true, isAgent: true, isClient: false},
]
