import { Link } from "react-router-dom"
import useUser from "../../../hooks/useUser"
import useClient from "../../../hooks/useClient"

export default function ProfileDropDown({ isToggle }) {
    const { logoutSession, userMode, activeUser } = useUser()
    const { activeClient } = useClient()
    return (
        <>
            <div onClick={isToggle} className="w-full h-[92%] bg-black opacity-40 absolute top-28 left-0 z-30"></div>
            <div className="bg-white absolute top-28 right-0 w-64 z-40 flex flex-col justify-center">
                {userMode === "client" &&
                    <Link
                        className="flex justify-between font-light items-center text-xl text-[#244B69] hover:bg-gray-300 w-full py-3 px-8"
                        to={`/profile`}
                        onClick={isToggle}
                    >
                        <p>Profile</p>
                    </Link>}
                <button onClick={logoutSession} className="flex justify-between font-light items-center text-xl text-[#244B69] hover:bg-gray-300 w-full py-3 px-8">
                    <p>Logout</p>
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.79749 19.0703H3.79749C3.26705 19.0703 2.75834 18.8596 2.38327 18.4845C2.0082 18.1095 1.79749 17.6007 1.79749 17.0703V3.07031C1.79749 2.53988 2.0082 2.03117 2.38327 1.6561C2.75834 1.28103 3.26705 1.07031 3.79749 1.07031H7.79749" stroke="#244B69" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M14.7975 15.0703L19.7975 10.0703L14.7975 5.07031" stroke="#244B69" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M19.7975 10.0703H7.79749" stroke="#244B69" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
        </>
    )
}