import Surety from "../../../../assets/Surety";
import Topbtn from "../../../../assets/basic/Topbtn";
import CalTopHead from "../allCalProps.jsx/CalTopHead";
import React, {useState } from 'react';
export default function WFHCal(){
    const [inputValues, setInputValues] = useState({
        cost: 0,
        roomsTotal: 0,
        roomsUsedForBusiness: 0,
        totalHours: 0,
        businessHours: 0,
        total: 0,
    });

    function isNumber(value) {
        return typeof value === 'number' && isFinite(value);
      }

    const handleInputChange = (e, field) => {
        const value = e.target.value;
        const updatedValues = ({
            ...inputValues,
            [field]: value,         
        })
        setInputValues(updatedValues);
        subtotal(updatedValues)
    };
   
    function subtotal(updatedValues) {
        console.log(updatedValues)
        const { cost, roomsTotal, roomsUsedForBusiness, totalHours, businessHours } = updatedValues;
        const result = (((cost/roomsTotal)*roomsUsedForBusiness)/totalHours)*businessHours;
        if (isNumber(result))
            setInputValues(prev => ({
                ...prev,
                total: result,
            }));
    
        console.log(result)
    }
  
    return(
    <>
        <div className="mx-auto max-w-[1001px] md:px-10 px-5 font-poppins">
            <CalTopHead
                title="Work from Home Calculator"
                para="Calculate how much work from home tax should get you."
            />
            <div className="bg-[#E6F6DE] font-poppins text-[#444A4C] md:p-20 p-5">
                <div className="text-[#274A69] text-center font-semibold md:text-2xl text-xl">Your situation</div>
                    <div className="mt-10">
                        <p className="md:text-base text-sm">1. Work out your total costs of electricity, gas, council tax, mortgage interest or rent, internet and telephone use.</p>
                        <input type="number" className="outline-none w-full py-3 px-5 drop-shadow-xl my-4" placeholder="£ 0"
                        onChange={(e) => handleInputChange(e, 'cost')} />
                    </div>
                    <div className="my-5">
                        <p className="md:text-base text-sm">2. How many rooms are there in your house?</p>
                        <input type="number" className="outline-none w-full py-3 px-5 drop-shadow-xl my-4" placeholder="£ 0" 
                        onChange={(e) => handleInputChange(e, 'roomsTotal')}/>
                    </div>
                    <div className="my-5">
                        <p className="md:text-base text-sm">3. How many rooms do you use for business purposes?</p>
                        <input type="number" className="outline-none w-full py-3 px-5 drop-shadow-xl my-4" placeholder="£ 0" 
                        onChange={(e) => handleInputChange(e, 'roomsUsedForBusiness')}/>
                    </div>
                    <div className="my-5">
                        <p className="md:text-base text-sm">4. How many hours do you use that room in total?</p>
                        <input type="number" className="outline-none w-full py-3 px-5 drop-shadow-xl my-4" placeholder="£ 0" 
                        onChange={(e) => handleInputChange(e, 'totalHours')}/>
                    </div>
                    <div className="my-5">
                        <p className="md:text-base text-sm">5. How many of those hours are for business purposes?</p>
                        <input type="number" className="outline-none w-full py-3 px-5 drop-shadow-xl my-4" placeholder="£ 0" 
                        onChange={(e) => handleInputChange(e, 'businessHours')}/>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className="font-bold md:text-2xl text-base lg:w-[20%] w-[40%]">Total <span className="md:ml-7 ml-2">=</span></div>
                        <div className="outline-none w-full py-3 px-5 drop-shadow-xl my-4 bg-white">£ {inputValues.total.toFixed()}</div>
                    </div>
            </div>
        </div>
        <div className="pt-10">
          <Surety />
        </div>
        <div className="max-w-7xl flex justify-end p-5">
            <div className="w-[98px]">
                <Topbtn />
            </div>
        </div>
    </>
    )
}