import BacktoTop from "../../assets/basic/backtoTop";
import Surety from "../../assets/Surety";
import React, { useState} from "react";


const Service = () => {
  const [activeItem, setActiveItem] = useState("button1");
  const [pseudoPosition, setPseudoPosition] = useState('0');

 const handleButtonClick = (buttonId) => {
  setActiveItem(buttonId);

  // switch (buttonId) {
  //   case "button1":
  //     setPseudoPosition('0%')
  //     break;
  //   case "button2":
  //     setPseudoPosition('25%')
  //     break;
  //   case "button3":
  //     setPseudoPosition('50%')
  //     break;
  //   case "button4":
  //     setPseudoPosition('75%')
  //     break;
  // }
  if(buttonId==="button1"){
    setPseudoPosition('0%')
  }
  else if(buttonId==="button2"){
    setPseudoPosition('25%')
  }
  else if(buttonId==="button3"){
    setPseudoPosition('50%')
  }
  else{
    setPseudoPosition('75%')
  }
 
};



  return (
    <div className="font-poppins">
      <div className="bg-[#7FC3C3BF] px-10 lg:py-20 py-10 text-center font-bold lg:text-5xl text-3xl text-[#444A4C]">
        Our Services
      </div>
      <div className="mx-auto max-w-7xl py-20 md:px-10 px-5 bg-[#D9D9D9]/[29%]">
        <div className="accordion-container  max-w-[885px] mx-auto">
          <div className="relative border-b-[1px] border-[#444A4C]">
            <div className="grid md:grid-cols-4 grid-cols-2  gap-4 pb-10 px-5 relative">
              <button
                className={`accordion-button lg:text-xl text-base text-[#244B69] border-[#244B69] border-[2px] lg:py-5 lg:px-10 py-5 px-5 rounded-lg ${
                  activeItem === "button1" ? "active" : ""
                }`}
                style={{ '--pseudo-position': pseudoPosition }}
                onClick={() => handleButtonClick("button1")}
              >
               Independent
              </button>
              <button
                className={`accordion-button lg:text-xl text-base text-[#244B69] border-[#244B69] border-[2px] lg:py-5 lg:px-10 py-5 px-5 rounded-lg ${
                  activeItem === "button2" ? "active" : ""
                }`}
                style={{ '--pseudo-position': pseudoPosition }}
                onClick={() => handleButtonClick("button2")}
              >
                Enhanced
              </button>
              <button
                className={`accordion-button lg:text-xl text-base text-[#244B69] border-[#244B69] border-[2px] lg:py-5 lg:px-10 py-5 px-5 rounded-lg ${
                  activeItem === "button3" ? "active" : ""
                }`}
                style={{ '--pseudo-position': pseudoPosition }}
                onClick={() => handleButtonClick("button3")}
              >
                Professional
              </button>
              <button
                className={`accordion-button lg:text-xl text-base text-[#244B69] border-[#244B69] border-[2px] lg:py-5 lg:px-10 py-5 px-5 rounded-lg ${
                  activeItem === "button4" ? "active" : ""
                }`}
                style={{ '--pseudo-position': pseudoPosition }}
                onClick={() => handleButtonClick("button4")}
              >
                Tax Reclaims
              </button>
            </div>
          </div>
          <div>
            {activeItem === "button1" && (
              <div className="accordion-content flex justify-center items-center mt-10">
                <div className="bg-img bg-img1 bg-[#D9D9D9]/[35%] lg:p-10 p-5 md:text-[18px] text-[12px] font-normal text-[#444A4C] md:leading-[42px] leading-[30px] lg:h-[500px] flex justify-center items-center">
                Our budget-friendly option is perfect for individuals with fairly straightforward tax affairs.  It's designed for those who have a small side hustle, self-employment in a small business, or rental property income. Using our intuitive software, you'll answer questions relevant to your situation.
                </div>
              </div>
            )}
            {activeItem === "button2" && (
              <div className="accordion-content flex justify-center items-center mt-10">
                  <div className="bg-img bg-img2 bg-[#D9D9D9]/[35%] lg:p-10 p-5 md:text-[18px] text-[12px] font-normal text-[#444A4C] md:leading-[42px] leading-[30px] lg:h-[500px] flex justify-center items-center">
                  For those with more complex tax filings or multiple income streams, this option provides additional assistance from our tax experts. You'll receive everything from Option A, along with personalized help from our experts. They will review your filing before submission, provide tips and tricks, and ensure an accurate self-assessment tax return that maximizes your tax reliefs. This option is suitable for first-time filers, individuals with multiple income streams, or up to two rental properties. By choosing this option,you'll have the peace of mind that comes with expert guidance.
                  </div>
              </div>
            )}
            {activeItem === "button3" && (
              <div className="accordion-content flex justify-center items-center mt-10">
                <div className="bg-img bg-img3 bg-[#D9D9D9]/[35%] lg:p-10 p-5 md:text-[18px] text-[12px] font-normal text-[#444A4C] md:leading-[42px] leading-[30px] lg:h-[500px] flex justify-center items-center">
                The Tax Accountant route is designed for individuals with more complicated tax affairs or more than three properties. With this package, you'll receive full support from a dedicated tax accountant who will provide tailored one-on-one advice. Fill in your information, have it reviewed by a tax accountant, and then schedule a one-to-one telephone or video call to ensure full compliance. The comprehensive support package ensures you report all your income in the most tax-efficient manner, while taking advantage of all available tax-saving options.
                </div>
              </div>
            )}
             {activeItem === "button4" && (
              <div className="accordion-content flex justify-center items-center mt-10">
                <div className="bg-img bg-img4 bg-[#D9D9D9]/[35%] lg:p-10 p-5 md:text-[18px] text-[12px] font-normal text-[#444A4C] md:leading-[42px] leading-[30px] lg:h-[500px] flex justify-center items-center">
                If you believe the taxmagpie owes you money, our tax relief and reclaim service can help. Whether you're a CIS worker or have made pension contributions subject to tax, we can assist you in reclaiming any overpaid tax. There is no upfront fee for our service, and we can go back up to four years to reclaim overpaid tax. We handle all the hard work for you and help you retrieve any money you're owed. Our fee is only charged upon successfully reclaiming the money. Get in touch with us to learn more about this service.
                If you believe you have previously overpaid tax, either by not claiming your allowances or you are a construction worker, then this plan will put in a tax reclaim for you which can go back up to 4 years. This plan works on a no win no fee basis.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl pb-7 overflow-auto">
        <Surety />
        <BacktoTop />
      </div> 
    </div>
  );
};

export default Service;
