import { useState } from "react";
export default function MileageCal() {
  const [ownVehicle, setOwnvehicle] = useState("");
  const [buyVehicle, setBuyvehicle] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [miles , setMiles] = useState(0)

  const OwnVehicle = (event) => {
    setOwnvehicle(event.target.value);
  };
  const BuyVehicle = (event) => {
    setBuyvehicle(event.target.value);
  };
  const vehicletype = (event) => {
    setVehicleType(event.target.value);
  };
  const totalMiles = (event) => {
    setMiles(event.target.value);
  };
 
   let cost;
   const vehicleCar = vehicleType === "car";
   
   if (vehicleCar) {
       if (miles <= 10000) {
           cost = (miles * 0.45).toFixed();
       } else {
           const first10000Miles = 10000;
           const remainingMiles = miles - 10000;
           cost = ((first10000Miles * 0.45) + (remainingMiles * 0.25)).toFixed();
       }
   }
   
   console.log(cost);
   
  if (vehicleType === "motorbike") {
      cost = (miles * 0.24).toFixed();
  } if(vehicleType === "bike") {
      cost = (miles * 0.20).toFixed();
  }
   

  console.log(miles)
  return (
    <div className="grid grid-cols-5 text-[#444A4C] font-poppins">
      <div className="md:col-span-3 col-span-5 bg-[#7FC3C3]/[11%] md:p-10 p-5">
        <div className="text-[#274A69] text-center font-semibold text-2xl">
          Your situation
        </div>
        <div>
          <p className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
            Do you own the vehicle you use for work?
          </p>
          <div className="grid grid-cols-2 mt-5">
            <div>
              <input
                type="radio"
                name="ownVehicle"
                value="yes"
                onChange={OwnVehicle}
              />
              <label className="text-[#444A4C] ml-2 md:text-base text-xs">
                Yes
              </label>
            </div>
            <div>
              <input
                type="radio"
                name="ownVehicle"
                value="no"
                onChange={OwnVehicle}
              />
              <label className="text-[#444A4C] ml-2 md:text-base text-xs">
                No
              </label>
            </div>
          </div>
        </div>
        {ownVehicle === "yes" && (
          <>
            <div>
              <p className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
                Did you buy the vehicle specifically to use for work?
              </p>
              <div className="grid grid-cols-2 mt-5">
                <div>
                  <input
                    type="radio"
                    name="buyVehicle"
                    value="yes"
                    onChange={BuyVehicle}
                  />
                  <label className="text-[#444A4C] ml-2 md:text-base text-xs">
                    Yes
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="buyVehicle"
                    value="no"
                    onChange={BuyVehicle}
                  />
                  <label className="text-[#444A4C] ml-2 md:text-base text-xs">
                    No
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
        {(buyVehicle === "yes" || buyVehicle === "no") && (
          <div>
            <p className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
              Type of vehicle
            </p>
            <div className="grid grid-cols-2 mt-5">
              <div>
                <input
                  type="radio"
                  value="car"
                  name="vehicleType"
                  onChange={vehicletype}
                />
                <label className="text-[#444A4C] ml-2 md:text-base text-xs">
                  Car
                </label>
              </div>
              <div>
                <input
                  type="radio"
                  name="vehicleType"
                  value="bike"
                  onChange={vehicletype}
                />
                <label className="text-[#444A4C] ml-2 md:text-base text-xs">
                  Bike
                </label>
              </div>
              <div className="mt-3">
                <input
                  type="radio"
                  name="vehicleType"
                  value="motorbike"
                  onChange={vehicletype}
                />
                <label className="text-[#444A4C] ml-2 md:text-base text-xs">
                  Motorbike
                </label>
              </div>
            </div>
          </div>
        )}
        {(vehicleType === "car" ||
          vehicleType === "bike" ||
          vehicleType === "motorbike") && (
          <div className="mt-5 flex items-center justify-between">
            <label
              className="md:text-base text-xs font-normal text-[#444A4C] mt-5"
            >
              How many miles do you drive per year?
            </label>
            <input
              type="number"
              name="miles"
              className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
              value={miles}
              onChange={totalMiles}
            />
          </div>
        )}
      </div>
      <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] md:p-7 p-5 relative">
        <div className="text-[#274A69] text-center font-semibold text-2xl">
          Tax and profit
        </div>
        {ownVehicle === "" && (
          <p className="leading-loose text-[#444A4C]/[50%] text-sm mt-7 font-medium">
            Enter your vehicle ownership information
          </p>
        )}
        {/* ***********PART ONE********** */}
        {ownVehicle === "no" && (
          <div className="flex items-center justify-center h-full">
            <p className="text-center leading-loose">
              If you don’t own the vehicle you’re driving for work, you won’t be
              able to claim the mileage allowance.
            </p>
          </div>
        )}

        {/* ***********PART TWO********** */}
        {ownVehicle === "yes" && (
          <div>
            <div className="flex items-center justify-between md:mt-[10%] mt-[5%] lg:ml-10 ml-0">
              <div className="text-[#444A4C] font-bold">You can claim</div>
              <div className="text-[#444A4C] font-bold">£ {cost}</div>
            </div>
            <div className="flex items-center justify-center bg-white mt-5 rounded-lg p-5">
              <p className="text-center leading-loose text-sm">
                If you’re self-employed and use your car for work, you can claim
                back a flat rate for your usage costs using the Mileage
                Allowance. If you’re employed, claim the mileage tax relief
                instead.
              </p>
            </div>
            <div className="absolute top-[19%] lg:block hidden">
              <svg
                width="23"
                height="87"
                viewBox="0 0 23 87"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 20V86.5" stroke="white" strokeWidth="3" />
                <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
                <path
                  d="M14.694 9.832V10.91H7.414V9.832H14.694ZM14.694 12.842V13.92H7.414V12.842H14.694Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
        )}
        {/* ***********PART THREE********** */}
        {buyVehicle === "yes" ? (
          <div>

            <div className="flex items-center justify-center bg-white mt-5 rounded-lg p-5">
              <p className="text-center leading-loose text-sm">
                If you bought the vehicle you’re driving specifically for work,
                you may want to claim capital allowances instead.
              </p>
            </div>
            <div className="absolute top-[63%] left-[10%] lg:block hidden">
            <svg width="4" height="35" viewBox="0 0 4 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.82324 0.438965L1.82324 34.4609" stroke="white" strokeWidth="3"/>
            </svg>

            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
