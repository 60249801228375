import axios from 'axios'
const baseUrl = `/api/notes`

const getAll = () => {
    const request = axios.get(baseUrl)
    return request.then(response => response.data)
}

const getNote = (clientId, token) => {
  const request = axios.get(`${baseUrl}/${clientId}?token=${token}`)
  return request.then(response => response.data)
}

const create = (newObject, token) => {
  const request = axios.post(`${baseUrl}/createnote?token=${token}`, newObject)
  return request.then(response => response.data)
}

const update = (noteId, newObject, token) => {
  const request = axios.put(`${baseUrl}/updatenote/${noteId}?token=${token}`, newObject)
  return request.then(response => response.data)
}

const remove = (id, token) => {
    const request = axios.delete(`${baseUrl}/${id}?token=${token}`);
    return request.then(response => response.data);
};

// eslint-disable-next-line
export default { getAll, getNote, create, update, remove };