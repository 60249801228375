import { Link, NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <div className="bg-[#244B69] font-inter py-6 font-poppins px-5 z-10">
      <div className="mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-3 justify-between items-center text-[white] px-10">
        <NavLink to="/" className="flex justify-center items-center lg:inline-block">
          <img src="../images/WhiteBlueStripe-Logo.png" alt="" className="w-16" />
        </NavLink>
        <div className="flex gap-20 justify-center my-5 md:my-0">
          <NavLink
            to="about-us">
              About Us
          </NavLink>
          <NavLink
            to="">
              Contact Us
          </NavLink>
        </div>
        <div className="flex items-center justify-center md:justify-end gap-5 md:gap-2 mt-5 md:mt-0">
          <Link className="w-5 h-5"><img src="../images/facebook.png" alt="" /></Link>
          <Link className="w-5 h-5"><img src="../images/tik-tok.png" alt="" /></Link>
          <Link className="w-5 h-5"><img src="../images/linkedin.png" alt="" /></Link>
          <Link className="w-5 h-5"><img src="../images/twitter.png" alt="" /></Link>
          <Link className="w-5 h-5"><img src="../images/instagram.png" alt="" /></Link>
        </div>
      </div>
    </div>
  );
}
