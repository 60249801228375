import Banner from "../../assets/Banner";
import Pricing from "../../assets/Pricing";
import Experties from "../../assets/Experties";
import Surety from "../../assets/Surety";
import Ourhelp from "../../assets/Ourhelp";
import Priceplantable from "../../assets/Priceplantable";
import BacktoTop from "../../assets/basic/backtoTop";


export default function LandingPage() {
  return (
    <>
      <Banner/>
      <Experties/>
      <Pricing/>
      <Ourhelp/>
      <Priceplantable/>
      <div><Surety/></div>
      <div className="pb-[4rem]"><BacktoTop/></div>
    </>
  );
}