import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import fileService from '../../services/fileService';

function FileUpdate({token, uploadedFile, setUploadedFile, uploadProgress, setUploadProgress, showAlert}) {

  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);

      try {
        const response = await fileService.updateFile(formData, (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100
          );
          setUploadProgress(progress);
          setTimeout(() => {
            setUploadProgress(0);
          }, 2000);
        }, token);
    
        setUploadedFile({
          filename: response.filename,
          preview: URL.createObjectURL(file),
        });
    
        showAlert('success', 'File uploaded successfully')
      } catch (error) {
        console.error('Error uploading file', error);
      }
    }, [token]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*,application/pdf',
  });

  return (
    <div>
      <div {...getRootProps()} className="dropzone border border-dased border-black cursor-pointer">
        <input {...getInputProps()} />
        {uploadProgress > 0 && (
          <div className="progress-bar">
            <div
              className="progress bg-green-500 h-2"
              style={{ width: `${uploadProgress}%` }}
            ></div>
          </div>
        )}
        {/* {uploadedFile && (
          <div className="file-preview">
            <p>File uploaded:</p>
            {uploadedFile.preview && (
              <img
                src={uploadedFile.preview}
                alt="File Preview"
                className="file-preview-image"
              />
            )}
          </div>
        )} */}
        {/* {!uploadedFile && ( */}
          <p className='p-5 text-center'>
            Drag & drop an image, or click to select a file
            {/* {uploadProgress > 0 && ` - ${uploadProgress}% uploaded`} */}
          </p>
        {/* )} */}
      </div>
    </div>
  );
}

export default FileUpdate;
