import Surety from "../../assets/Surety";
import useClient from "../../hooks/useClient";
export default function FormSeven() {
  const { activeClient } = useClient();
  return (
    <div className="h-[90vh]">
      <div className="mx-auto max-w-7xl bg-[#D9D9D9]/[29%] pt-10 mt-5 font-poppins">
        <div className="md:text-3xl text-lg font-medium text-white bg-[#E0A045] py-6 md:px-10 px-[10px] text-center">
          <p> Thank you for using tax magpie, we will be in touch shortly.</p>
          <p>Ref: {activeClient?.ref}</p>
        </div>
        <div className="flex items-center justify-center py-10 px-5">
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="8.5"
              cy="8.5"
              r="7.5"
              stroke="#FF1717"
              strokeOpacity="0.63"
              strokeWidth="2"
            />
            <path
              d="M8.50647 5.608C8.14647 5.608 7.85047 5.504 7.61847 5.296C7.39447 5.08 7.28247 4.816 7.28247 4.504C7.28247 4.184 7.39447 3.92 7.61847 3.712C7.85047 3.496 8.14647 3.388 8.50647 3.388C8.85847 3.388 9.14647 3.496 9.37047 3.712C9.60247 3.92 9.71847 4.184 9.71847 4.504C9.71847 4.816 9.60247 5.08 9.37047 5.296C9.14647 5.504 8.85847 5.608 8.50647 5.608ZM9.52647 6.304V13H7.47447V6.304H9.52647Z"
              fill="#FF1717"
              fillOpacity="0.63"
            />
          </svg>

          <p className="ml-5">Please make sure to check your mail junk box.</p>
        </div>
      </div>
      <div>
        <Surety />
      </div>
    </div>
  );
}
