import axios from 'axios'
const baseUrl = `/api/messages`

const getAll = () => {
    const request = axios.get(baseUrl)
    return request.then(response => response.data)
}

const getMessage = (clientId, token) => {
  const request = axios.get(`${baseUrl}/${clientId}?token=${token}`)
  return request.then(response => response.data)
}

const create = (newObject, token) => {
  const request = axios.post(`${baseUrl}/createmessage?token=${token}`, newObject)
  return request.then(response => response.data)
}

const update = (messageId, newObject, token) => {
  const request = axios.put(`${baseUrl}/updatemessage/${messageId}?token=${token}`, newObject)
  return request.then(response => response.data)
}

const remove = (id, token) => {
    const request = axios.delete(`${baseUrl}/${id}?token=${token}`);
    return request.then(response => response.data);
};

// eslint-disable-next-line
export default { getAll, getMessage, create, update, remove };