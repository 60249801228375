import { useState } from "react";
import useClient from "../../hooks/useClient";
import useUser from "../../hooks/useUser";
import CrmTabsContext from "./CrmTabsContext";
import messageService from "../../services/messageService";
import noteService from "../../services/noteService";
import claimService from "../../services/claimService";
import clientService from "../../services/clientService";
import { useNavigate } from "react-router";
import incomeSourceService from "../../services/incomeSourceService";

const CrmTabsState = ({children}) => {

    const token = localStorage.getItem("token")
    const { activeClient } = useClient();
    const { activeUser } = useUser();

    const [ clientData, setClientData ] = useState({
        claims: [],
        incomeSources: [],
        documents: [],
        messages: [],
        notes: [],
        plan: []
    })
    
    const [isDivVisible, setIsDivVisible] = useState(false);
    const [popupType, setPopupType] = useState("");
    const toggleDivVisibility = (type) => {
        setIsDivVisible(!isDivVisible);
        setPopupType(type)
    };

    const [activeContent, setActiveContent] = useState({})
    
    const [isWarningVisible, setIsWarningVisible] = useState(false);
    const [parentDiv, setParentDiv] = useState(null);
    const toggleWarningVisibility = () => {
        setIsWarningVisible(!isWarningVisible);
    }

    const navigate = useNavigate()
    
    const deletePopup = (e, content) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        const parent = e.target.closest(`.content-wrapper`) || e.target.closest(`.sidebar-wrapper`);
        console.log(parent.classList.contains(".content-wrapper"))
        setParentDiv(parent)
        if (parent.classList.contains("content-wrapper")) {
            setActiveContent(content)
            setIsWarningVisible(true)
        } else if (parent.classList.contains("sidebar-wrapper")) {
            setIsWarningVisible(true)
        } else {
            console.error('Parent div not found.');
        }
    }

    const deleteMessage = async () => {
        console.log(activeContent)
        const deletedMessage = await messageService.remove(activeContent.id, token)
        if (deletedMessage) {
            parentDiv.remove();
            setParentDiv("")
            setIsWarningVisible(false)
        }
    }

    const addMessage = async (messageText) => {
        const message = {
            user: activeUser.id,
            client: activeClient.id,
            text: messageText,
            sender: activeUser.role,
            created_at: new Date()
        }

        console.log(message)
        const createdMessage = await messageService.create(message, token)
        if (createdMessage) {
            console.log(createdMessage)
            const newMessages = [...clientData.messages, message];
            setClientData({...clientData, messages: newMessages});
            toggleDivVisibility()
        }
    }

    const editPopup = content => {
        setActiveContent(content)
        toggleDivVisibility("edit")
    }

    const editMessage = async (messageText) => {
        const message = {
            text: messageText
        }

        const messageIndex = clientData.messages.findIndex((n) => n.id === activeContent.id);

        if (messageIndex !== -1) {
            const updatedMessages = [...clientData.messages];
            updatedMessages[messageIndex] = { ...activeContent, ...message };
        
            setClientData({...clientData, messages: updatedMessages});
        
            const updatedMessage = await messageService.update(activeContent.id, message, token);
            if (updatedMessage) {
              console.log(updatedMessage);
              toggleDivVisibility();
            }
        }
    }

    const deleteNote = async () => {
        console.log(activeContent)
        const deletedNote = await noteService.remove(activeContent.id, token)
        if (deletedNote) {
            parentDiv.remove();
            setParentDiv("")
            setIsWarningVisible(false)
        }
    }

    const addNote = async (noteText) => {
        const note = {
            user: activeUser.id,
            client: activeClient.id,
            text: noteText
        }

        console.log(note)
        const createdNote = await noteService.create(note, token)
        if (createdNote) {
            console.log(createdNote)
            const newNotes = [...clientData.notes, note];
            setClientData({...clientData, notes: newNotes});
            toggleDivVisibility()
            // setNotes(notes.push(note))
        }
    }

    const editNote = async (noteText) => {
        const note = {
            text: noteText
        }

        const noteIndex = clientData.notes.findIndex((n) => n.id === activeContent.id);

        if (noteIndex !== -1) {
            const updatedNotes = [...clientData.notes];
            updatedNotes[noteIndex] = { ...activeContent, ...note };
        
            setClientData({...clientData, notes: updatedNotes});
        
            const updatedNote = await noteService.update(activeContent.id, note, token);
            if (updatedNote) {
              console.log(updatedNote);
              toggleDivVisibility();
            }
        }
    }


    const deleteClaim = async () => {
        console.log(activeContent)
        const deletedClaim = await claimService.remove(activeContent.id)
        if (deletedClaim) {
            parentDiv.remove();
            setParentDiv("")
            setIsWarningVisible(false)
        }
    }

    const deleteClient = async () => {
        // console.log(activeContent)
        const deletedClient = await clientService.remove(activeClient.id)
        console.log(deletedClient)
        setIsWarningVisible(false)
        navigate('/crm')
    }

    const addClaim = async (claimContent, incomeContent) => {
        const claim = {
            client: activeClient.id,
            taxType: claimContent.taxType,
            status_id: claimContent.status_id
        }

        console.log(claim)
        const createdClaim = await claimService.create(claim)
        if (createdClaim) {
            console.log(createdClaim)
            const income = {
                client: activeClient.id,
                claim: createdClaim.id,
                sourceOfIncome: [incomeContent.sourceType],
                [incomeContent.sourceType]: {
                    income: incomeContent.sourceIncome,
                    expenditure: incomeContent.sourceExpenditure
                }
            }
            const createdIncomeSource = await incomeSourceService.create(income)
            if (createdIncomeSource) {
                const newClaims = [...clientData.claims, claim];
                setClientData({...clientData, claims: newClaims});
                toggleDivVisibility()
            }
        }
    }

    const editClaim = async (claimContent) => {
        console.log(claimContent)
        const claim = {
            taxType: claimContent.taxType,
            status_id: claimContent.status_id
        }

        const claimIndex = clientData.claims.findIndex((c) => c.id === activeContent.id);

        if (claimIndex !== -1) {
            const updatedClaims = [...clientData.claims];
            updatedClaims[claimIndex] = { ...activeContent, ...claim };
        
            setClientData({...clientData, claims: updatedClaims});
        
            const updatedClaim = await claimService.update(activeContent.id, claim);
            if (updatedClaim) {
              console.log(updatedClaim);
              toggleDivVisibility();
            }
        }
    }

    const scrollSection = element => {
        element.scrollTop = element.scrollHeight
    }

    return (
        <CrmTabsContext.Provider value={{isDivVisible, setIsDivVisible, popupType, setPopupType, toggleDivVisibility, activeContent, setActiveContent, isWarningVisible, setIsWarningVisible, parentDiv, setParentDiv, toggleWarningVisibility, deletePopup, editPopup, deleteMessage, addMessage, editMessage, deleteNote, addNote, editNote, deleteClaim, addClaim, editClaim, deleteClient, scrollSection, clientData, setClientData}}>
            {children}
        </CrmTabsContext.Provider>
    )
}

export default CrmTabsState