import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Priceplan({ upPlan, plan}) {

  const planRefs = useRef([])

  function handleClick(e) {
    if (upPlan) {
      e.preventDefault()
      const value = e.currentTarget.getAttribute('data-value');
      const currentPlanPrice = parseFloat(plan.price.split(" ")[0].substr(1));
      const newPlanPrice = parseFloat(value.split(" ")[0].substr(1));

      if (currentPlanPrice < newPlanPrice) {
        console.log("go to stripe");
      }
    }
     window.scrollTo({ top: 0, behavior: "smooth" });

  }

  useEffect(() => {
  
    if (upPlan) {
      // console.log(plan.price)
      planRefs.current.forEach( p => {
        if (plan.price === p.getAttribute('data-value')){
          console.log(plan)
          p.innerHTML = "Current Plan"
          p.classList.add("bg-[#00B67A]");
          p.classList.remove("border", "bg-transparent");
        }
        console.log(p.getAttribute('data-value'))
      })
    }
  }, [plan]);  

  return (
    <div className={`${upPlan ? "bg-[#244B69]" : "bg-[#7FC2C2]/[29%]"} px-10 py-[5rem]`}>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 text-center">
        <div className="w-28 lg:w-40 mx-auto">
          {upPlan 
            ? <img src="../images/WhiteBlueStripe-Logo.png" alt="" className="w-full" />
            : <img src="../images/FullColourIcon.png" alt="" className="w-full" />
          }
        </div>
        <div className={`text-[#00B67A] font-bold md:text-[2.5rem] text-[1.5rem] border-b-[4px] ${upPlan ? "border-[#fff]" : "border-[#444A4C]"} py-10`}>
          Price Plans
        </div>
        <div>
          <div className={`pt-5 pb-10 border-b-[1px] ${upPlan ? "border-[#fff]" : "border-[#444A4C]"} ${plan?.price === "£99 +VAT" ? "hidden" : "block"}`}>
            <p className="text-[#00B67A] text-2xl">Independent</p>
            <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
              £64.99 +VAT
            </div>
            <p className={`${upPlan ? "text-white" : "text-[#244B69]"} md:text-2xl text-xl pb-5`}>
              (Going solo)
            </p>
            <Link
              data-value="£64.99 +VAT"
              className={`text-white font-medium md:text-2xl text-xl ${upPlan ? "bg-transparent hover:bg-[#00B67A] border-slate-100 border" : "bg-[#00B67A]"} py-2 px-10`}
              to="/form"
              ref={(ref) => (planRefs.current[0] = ref)}
              onClick={handleClick}>
                Get started
            </Link>
          </div>
          <div className={`pt-5 pb-10 border-b-[1px] ${upPlan ? "border-[#fff]" : "border-[#444A4C]"}`}>
            <p className="text-[#00B67A] text-2xl">Enhanced</p>
            <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
              £99 +VAT
            </div>
            <p className={`${upPlan ? "text-white" : "text-[#244B69]"} md:text-2xl text-xl pb-5`}>
              (Assisted)
            </p>
            <Link
              data-value="£99 +VAT"
              className={`text-white font-medium md:text-2xl text-xl ${upPlan ? "bg-transparent hover:bg-[#00B67A] border-slate-100 border" : "bg-[#00B67A]"} py-2 px-10`}
              to="/form"
              ref={(ref) => (planRefs.current[1] = ref)}
              onClick={handleClick}>
                Get started
            </Link>
          </div>
          <div className="pt-5 pb-10 ">
            <p className="text-[#00B67A] text-2xl">Professional</p>
            <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
              £220 +VAT
            </div>
            <p className={`${upPlan ? "text-white" : "text-[#244B69]"} md:text-2xl text-xl pb-5`}>
              (Accountant Review)
            </p>
            <Link
              data-value="£220 +VAT"
              // className={`text-white font-medium md:text-2xl text-xl ${upPlan ? "bg-transparent hover:bg-[#00B67A] border-slate-100 border" : "bg-[#00B67A]"} py-2 px-10`}
              className={`text-white font-medium md:text-2xl text-xl ${upPlan ? "bg-transparent border-slate-100 border" : "bg-gray-500"} py-2 px-10`}
              // to="/form"
              ref={(ref) => (planRefs.current[2] = ref)}
              // onClick={handleClick}
              >
                Coming Soon
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
