import { useEffect, useState } from "react";
import AddDocuments from "../crmPopups/AddDocuments";
import SingleDocument from "./SingleDocument";
import fileService from "../../../../services/fileService";
import useClient from "../../../../hooks/useClient";
import useUser from "../../../../hooks/useUser";
import useCrmTabs from "../../../../hooks/useCrmTabs";

const DocumentsTab = () => {

    const { clientData, setClientData } = useCrmTabs()
    const { activeClient } = useClient()
    const { userMode } = useUser()
    const token = localStorage.getItem("token")

    const [isDivVisible, setIsDivVisible] = useState(false);
    const toggleDivVisibility = () => {
        setIsDivVisible(!isDivVisible);
    };

    const deleteFile = async (document) => {
        console.log(document);
        try {
            const deletedFile = await fileService.remove(document._id, token);
            console.log(deletedFile);
            await fetchClientDocuments(activeClient)
        } catch (error) {
            console.log(error);
        }
    };

    const fetchClientDocuments = async () => {
        try {
            const returnedDocuments = await fileService.fetchFile(activeClient.id, token);
            returnedDocuments && (await setClientData({...clientData, documents: returnedDocuments}));
            console.log(returnedDocuments);
        } catch (error) {
            console.log("no documents for this client")
        }
    };

    useEffect(() => {
        JSON.stringify(activeClient) !== '{}' && fetchClientDocuments()
    }, [activeClient])

    return (
        <>
            <div className={`lg:mt-7 border ${userMode === "client" ? "border-[#244B69]/[30%] min-h-screen" : "border-[#244B69]"} rounded-md`}>
                <div className={`flex items-center ${userMode === "client" ? "p-8" : "p-5"} border-[#244B69]/[30%] border-b`}>
                    <button
                        onClick={toggleDivVisibility}
                        className="border border-emerald-500 p-2 lg:p-4 text-emerald-500 flex items-center gap-5 rounded-md"
                    >
                        <span className="text-sm lg:text-lg font-normal">Add Document</span>
                        <span className="text-sm lg:text-xl">+</span>
                    </button>
                </div>
                <div className={`${userMode === "client" ? "p-8" : "p-5"}`}>
                    <p className="text-lg font-normal">Current client documents:</p>
                    <div className="min-h-[5rem] border-[#244B69]/[30%] border mt-5 p-5">
                        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-5">
                            {clientData.documents.length > 0
                                ? clientData.documents.map(doc => (
                                    <SingleDocument key={doc.id} doc={doc} deleteFile={deleteFile} />
                                ))
                                : <div className="text-[#244B69] text-xl flex col-span-3 py-10 items-center justify-center">
                                    <p>No Documents Available</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {isDivVisible && <AddDocuments isToggle={toggleDivVisibility} fetchClientDocuments={fetchClientDocuments} />}
        </>
    );
};

export default DocumentsTab;
