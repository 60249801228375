import axios from 'axios'
const baseUrl = `/api/claims`

const getAll = () => {
    const request = axios.get(baseUrl)
    return request.then(response => response.data)
}

const getClaim = clientId => {
  const request = axios.get(`${baseUrl}/${clientId}`)
  return request.then(response => response.data)
}

const create = newObject => {
  const request = axios.post(`${baseUrl}/createclaim`, newObject)
  return request.then(response => response.data)
}

const update = (claimId, newObject) => {
  const request = axios.put(`${baseUrl}/updateclaim/${claimId}`, newObject)
  return request.then(response => response.data)
}

const remove = id => {
    const request = axios.delete(`${baseUrl}/${id}`);
    return request.then(response => response.data);
};

// eslint-disable-next-line
export default { getAll, getClaim, create, update, remove };