
import React from "react";

const SectionThree = ({ userData, handleChange }) => {
  return (
    <>
        <div className="font-normal xl:text-2xl text-xl  text-[#244B69] pt-7 pb-2 lg:pb-5 xl:pb-7" id="incomeHeading">
          3. Select what were your sources of income
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-1 gap-1 md:gap-4 text-center mt-[2%]">
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="self_employment"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("self_employment")}
            />
            <label
              htmlFor="self_employment"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Self employment
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="employment"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("employment")}
            />
            <label
              htmlFor="employment"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Employment
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="property"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("property")}
            />
            <label
              htmlFor="property"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Property
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="saving_investment"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("saving_investment")}
            />
            <label
              htmlFor="saving_investment"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Saving & Investment
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="pension"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("pension")}
            />
            <label
              htmlFor="pension"
              className=" color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Pension
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="furnished_holiday"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("furnished_holiday")}
            />
            <label
              htmlFor="furnished_holiday"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Furnished holiday lettings
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="trusts"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("trusts")}
            />
            <label
              htmlFor="trusts"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Trusts
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="rent_room"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("rent_room")}
            />
            <label
              htmlFor="rent_room"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Rent a room
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="minister_of_religion"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("minister_of_religion")}
            />
            <label
              htmlFor="minister_of_religion"
              className="color1  flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Minister of religion
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="non_resident"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("non_resident")}
            />
            <label
              htmlFor="non_resident"
              className="color1  flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Non resident
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="partnership"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("partnership")}
            />
            <label
              htmlFor="partnership"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Partnership
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="foreign"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("foreign")}
            />
            <label
              htmlFor="foreign"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Foreign
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="capital_gains"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("capital_gains")}
            />
            <label
              htmlFor="capital_gains"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Capital gains
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              className="hidden"
              id="donations"
              onChange={handleChange}
              name="sourceincome"
              checked={userData.sourceOfIncome.includes("donations")}
            />
            <label
              htmlFor="donations"
              className="color1 flex items-center justify-center text-[#244B69] border border-black xl:text-xl text-base font-medium my-[3%] px-2 h-16 lg:h-24 xl:h-28 cursor-pointer"
            >
              Donations/Gift aid
            </label>
          </div>
        </div>
    </>
  );
}

export default SectionThree;
