import React, { useState } from "react";

export default function NationalInsuranceCal() {
  const [employedType, setEmployedType] = useState("Self-Employed");
  const [selfEmployedIncome, setSelfEmployedIncome] = useState(49000);
  const [selfEmployedExpenses, setSelfEmployedExpenses] = useState(0);
  const [annualSalary, setAnnualSalray] = useState(49000);

  const handleEmployedTypeChange = (event) => {
    setEmployedType(event.target.value);
    setSelfEmployedIncome(event.target.value);
  };

  const handleSelfEmployedIncomeChange = (event) => {
    setSelfEmployedIncome(event.target.value);
  };

  const handleSelfEmployedExpensesChange = (event) => {
    setSelfEmployedExpenses(event.target.value);
  };
  const handleAnnualSalrayChange = (event) => {
    setAnnualSalray(event.target.value);
  };
  const handleFocus = (e) => {
    if (e.target.value=== '0') {
        setSelfEmployedExpenses('');
    }
  };

  let total = 0;
  const income = Number(selfEmployedIncome) || 0;
  const expenses = Number(selfEmployedExpenses) || 0;
  const salary = Number(annualSalary) || 0;
  let selfemployNi = 0, class2Ni = 0, totalTax;
  let selfemployRefilef;

  // *****SELF-EMPLOYED TAX*****

  if (employedType === "Self-Employed") {
    if (expenses <= 1000) {
      total = income;
      selfemployRefilef = total - 13570;
    } else {
      total = income - expenses;
      selfemployRefilef = total - 12570;
    }
  }

if (employedType === "Self-Employed") {
  totalTax = total <= 50270 
    ? (selfemployNi = selfemployRefilef * 0.09, class2Ni = selfemployNi + 179, Math.max(0, selfemployNi + 179)) 
    : (selfemployRefilef * 0.09 + (total - 50270) * 0.02 + 179);
}
//   *********EMPLOYED TAX********
if (employedType === "Employed") {
    total = salary;
  }

if (employedType === "Employed") {
  const employRefilef = total - 12570;

  totalTax = total <= 50270 
    ? (Math.max(0, employRefilef * 0.12)) 
    : ((50270 - 12570) * 0.12 + (total - 50270) * 0.02);
}



  return (
    <div className="grid grid-cols-5 text-[#444A4C]">
      <div className="md:col-span-3 col-span-5 bg-[#7FC3C3]/[11%] md:p-10 p-5">
        <div className="text-[#274A69] text-center font-semibold md:text-2xl text-xl">
          Your situation
        </div>
        <div>
          <p className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
            How did you make money?
          </p>
          <div className="grid grid-cols-2 mt-5 non-bg">
            <div>
              <input
                type="radio"
                id="Self-Employed"
                name="employedType"
                value="Self-Employed"
                onChange={handleEmployedTypeChange}
                defaultChecked
              />
              <label
                htmlFor="Self-Employed"
                className="text-[#444A4C] ml-2 md:text-base text-xs"
              >
                Self-Employed
              </label>
            </div>
            <div>
              <input
                type="radio"
                id="Employed"
                name="employedType"
                value="Employed"
                onChange={handleEmployedTypeChange}
              />
              <label
                htmlFor="Employed"
                className="text-[#444A4C] ml-2 md:text-base text-xs"
              >
                Employed
              </label>
            </div>
          </div>
          {employedType === "Self-Employed" && (
            <>
              <div className="mt-5 flex items-center justify-between">
                <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
                  Self-employed income
                </label>
                <div className="flex items-center">
                  <svg
                    className="mr-2"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                  </svg>
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">£</span>
                    <input
                        type="number"
                        name="selfEmployedIncome"
                        className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
                        value={selfEmployedIncome}
                        onChange={handleSelfEmployedIncomeChange}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-5 flex items-center justify-between">
                <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
                  Self-employed expenses
                </label>
                <div className="flex items-center">
                  <svg
                    className="mr-2"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                  </svg>
                  <div className="relative">
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">£</span>
                    <input
                        type="number"
                        name="selfEmployedExpenses"
                        className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
                        value={selfEmployedExpenses}
                        onFocus={handleFocus}
                        onChange={handleSelfEmployedExpensesChange}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          {employedType === "Employed" && (
            <div className="mt-5 flex items-center justify-between">
              <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">
                Annual salary
              </label>
              <div className="flex items-center">
                <svg
                  className="mr-2"
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                ></svg>
                <input
                  type="number"
                  name="annualSalary"
                  className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]"
                  value={annualSalary}
                  onChange={handleAnnualSalrayChange}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] md:py-10 md:pr-10 md:md:pl-[4rem] p-5 relative">
        <div className="text-[#274A69] text-center font-semibold text-2xl">
          Tax and profit
        </div>
        {employedType === "Self-Employed" && (
          <>
            <div className="flex items-center justify-between mt-[10%]">
              <div className="text-[#444A4C]">Total earnings</div>
              <div className="text-[#444A4C]">£ {income - expenses}</div>
            </div>
            <div className="md:mt-[12%]">
              {expenses > 1000 ? (
                <p className="font-light text-[10px]">
                  £ {expenses} self-employment expenses
                </p>
              ) : (
                <p className="font-light text-[10px]">
                  £1,000 tax-free Trading Allowance
                </p>
              )}
            </div>
            <div className="flex items-center justify-between md:mt-[16%] mt-[5%]">
              <div className="text-[#444A4C]">NI contributions</div>
              {class2Ni < 0 ? (
                <div className="text-[#444A4C]">£ 0</div>
              ) : (
                <div className="text-[#444A4C]">£ {class2Ni.toFixed()}</div>
              )}
            </div>
            <div className="mt-[10%]">
              <div className="md:mt-[5%]">
                <p className="font-light text-[10px]">Class 2 NI: £ 179</p>
              </div>
              <div className="md:mt-[5%]">
                {selfemployNi < 0 ? (
                  <p className="font-light text-[10px]">Class 4 NI: £ 0</p>
                ) : (
                  <p className="font-light text-[10px]">
                    Class 4 NI: £ {selfemployNi.toFixed()}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center justify-between md:mt-[12%] mt-[5%]">
              <div className="text-[#444A4C] font-bold">
                What you're left with
              </div>
              <div className="text-[#444A4C] font-bold">
                £ {(income - expenses - totalTax).toFixed()}
              </div>
            </div>
            <div className="absolute top-[25%] left-[7%] lg:block hidden">
              <svg
                width="23"
                height="264"
                viewBox="0 0 23 264"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
                <circle cx="11.5" cy="126.5" r="11.5" fill="white" />
                <circle cx="11.5" cy="61.5" r="4.5" fill="white" />
                <circle cx="11.5" cy="171.5" r="4.5" fill="white" />
                <circle cx="11.5" cy="204.5" r="4.5" fill="white" />
                <path d="M12 10.5V250" stroke="white" />
                <path
                  d="M11.5 7.5V15.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 11.5H15.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 126.5H15"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="11.5" cy="252.5" r="11.5" fill="white" />
                <path
                  d="M15.194 250.832V251.91H7.914V250.832H15.194ZM15.194 253.842V254.92H7.914V253.842H15.194Z"
                  fill="black"
                />
              </svg>
            </div>
          </>
        )}
        {employedType === "Employed" && (
          <>
            <div className="flex items-center justify-between mt-[10%]">
              <div className="text-[#444A4C]">Total earnings</div>
              <div className="text-[#444A4C]">£ {total}</div>
            </div>
            <div className="flex items-center justify-between md:mt-[16%] mt-[5%]">
              <div className="text-[#444A4C]">NI contributions</div>
              <div>
                {totalTax < 0 ? (
                  <div className="text-[#444A4C]">£ 0</div>
                ) : (
                  <div className="text-[#444A4C]">£ {totalTax.toFixed()}</div>
                )}
              </div>
            </div>
            <div className="md:mt-[12%]">
              <div>
                {totalTax < 0 ? (
                  <p className="font-light text-[10px]">
                  Class 1 NI: £ 0
                  </p>
                ) : (
                    <p className="font-light text-[10px]">
                    Class 1 NI: £ {totalTax.toFixed()}
                    </p>
                )}
              </div>
              
            </div>
            <div className="flex items-center justify-between md:mt-[12%] mt-[5%]">
              <div className="text-[#444A4C] font-bold">
                What you're left with
              </div>
              <div className="text-[#444A4C] font-bold">
                £ {(total - totalTax).toFixed()}
              </div>
            </div>
            <div className="absolute top-[30%] left-[7%] lg:block hidden">
              <svg
                width="23"
                height="190"
                viewBox="0 0 23 190"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="11.5" cy="11.5" r="11.5" fill="white" />
                <circle cx="11.5" cy="76.5" r="11.5" fill="white" />
                <circle cx="11.5" cy="127.5" r="4.5" fill="white" />
                <path d="M12 11V179" stroke="white" />
                <path
                  d="M11.5 7.5V15.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.5 11.4999H15.5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 76.5H15"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <circle cx="11.5" cy="178.5" r="11.5" fill="white" />
                <path
                  d="M14.694 176.832V177.91H7.414V176.832H14.694ZM14.694 179.842V180.92H7.414V179.842H14.694Z"
                  fill="black"
                />
              </svg>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
