import PdfToolbar from "../../assets/common/PdfToolbar"
import TermsConditionsPdf from "../../assets/legal-docs/Terms__and_conditions_TM_January2024.pdf"

export default function TermsConditions() {
  return (
    <div className="xl:w-[90%] mx-auto">
        <PdfToolbar file={TermsConditionsPdf} />
    </div>
  )
}
