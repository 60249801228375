import { useState } from "react"
export default function PensionTaxReliefCal(){
  const [pensionType , setpensionType] = useState('reliefsource');
  const [annualIncome , setannualIncome] = useState(49000)
  const [ pensionIncome, setpensionIncome] = useState(1000)
  const handlePensionTax = (event) => {
    setpensionType(event.target.value);
   }
   const handleAnnualPensionTax = (event) => {
    setannualIncome(event.target.value);
   }
   const handlePensioncontribution = (event) => {
    setpensionIncome(event.target.value);
   }
   let extraRelief;
   const taxRelief = (pensionIncome*0.25).toFixed()
   if(annualIncome > 50270){
    extraRelief = (pensionIncome*0.25).toFixed()
   }
   else{
     extraRelief = 0
   }
console.log(pensionType,annualIncome, pensionIncome )
    return(
        <div className="grid grid-cols-5 text-[#444A4C]">
        <div className="md:col-span-3 col-span-5 bg-[#7FC3C3]/[11%] md:p-10 p-5">
            <div className="text-[#274A69] text-center font-semibold md:text-2xl text-xl">Your situation</div>
             <div>
                <p className="md:text-base text-xs font-normal text-[#444A4C] mt-5">What pension scheme is your employer using?</p>
                <div className="grid grid-cols-2 mt-10">
                    <div>
                        <input type="radio" name="pensionType" value="netPay" onChange={handlePensionTax}/>
                        <label className="text-[#444A4C] ml-2 md:text-base text-xs">Net pay</label>
                    </div>
                    <div>
                        <input type="radio" name="pensionType" value="reliefsource" onChange={handlePensionTax}/>
                        <label className="text-[#444A4C] ml-2 md:text-base text-xs">Relief at source</label>
                    </div>
                </div>
                <div className="mt-10 flex items-center justify-between">
                    <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Your annual income</label>
                    <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2">£</span>
                        <input type="number" name="annualIncome" value={annualIncome} onChange={handleAnnualPensionTax} className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]" placeholder="20000" />
                    </div>
                </div>
                <div className="mt-5 flex items-center justify-between">
                    <label className="md:text-base text-xs font-normal text-[#444A4C] mt-5">Pension contributions</label>
                    <div className="flex items-center"> 
                        <svg className="mr-2" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.5 15.4921C12.6421 15.4921 16 12.1848 16 8.10516C16 4.02549 12.6421 0.718262 8.5 0.718262C4.35786 0.718262 1 4.02549 1 8.10516C1 12.1848 4.35786 15.4921 8.5 15.4921Z" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6.31763 5.8891C6.49395 5.39541 6.84199 4.97912 7.30009 4.71395C7.75819 4.44877 8.2968 4.35184 8.82051 4.44032C9.34422 4.52879 9.81924 4.79697 10.1614 5.19734C10.5036 5.59771 10.6909 6.10445 10.6901 6.62779C10.6901 8.10517 8.44013 8.84386 8.44013 8.84386" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M8.5 11.7986H8.507" stroke="black" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <div className="relative">
                            <span className="absolute inset-y-0 left-0 flex items-center pl-2">£</span>
                            <input type="number" name="pensionIncome" value={pensionIncome}  className="outline-none w-[119px] ml-auto px-5 md:py-2 py-1 border border-[#244B69]" placeholder="20000" onChange={handlePensioncontribution} />
                        </div>
                    </div>
                </div>
             </div>
        </div>
        {
            pensionType === "reliefsource" &&
            <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] md:py-10 md:pr-10 md:md:pl-[3.5rem] p-5 relative">
                <div className="text-[#274A69] text-center font-semibold text-2xl">Tax and profit</div>
                <div className="flex items-center justify-between mt-[7%]">
                    <div className="text-[#444A4C]">Pension contributions</div>
                    <div className="text-[#444A4C]">£ {pensionIncome}</div>
                </div>
                <div className="flex items-center justify-between md:mt-[20%] mt-[5%]">
                    <div className="text-[#444A4C]">Automatic tax relief</div>
                    <div className="text-[#444A4C]">£ {taxRelief}</div>
                </div>
                <div className="flex items-center justify-between md:mt-[20%] mt-[5%]">
                    <div className="text-[#444A4C] font-semibold">Extra tax relief you can claim</div>
                    <div className="text-[#444A4C] font-semibold">£ {extraRelief}</div>
                </div>
                <div className="absolute top-[25%] left-[7%] lg:block hidden">
                <svg width="23" height="179" viewBox="0 0 23 179" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 18L11 169" stroke="white"/>
                    <circle cx="11.5" cy="167.5" r="11.5" fill="white"/>
                    <path d="M14.694 165.832V166.91H7.414V165.832H14.694ZM14.694 168.842V169.92H7.414V168.842H14.694Z" fill="black"/>
                    <circle cx="11.5" cy="11.5" r="11.5" fill="white"/>
                    <path d="M11.5 7.5V15.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.5 11.5H15.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <circle cx="11.5" cy="89.5" r="11.5" fill="white"/>
                    <path d="M11.5 85.5V93.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7.5 89.5H15.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </div> 
            </div>
        }
        {/* *********SECOND CONDITION OF RADIO*********** */}
        {
            pensionType === "netPay" &&
            <div className="md:col-span-2 col-span-5 bg-[#7FC3C3]/[47%] p-10">
                <div className="text-[#274A69] text-center font-semibold text-2xl">Tax and profit</div>
                <p className="mt-[5%] leading-loose">If your employer is using a net pay or salary sacrifice scheme, then you don’t need to worry about needing to do a tax return in order to claim your relief.</p>
                <p className="mt-[5%] leading-loose">Your contributions are already made before any tax is calculated, so your overall taxable income is reduced as a result.</p>
                <p className="mt-[5%] leading-loose">You don’t need to do anything.</p>
        </div>
        }
        </div>
    )
}