const calculateTimeDifference = (clientDate) => {
    const endDate = new Date();
    const startDate = new Date(clientDate);

    const startMillis = startDate.getTime();
    const endMillis = endDate.getTime();

    const differenceMillis = Math.abs(endMillis - startMillis);

    const differenceDays = Math.ceil(differenceMillis / (1000 * 60 * 60 * 24));

    if (differenceDays >= 365) {
        const years = Math.floor(differenceDays / 365);
        return years + (years > 1 ? ' years' : ' year');
    }
    else if (differenceDays >= 30) {
        const months = Math.floor(differenceDays / 30);
        return months + (months > 1 ? ' months' : ' month');
    }
    else {
        return Math.ceil(differenceDays) + (differenceDays > 1 ? ' days' : ' day');
    }
}

export default calculateTimeDifference;

