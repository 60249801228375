import { useState } from "react";
import useClient from "../../../../hooks/useClient";
import CopyToClipboard from "../../../../utils/CopyToClipboard";
import FormatDate from "../../../../utils/FormatDate";

const ClientTab = () => {
    const { activeClient } = useClient();

    const [isDivVisible, setIsDivVisible] = useState(true);
    const toggleDivVisibility = () => {
        setIsDivVisible(!isDivVisible);
    };

    return (
        <>
            <div className="border border-[#244B69] rounded-md lg:mt-7">
                <div className="hidden md:flex bg-[#D9D9D9]/[48%] items-center p-5 md:p-7">
                    <button onClick={toggleDivVisibility} className="py-2 md:py-4 px-7 text-[#244B69] border border-[#244B69] mr-3 rounded-md">
                        Client Details
                    </button>
                    {/* <button className="py-4 px-7 text-[#244B69] border border-[#244B69] mr-3 rounded-md">
                            No Client Status
                        </button>
                        <button className="py-4 px-7 text-[#B60C0C] border border-[#B60C0C] mr-3 rounded-md">
                            Complaint Clients?
                        </button> */}
                </div>
                {isDivVisible &&
                    <>
                        <div className="border-b pb-20 pt-5 px-5 md:px-10 max-md:text-sm">
                            <div className="grid grid-cols-2 md:grid-cols-3 md:mt-5">
                                <div className="flex items-end max-md:col-span-2">
                                    <div>
                                        <div className="">Client Ref:</div>
                                        <div className="mt-2 font-light">{activeClient?.ref}</div>
                                    </div>
                                    <button
                                        className="ml-5"
                                        onClick={() => CopyToClipboard(activeClient?.ref)}
                                    >
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.25 4.55042H5.75C5.19772 4.55042 4.75 4.99813 4.75 5.55042V10.0504C4.75 10.6027 5.19772 11.0504 5.75 11.0504H10.25C10.8023 11.0504 11.25 10.6027 11.25 10.0504V5.55042C11.25 4.99813 10.8023 4.55042 10.25 4.55042Z"
                                                stroke="#244B69"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M2.75 7.55042H2.25C1.98478 7.55042 1.73043 7.44506 1.54289 7.25752C1.35536 7.06999 1.25 6.81563 1.25 6.55042V2.05042C1.25 1.7852 1.35536 1.53084 1.54289 1.34331C1.73043 1.15577 1.98478 1.05042 2.25 1.05042H6.75C7.01522 1.05042 7.26957 1.15577 7.45711 1.34331C7.64464 1.53084 7.75 1.7852 7.75 2.05042V2.55042"
                                                stroke="#244B69"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="mt-5 md:mt-2">
                                    <div className="">First Name:</div>
                                    <div className="mt-2 font-light capitalize">
                                        {activeClient?.first_name}
                                    </div>
                                </div>
                                <div className="mt-5 md:mt-2">
                                    <div className="">Last Name:</div>
                                    <div className="mt-2 font-light capitalize">
                                        {activeClient?.last_name}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 md:grid-cols-3 mt-5">
                                <div className="mt-2">
                                    <div className="">Email:</div>
                                    <div className="mt-2 font-light">
                                        {activeClient?.email_address}
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <div className="">Mobile Number:</div>
                                    <div className="mt-2 font-light">
                                        {activeClient?.phone_number}
                                    </div>
                                </div>
                                <div className="mt-5 md:mt-2 max-md:col-span-2">
                                    <div className="">Address:</div>
                                    <div className="mt-2 font-light">{activeClient?.address}</div>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 mt-5">
                                <div className="mt-2">
                                    <div className="">Postcode:</div>
                                    <div className="mt-2 font-light">{activeClient?.postcode}</div>
                                </div>
                            </div>
                        </div>
                        <div className="p-5 md:p-10 max-md:text-sm">
                            <div className="grid grid-cols-2 md:grid-cols-3 md:mt-5">
                                <div className="flex items-end">
                                    <div>
                                        <div className="">NI Number:</div>
                                        <div className="mt-2 font-light">{activeClient?.ni_number}</div>
                                    </div>
                                    <button
                                        className="ml-5"
                                        onClick={() => CopyToClipboard(activeClient?.ni_number)}
                                    >
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 12 12"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.25 4.55042H5.75C5.19772 4.55042 4.75 4.99813 4.75 5.55042V10.0504C4.75 10.6027 5.19772 11.0504 5.75 11.0504H10.25C10.8023 11.0504 11.25 10.6027 11.25 10.0504V5.55042C11.25 4.99813 10.8023 4.55042 10.25 4.55042Z"
                                                stroke="#244B69"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M2.75 7.55042H2.25C1.98478 7.55042 1.73043 7.44506 1.54289 7.25752C1.35536 7.06999 1.25 6.81563 1.25 6.55042V2.05042C1.25 1.7852 1.35536 1.53084 1.54289 1.34331C1.73043 1.15577 1.98478 1.05042 2.25 1.05042H6.75C7.01522 1.05042 7.26957 1.15577 7.45711 1.34331C7.64464 1.53084 7.75 1.7852 7.75 2.05042V2.55042"
                                                stroke="#244B69"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <div className="">Date of Birth:</div>
                                    <div className="mt-2 font-light">
                                        {FormatDate(activeClient?.dob, "/")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
};

export default ClientTab;