export default function Ourhelp() {
  return (
    <div className="mt-[4%]">
        <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
        <div className="font-bold md:text-4xl text-2xl text-center text-[#244B69] pb-3">Who we can help</div>
        <p className="font-normal md:text-2xl text-sm text-center">We can help everybody that needs to file a tax return.</p>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 text-center">
        <div className="flex flex-col items-center justify-center  lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/file.png" alt="" />
          </div>
          <p>First-time filers</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/refund.png" alt="" />
          </div>
          <p>Tax relief/ Refund claim</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/self-employed.png" alt="" />
          </div>
          <p>Self-employed</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/Retired.png" alt="" />
          </div>
          <p>Retired</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/freelance.png" alt="" />
          </div>
          <p>Freelancers & contractors</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/Investors.png" alt="" />
          </div>
          <p>Investors (property, forex, crypto, etc.)</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/Construction.png" alt="" />
          </div>
          <p>Construction workers (CIS)</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/High.png" alt="" />
          </div>
          <p>High earners (£100k+)</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/land.png" alt="" />
          </div>
          <p>Landlords (inc. Airbnb, hosts)</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/Expats.png" alt="" />
          </div>
          <p>Expats</p>
        </div>
        <div className="flex flex-col items-center justify-center lg:p-20 px-0 py-10">
          <div className="bg-[#7FC2C2]/[.15] w-[100px] h-[100px] rounded-full font-normal text-xl">
            <img src="../images/cdr.png" alt="" />
          </div>
          <p>Couriers, riders or drivers</p>
        </div>
        
      </div>
    </div>
    </div>
  );
}
