import { useEffect, useRef } from "react";
import Warning from "../crmPopups/Warning";
import SingleMessage from "./SingleMessage";
import AddUpdateMessages from "../crmPopups/AddUpdateMessages";
import useCrmTabs from "../../../../hooks/useCrmTabs";
import useUser from "../../../../hooks/useUser";
import Backdrop from "../../../../assets/common/Backdrop";
import messageService from "../../../../services/messageService";
import useClient from "../../../../hooks/useClient";

const MessagesTab = ({ activeTab }) => {
    const { isDivVisible, toggleDivVisibility, isWarningVisible, toggleWarningVisibility, deleteMessage, scrollSection, clientData, setClientData } = useCrmTabs();
    const { userMode } = useUser();
    const { activeClient } = useClient();
    const token = localStorage.getItem("token")

    const messageRef = useRef()
    const lastMessage = clientData.messages[clientData.messages.length - 1]

    const fetchClientMessages = async () => {
        try {
            const returnedMessages = await messageService.getMessage(activeClient.id, token);
            returnedMessages && (await setClientData({...clientData, messages: returnedMessages}));
            console.log(returnedMessages);
        } catch (error) {
            console.log("no messages for this client")
        }
    };

    useEffect(() => {
        JSON.stringify(activeClient) !== '{}' && fetchClientMessages()
    }, [activeClient])

    useEffect(() => {
        scrollSection(messageRef.current)
    }, [activeTab === "5"])

    return (
        <>
            <div ref={messageRef} className={`lg:mt-7 border ${userMode === "client" ? "border-[#244B69]/[30%] min-h-screen" : "border-[#244B69] lg:max-h-[45rem]"} rounded-md overflow-scroll`}>
                <div className={`p-5 lg:p-10 ${clientData.messages.length === 0 && "min-h-96 lg:min-h-[45rem]"}`}>
                    {clientData.messages.length > 0
                        && <div className="flex flex-col gap-5">
                            {clientData.messages.map(message => (
                                <SingleMessage key={message.id} message={message} lastMessage={lastMessage} />
                            ))}
                        </div>
                    }
                    <div className="mt-10">
                        <button onClick={() => toggleDivVisibility("new")} className="border bg-emerald-500 p-4 text-white w-4/5 text-center gap-5 rounded-md text-lg font-normal">
                            New Message
                        </button>
                    </div>
                </div>
            </div>
            {isDivVisible && <AddUpdateMessages />}
            {isWarningVisible &&
                <div className="flex w-screen h-screen place-content-center inset-0 absolute">
                    <Backdrop />
                    <Warning toggle={toggleWarningVisibility} deleteContent={deleteMessage} />
                </div>}

        </>
    )
}

export default MessagesTab;
