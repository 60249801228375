import Surety from "../../assets/Surety";
import RightArrow from "../../assets/basic/RightArrow";
import LeftArrow from "../../assets/basic/leftArrow";
import { useState, useEffect } from "react";
import useClient from "../../hooks/useClient";
import useUser from "../../hooks/useUser";

export default function FormFour({ onNext, onPrevious }) {
  const { formData } = useClient()
  const { setLoading } = useUser()
  const { sourceOfIncome } = formData?.userData;
  //console.log("here",sourceOfIncome)
  const getInitialPricePlan = (sourceOfIncomeLength) => {
    if (sourceOfIncomeLength <= 2) {
      return "£64.99 +VAT";
    } else if (sourceOfIncomeLength >= 3 && sourceOfIncomeLength <= 5) {
      return "£99 +VAT";
    } else if (sourceOfIncomeLength > 5) {
      return "£220 +VAT";
    } else {
      return "";
    }
  };
  console.log(sourceOfIncome.length, "sourceOfIncomeLength")

  const initialPrice = getInitialPricePlan(sourceOfIncome.length);

  const [bankState, setBankState] = useState({
    price: formData?.bankState?.price || initialPrice,
  });

  useEffect(() => {
    // Update the price plan if the sourceOfIncome length changes after the component mounts
    const updatedPrice = getInitialPricePlan(sourceOfIncome.length);
    setBankState((prevFormData) => ({
      ...prevFormData,
      price: updatedPrice,
    }));
  }, [sourceOfIncome.length]);
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setBankState((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  //console.log(bankState);
  const handleNext = () => {
    if (bankState.price === "£64.99 +VAT") {
      bankState.type = "Independent"
      bankState.description = "Going Solo"
    } else if (bankState.price === "£99 +VAT") {
      bankState.type = "Enhanced"
      bankState.description = "Assisted"
    } else {
      bankState.type = "Professional"
      bankState.description = "Accountant Review"
    }

    setLoading(true)
    onNext({ bankState });
  };
  return (
    <>
      <div className="mx-auto max-w-7xl bg-[#D9D9D9]/[29%] py-10 mt-5 font-poppins">
        <div className="text-center">
          <div className="md:text-3xl text-lg font-medium text-[#244B69] bg-[#E0A045] p-5 w-full">
            That’s all we need for now.
          </div>
          <div className="text-[#244B69] font-medium md:text-xl text-text-base mt-2">
            Buy Now
          </div>
          <div className="text-[#E0A045] font-bold md:text-3xl text-lg mt-2">
            Annual subscription.
          </div>
          <div className="text-[#244B69] font-medium md:text-xl text-text-base mt-2">
            Renews every 12 month.
          </div>
        </div>
      </div>
      <div>
        <div className="bg-[#7FC2C2]/[29%] px-10 py-[5rem] relative">
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 text-center">
            <div className="w-[152px] h-[152px] mx-auto">
              <img src="../images/priceimg.png" alt="" className="w-full" />
            </div>
            <div className="text-[#00B67A] font-bold md:text-[2.5rem] text-[1.5rem] border-b-[4px] py-10 border-[#444A4C] ">
              Price Plans
            </div>
            <div>
              <div className="py-5 border-b-[1px] border-[#444A4C] relative">
                <p className="text-[#00B67A] text-2xl">Independent</p>
                <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
                  £64.99 +VAT
                </div>
                <p className="text-[#244B69] md:text-2xl text-xl">
                  (Going solo)
                </p>
                <div className="py-5 relative">
                  <input
                    type="radio"
                    className="hidden"
                    id="option1"
                    onChange={handleChange}
                    name="price"
                    checked={bankState.price === "£64.99 +VAT"}
                    value="£64.99 +VAT"
                  />
                  <label
                    htmlFor="option1"
                    className="pay-price text-white font-medium md:text-2xl text-xl bg-[#8E8E8E] py-3 px-10 my-5 cursor-pointer"
                  >
                    Get started
                  </label>
                </div>
                {
                  sourceOfIncome.length <= 2 && (

                    <div className="lg:absolute left-[60%] bottom-0">
                      <div className="lg:w-72 lg:h-16 z-10">
                        <img src="../images/vector.png" alt="" className="max-md:hidden" />
                        <ul className=" text-[10px] list-disc text-start leading-tight lg:w-48 z-10 top-5 lg:absolute right-2">
                          <li>We would recommend you to go for our <strong className="text-[#E0A045]">Independent</strong> plan. This plan should be sufficient to meet your needs at this point.</li>
                          <li className="mt-2">You can choose any route and upgrade later if you want to</li>
                        </ul>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="py-5 border-b-[1px] border-[#444A4C] relative">
                <p className="text-[#00B67A] text-2xl">Enhanced</p>
                <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
                  £99 +VAT
                </div>
                <p className="text-[#244B69] md:text-2xl text-xl">
                  (Assisted)
                </p>
                <div className="py-5">
                  <input
                    type="radio"
                    className="hidden"
                    id="option2"
                    onChange={handleChange}
                    name="price"
                    checked={bankState.price === "£99 +VAT"}
                    value="£99 +VAT"
                  />
                  <label
                    htmlFor="option2"
                    className="pay-price text-white font-medium md:text-2xl text-xl bg-[#8E8E8E] py-3 px-10 my-5 cursor-pointer"
                  >
                    Get started
                  </label>
                </div>
                {/* {sourceOfIncome.length >= 3 && sourceOfIncome.length <= 5 && ( */}
                {sourceOfIncome.length >= 3 && (
                  <div className="absolute left-[60%] bottom-0 hidden lg:block">
                    <div className="w-72 h-16 z-10">
                      <img src="../images/vector.png" alt="" />
                      <ul className="text-[10px] list-disc text-start leading-tight w-48 z-10 top-5 absolute right-2">
                        <li>
                          We would recommend you to go for our
                          <strong className="text-[#E0A045]">Enhanced</strong> plan. This plan
                          should be sufficient to meet your needs at this point.
                        </li>
                        <li className="mt-2">
                          You can choose any route and upgrade later if you want to
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
              <div className="py-5 relative">
                <p className="text-[#00B67A] text-2xl">Professional</p>
                <div className="text-[#ECBD41] md:text-2xl text-xl font-bold">
                  £220 +VAT
                </div>
                <p className="text-[#244B69] md:text-2xl text-xl">
                  (Accountant Review)
                </p>
                <div className="py-5">
                  <input
                    type="radio"
                    className="hidden"
                    id="option3"
                    onChange={handleChange}
                    name="price"
                    checked={bankState.price === "£220 +VAT"}
                    value="£220 +VAT"
                    disabled
                  />
                  <label
                    htmlFor="option3"
                    className="text-white font-medium md:text-2xl text-xl bg-[#8E8E8E] py-3 px-10 my-5"
                  >
                    Coming Soon
                  </label>
                </div>
                {/* {
                  sourceOfIncome.length > 5 && (
                    <div className="absolute left-[60%] bottom-0 hidden lg:block">
                      <div className="w-72 z-10">
                        <img src="../images/vector.png" alt="" />
                        <ul className=" text-[10px] list-disc text-start leading-tight w-48 z-10 top-10 absolute right-2">
                          <li>We would recommend you to go for our <strong className="text-[#E0A045]">Professional</strong> plan. This plan should be sufficient to meet your needs at this point.</li>
                        </ul>
                      </div>
                    </div>

                  )
                } */}
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-[5%] lg:px-[10rem] px-[2rem]">
        <button
          onClick={onPrevious}
          className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center"
        >
          <LeftArrow />
        </button>
        <button
          onClick={handleNext}
          className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center"
        >
          <RightArrow />
        </button>
      </div>
      <div className="my-[5%]">
        <Surety />
      </div>
    </>
  );
}
