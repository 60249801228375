import axios from 'axios'
const baseUrl = `/api/plans`

const getAll = () => {
    const request = axios.get(baseUrl)
    return request.then(response => response.data)
}

const getPlan = clientId => {
  const request = axios.get(`${baseUrl}/${clientId}`)
  return request.then(response => response.data)
}

const create = newObject => {
  const request = axios.post(`${baseUrl}/createplan`, newObject)
  return request.then(response => response.data)
}

const update = (clientId, newObject) => {
  const request = axios.put(`${baseUrl}/updateplan/${clientId}`, newObject)
  return request.then(response => response.data)
}

const remove = id => {
    const request = axios.delete(`${baseUrl}/${id}`);
    return request.then(response => response.data);
};

// eslint-disable-next-line
export default { getAll, getPlan, create, update, remove };