
const Loader = () => {
    return (
        <div className="min-h-[100vh] flex items-center justify-center">
          <div className="loader"></div>
        </div>
    )
}

export default Loader
