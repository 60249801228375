import React from 'react';


const ArticleDownloadButton = ({ filename }) => {
  const handleDownload = () => {
    const fileURL = require(`../articles/${filename}`);
    window.open(fileURL, '_blank');
    console.log(fileURL)
    const link = document.createElement('a');
    link.href = fileURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button className='flex underline hover:text-[#7FC3C3]' onClick={handleDownload}>
      {filename}
    </button>
  );
};

export default ArticleDownloadButton;
