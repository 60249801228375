import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
// import ZoomInSvg from '../../assets/images/zoom-in.svg'
// import ZoomOutSvg from '../../assets/images/zoom-out.svg'
// import DownloadSvg from '../../assets/images/download-file-round-icon.svg'

const PdfToolbar = ({file}) => {
    const toolbarPluginInstance = toolbarPlugin();
    // const { Toolbar } = toolbarPluginInstance;
    return (
        <>
            {/* <div>
                <Toolbar>
                    {(props) => {
                        const {
                            CurrentScale,
                            ZoomIn,
                            ZoomOut,
                            Download,
                        } = props;
                        return (
                            <div className='flex -mt-2'>
                                <div className=''>
                                    <Download>
                                        {(props) => (
                                            <button
                                                onClick={props.onClick}
                                            >
                                                <img src={DownloadSvg} alt="" className='w-5 lg:w-8' />
                                            </button>
                                        )}
                                    </Download>
                                </div>
                                <div className='flex w-10/12 justify-center gap-4'>
                                    <div>
                                        <ZoomOut>
                                            {(props) => (
                                                <button
                                                    onClick={props.onClick}
                                                >
                                                    <img src={ZoomOutSvg} alt="" className='w-4 lg:w-5' />
                                                </button>
                                            )}
                                        </ZoomOut>
                                    </div>
                                    <div className='-mt-[.1rem]'>
                                        <CurrentScale>
                                            {(props) => (
                                                <span>{`${Math.round(props.scale * 100)}%`}</span>
                                            )}
                                        </CurrentScale>
                                    </div>
                                    <div>
                                        <ZoomIn>
                                            {(props) => (
                                                <button
                                                    onClick={props.onClick}
                                                >
                                                    <img src={ZoomInSvg} alt="" className='w-4 lg:w-5' />
                                                </button>
                                            )}
                                        </ZoomIn>
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </Toolbar>
            </div> */}
            <div>
                <Viewer fileUrl={file} plugins={[toolbarPluginInstance]} />
            </div>
        </>
    )
}

export default PdfToolbar