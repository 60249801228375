import Surety from "../../../../assets/Surety";
import Topbtn from "../../../../assets/basic/Topbtn";
import CalTopHead from "../allCalProps.jsx/CalTopHead";
import RentRoomCal from "../allCalProps.jsx/RentRoomCal";
export default function RentRoomPage(){
    return(
        <>
        <div className="mx-auto max-w-[1001px] md:px-10 px-5 font-poppins">
            <CalTopHead
                title="Rent a Room Calculator"
                para="Rent a Room earning rental income from a tenant in your home or a buy-to-let property? Quickly calculate how much you can expect to pay in rental income tax."
            />
            <RentRoomCal/>
        </div>
        <div className="pt-10">
          <Surety />
        </div>
        <div className="max-w-7xl flex justify-end p-5">
            <div className="w-[98px]">
                <Topbtn />
            </div>
        </div>
    </>
    )
}