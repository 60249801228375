import { useEffect } from "react";
import BacktoTop from "../../assets/basic/backtoTop";
import Priceplan from "../../assets/common/Priceplan";
import Surety from "../../assets/Surety";
import PuzzleImg from "../../assets/images/PuzzleImg.svg";

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="text-center">
      <div className="bg-[#7FC3C3BF] px-10 py-20">
        <div className="font-bold md:text-4xl text-2xl pb-3">
          Want to know more
        </div>
        <p className="font-bold md:text-4xl text-2xl text-[#244B69]">
          About Us?
        </p>
      </div>
      <div className="mx-auto max-w-7xl text-center mt-10 pb-7 px-5 font-poppins">
        <div className="text-[#244B69] font-semibold lg:text-xl text-base mt-[5%]">
          Tax Magpie was founded to address a common frustration many people
          face.
        </div>
        <div className="flex items-center justify-center my-10">
          <img src={PuzzleImg} alt="" />
        </div>
        <div className="relative grid md:grid-cols-2 grid-cols-1  lg:gap-x-[8rem] md:gap-x-[2rem] gap-x-[0.5rem] lg:gap-y-[4rem] gap-y-[5rem] flex items-center mt-[5%]">
          <div>
              <li className="font-medium lg:text-xl text-base ml-3 line-50 list-none text-start text-[#444A4C]">
                As the number of self-employed individuals and those earning
                extra income through side hustles grows.
              </li>
              <li className="font-medium lg:text-xl text-base ml-3 line-50 list-none text-start pt-10 text-[#444A4C]">
                We noticed that self-assessment tax procedures often appear
                confusing.
              </li>
          </div>
          <div className="mr-0">
            <img className="w-full" src="../images/aboutimg1.png" alt="" />
          </div>
          <div className="order-4 md:order-none">
            <img className="w-full" src="../images/aboutimg2.png" alt="" />
          </div>
          <div>
              <li className="font-medium lg:text-xl text-base ml-3 line-50 list-none text-start text-[#444A4C]">
                The available options seemed limited: either hire a reputable
                accountant and pay exorbitant fees, or attempt to navigate the
                process alone with little assistance or guidance.
              </li>
              <li className="font-medium lg:text-xl text-base ml-3 line-50 list-none text-start pt-10 text-[#444A4C]">
                Unfortunately, many accountants tend to use complex jargon and
                language that can be overwhelming and inaccessible to many.
              </li>
          </div>
          <div className="absolute left-[45%] top-[20%] lg:block hidden"><img src="../images/line.png" alt="" /></div>
        </div>
        <div className="mt-[15%]">
          <div className="text-[#E0A045] font-bold lg:text-5xl text-3xl">
            At Tax Magpie,
          </div>
          <div className="text-[#244B69] font-normal lg:text-xl text-base mt-5">
            we made it our mission to simplify self-assessment, making it
            accessible to all
          </div>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1  gap-4 mt-[15%]">
          <div className="flex items-center justify-center">
            <img src="../images/arrowbanner.png" alt="" />
          </div>
          <div>
            <div className="md:mt-0 mt-5 text-[#244B69] font-bold text-xl ml-3 line-50 list-none text-start border-b-2 border-[#E0A045] pb-4 mb-8">
              Through our Four price plans, we aim
              to alleviate the headache associated with this process while
              catering to different budgets.
            </div>
              <li className="font-medium lg:text-xl text-base ml-3 line-50 list-none text-start text-[#444A4C]">
              We provide handy tips and guides developed with the latest information and constantly updated to ensure everyone can confidently file their returns accurately. In cases where your situation is more complex, we offer the option to have one of our dedicated tax experts assess your return for a small additional fee before submission.
              </li>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pb-[4rem]">
        <p className="lg:text-lg text-sm font-semibold my-[10%]  border-y-[1px] border-[#244B69] py-2">
          Our primary goal is to make completing your self-assessment as clear
          and straightforward as possible.
        </p>
      </div>
      <Priceplan/>
      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8 pb-[4rem] mt-10">
        <Surety />
        <BacktoTop />
      </div>
    </div>
  );
}