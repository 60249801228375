import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";
import ValidateDate from "../../../utils/ValidateDate";
import ValidateMobileNumber from "../../../utils/ValidateMobileNumber";
import ValidateEmail from "../../../utils/ValidateEmail";
import ValidateNi from "../../../utils/ValidateNi";
import AddressFinderSetup from "../../../utils/AddressFinderSetup";
import ValidateName from "../../../utils/ValidateName";
import useClient from "../../../hooks/useClient";
import useUser from "../../../hooks/useUser";
import IsObjectEmpty from "../../../utils/IsObjectEmpty";
import clientService from "../../../services/clientService";
import IsObjectFull from "../../../utils/IsObjectFull";
import FormatDateYMD from "../../../utils/FormatDateYMD";

const AddUpdateClient = () => {

    const { activeClient, setActiveClient } = useClient();
    const { setLoading } = useUser();

    const navigate = useNavigate()

    const [userInfo, setUserInfo] = useState({
        firstname: activeClient.first_name || "",
        lastname: activeClient.last_name || "",
        address: activeClient.address || "",
        postcode: activeClient.postcode || "",
        dob: FormatDateYMD(activeClient?.dob) || "",
        nic: activeClient.ni_number || "",
        emailAddress: activeClient.email_address || "",
        telephone: activeClient.phone_number || "",
    });
    const [dataError, setDataError] = useState({
        firstname: "",
        lastname: "",
        dob: "",
        nic: "",
        emailAddress: "",
        telephone: ""
    })

    const postcodeRef = {
        postcode: useRef(null),
        address: useRef(null),
    }

    useEffect(() => {
        console.log(isEmptyClient())
        populateAddressLookup()
    }, [])

    const populateAddressLookup = async () => {
        let { mergedAddress, postcode, town } = await AddressFinderSetup();
        // console.log(mergedAddress)
        // console.log(postcode)
        // console.log(town)
        // setUserInfo((prevUserInfo) => ({
        //     ...prevUserInfo,
        //     address: mergedAddress,
        //     postcode: postcode,
        //     city: town
        // }))
    }

    const isEmptyClient = () => JSON.stringify(activeClient) === '{}';

    const checkAddress = (e) => {
        console.log(e)
        if (e.key === "Backspace" || e.keyCode === 8) {
            setUserInfo(prevUserInfo => ({
                ...prevUserInfo,
                address: "",
                postcode: ""
            }))
        }
    }

    const handleInfoChange = async (e) => {
        let { value, name } = e.target
        if (name === "firstname" || name === "lastname") {
            let error = ""
            const validationError = ValidateName(value);
            if (value.length < 2) {
                error = "Please enter a name with at least 2 characters."
                setDataError({ ...dataError, [name]: error })
            } else if (validationError) {
                error = validationError
                setDataError({ ...dataError, [name]: error })
                return;
            } else {
                error = ""
                setDataError({ ...dataError, [name]: error })
            }
        }
        else if (name === 'dob') {
            setDataError({ ...dataError, [name]: ValidateDate(value) })
        }
        else if (name === "telephone") {
            if (value.length > 11) {
                return;
            }
            else if (value.length === 11) {
                setDataError({ ...dataError, [name]: ValidateMobileNumber(value) })
            }
            else if (value.length < 11) {
                setDataError({ ...dataError, [name]: "Incomplete mobile number" })
            }
        }
        else if (name === 'emailAddress') {
            setDataError({ ...dataError, [name]: ValidateEmail(value) })
        }
        else if (name === 'nic') {
            value = value.toUpperCase();
            setDataError({ ...dataError, [name]: ValidateNi(value) })
        }
        else if (name === 'address' && value === "") {
            setUserInfo(prevUserInfo => ({
                ...prevUserInfo,
                city: ""
            }))
        }
        setUserInfo(prevUserInfo => ({
            ...prevUserInfo,
            [name]: value
        }))
    }

    const createClient = async () => {
        if (IsObjectFull(userInfo)) {
            console.log("fill all data")
            return false;
        }
        else if (!IsObjectEmpty(dataError)) {
            console.log("error", dataError)
            return false;
        }

        // setUserInfo(prevUserInfo => ({
        //     ...prevUserInfo,
        //     address: `${userInfo.address}, ${userInfo.city}`
        // }))

        // let data = userInfo

        setLoading(true);
        clientService.createOrUpdate(userInfo).then(data => {
            console.log(data)
            setActiveClient(data.client)
            navigate(`/crm/${data.client.ref}`);
            setLoading(false)
        }).catch((error) => {
            console.log(error);
            setLoading(false)
        })
    }

    return (
        <>
            <div className="bg-neutral-100 xl:py-5 xl:px-16">
                <div className="col-span-4 lg:col-span-3 px-5 xl:px-10 py-10 border border-[#D9D9D9] bg-white">
                    <div className="relative text-lg font-normal text-[#1E1E1E] flex justify-around items-center">
                        <div className=" relative w-full">
                            <div className="absolute top-5 left-0">
                                <Link to={`${isEmptyClient() ? "/" : `/${activeClient.ref}`}`}>
                                    <svg
                                        width="34"
                                        height="35"
                                        viewBox="0 0 34 35"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M17.0677 32.7587C25.4776 32.7587 32.2952 25.9411 32.2952 17.5312C32.2952 9.12138 25.4776 2.30383 17.0677 2.30383C8.65788 2.30383 1.84033 9.12138 1.84033 17.5312C1.84033 25.9411 8.65788 32.7587 17.0677 32.7587Z"
                                            fill="#00B67A"
                                            stroke="#00B67A"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M17.0678 11.4403L10.9768 17.5312L17.0678 23.6222"
                                            fill="#00B67A"
                                        />
                                        <path
                                            d="M17.0678 11.4403L10.9768 17.5312L17.0678 23.6222"
                                            stroke="white"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M23.1587 17.5312H10.9768"
                                            stroke="white"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Link>
                            </div>
                            <div className="py-20">
                                <div className="border-b border-[#D9D9D9] pb-5">
                                    <p className="text-[#244B69] text-xl lg:text-2xl">
                                        {isEmptyClient() ? "Create" : "Edit"} Client
                                    </p>
                                </div>

                                <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            First Name:
                                        </p>
                                        <input
                                            type="text"
                                            className="bg-neutral-100 rounded-md h-16 px-5"
                                            name="firstname"
                                            value={userInfo.firstname}
                                            onChange={handleInfoChange}
                                        />
                                        {dataError.firstname && <p className="text-red-500">{dataError.firstname}</p>}
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            Last Name:
                                        </p>
                                        <input
                                            type="text"
                                            className="bg-neutral-100 rounded-md h-16 px-5"
                                            name="lastname"
                                            value={userInfo.lastname}
                                            onChange={handleInfoChange}
                                        />
                                        {dataError.lastname && <p className="text-red-500">{dataError.lastname}</p>}
                                    </div>
                                </div>

                                <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            Email:
                                        </p>
                                        <input
                                            type="text"
                                            className="bg-neutral-100 rounded-md h-16 px-5"
                                            name="emailAddress"
                                            value={userInfo.emailAddress}
                                            onChange={handleInfoChange}
                                        />
                                        {dataError.emailAddress && <p className="text-red-500">{dataError.emailAddress}</p>}
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            Mobile Number:
                                        </p>
                                        <input
                                            type="text"
                                            className="bg-neutral-100 rounded-md h-16 px-5"
                                            name="telephone"
                                            value={userInfo.telephone}
                                            onChange={handleInfoChange}
                                        />
                                        {dataError.telephone && <p className="text-red-500">{dataError.telephone}</p>}
                                    </div>
                                </div>

                                <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            Address:
                                        </p>
                                        <input
                                            type="text"
                                            className="bg-neutral-100 rounded-md h-16 px-5 w-full"
                                            id="address"
                                            name="address"
                                            defaultValue={activeClient.address}
                                            value={userInfo.address}
                                            onChange={handleInfoChange}
                                            ref={postcodeRef.address}
                                            onKeyDown={checkAddress}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            Postcode:
                                        </p>
                                        <input
                                            type="text"
                                            className="bg-neutral-100 rounded-md h-16 px-5"
                                            id="postcode"
                                            name="postcode"
                                            defaultValue={activeClient.postcode}
                                            value={userInfo.postcode}
                                            onChange={handleInfoChange}
                                            ref={postcodeRef.postcode}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                <div className="border border-[#D9D9D9] rounded-md grid grid-cols-1 lg:grid-cols-2 gap-10 p-5 mt-10">
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            Date of Birth:
                                        </p>
                                        <input
                                            className="bg-neutral-100 rounded-md h-16 px-5 uppercase"
                                            name="dob"
                                            type="date"
                                            value={userInfo.dob}
                                            onChange={handleInfoChange}
                                        />
                                        {dataError.dob && <p className="text-red-500">{dataError.dob}</p>}
                                    </div>
                                    <div className="flex flex-col gap-2">
                                        <p className="text-neutral-500 font-light lg:text-xl">
                                            NI Number:
                                        </p>
                                        <InputMask
                                            mask="aa999999a"
                                            maskChar=""
                                            placeholder="AA######A"
                                            className="bg-neutral-100 rounded-md h-16 px-5"
                                            name="nic"
                                            value={userInfo.nic}
                                            onChange={handleInfoChange}
                                        />
                                        {dataError.nic && <p className="text-red-500">{dataError.nic}</p>}
                                    </div>
                                </div>

                                <input type="text" name="city" id="city" className="hidden" value={userInfo.city} onChange={handleInfoChange} readOnly />

                                <div className="w-full flex justify-center mt-10">
                                    <button onClick={createClient} className="bg-emerald-500 text-white w-1/2 h-16 text-2xl rounded-md">
                                        {isEmptyClient() ? "Create" : "Update"} Client
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddUpdateClient;
