
export default function Warning({toggle, deleteContent}) {
  
  return (
        <>
            <div className="w-full lg:w-1/3 mx-auto bg-white fixed top-64 z-50 drop-shadow-md">
                <div className="flex items-center justify-between p-5 w-full">
                    <div className="flex items-center w-full">
                        <div className=" text-black text-lg">Are you sure?</div>
                    </div>
                    <div onClick={toggle} className="cursor-pointer" >
                        <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M17.1726 5.7832L5.97229 16.9835"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                            <path
                            d="M5.97217 5.78345L17.1725 16.9838"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div className="px-5 flex justify-between gap-10">
                    <button onClick={deleteContent}
                        className="w-full mb-4 cursor-pointer text-white bg-emerald-500 px-7 py-4 mt-5 rounded-md text-base font-medium"
                    >
                        Yes
                    </button>
                    <button onClick={toggle}
                        className="w-full mb-4 cursor-pointer text-white bg-gray-500 px-7 py-4 mt-5 rounded-md text-base font-medium"
                    >
                        No
                    </button>
                </div>
            </div>
        </>
  );
}
