import RightArrow from "../../assets/basic/RightArrow";
import Surety from "../../assets/Surety";
export default function FormFive({ onNext }) {
  return (
    <>
      <div className="mx-auto max-w-7xl bg-[#D9D9D9]/[29%] py-10 mt-5 font-poppins md:px-10 px-5">
        <div className="md:text-3xl text-lg font-medium text-white bg-[#E0A045] w-full py-6 md:px-10 px-5">
          Do you have a <span className="text-[#244B69]">UTR</span> number?
        </div>
        <ul className="mt-5">
          <div className="flex items-baseline">
            <img src="../images/Ellipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              This is your unique taxpayer reference number or Tax reference.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/Ellipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              It is a 10 digit code provided to you by HMRC when you register
              for self assessment.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/Ellipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              You do need this in order to submit your self assessment.
            </li>
          </div>
        </ul>
        <div className="md:text-3xl text-lg font-medium text-white bg-[#E0A045] w-full py-6 md:px-10 px-5 my-10">
          Ways to find it.
        </div>
        <ul className=" mt-5">
          <div className="flex items-baseline">
            <img src="../images/Ellipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              If you have already registered for it, then it will be at the top
              of any letters HMRC send to you. Alternatively you can find it in
              your personal tax account with HMRC or on the HMRC app.
            </li>
          </div>
          <div className="flex items-baseline">
            <img src="../images/Ellipse.png" alt="" />
            <li className="font-medium md:text-lg text-base ml-3 md:leading-[3rem] leading-[2rem]">
              If this is the first time you are submitting a self assessment you
              will need to register with HMRC. You’ll get your UTR by post 10
              days after you register. You can usually see it sooner in your
              Personal Tax Account or the HMRC app.
            </li>
          </div>
        </ul>
      </div>
      <div className=" mx-auto max-w-7xl flex justify-end my-10 lg:px-[10rem] px-[2rem]">
        <button onClick={onNext} className="w-[25%] h-[70px] bg-[#244B69] flex justify-center items-center">
          <RightArrow />
        </button>
      </div>
      <div className="mb-10">
        <Surety />
      </div>
    </>
  );
}
