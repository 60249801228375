import { useState } from "react";
import RightArrow from "../../assets/basic/RightArrow";
import useClient from "../../hooks/useClient";
import { toast } from "react-toastify";

export default function FormOne({ onNext }) {
  const { formData } = useClient();
  const [data, setData] = useState({
    selfEmploment: formData.selfEmploment || false,
    reliefOrRefund: formData.reliefOrRefund || false,
    property: formData.property || false,
    interest: formData.interest || false,
    gains: formData.gains || false,
    company: formData.company || false,
    earned: formData.earned || false,
    HMRC: formData.HMRC || false,
    otherReason: formData.otherReason || false,
  });
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
    // if (event.target.checked) {
    //   document.querySelector(`label[for="${event.target.id}"]`).classList.remove("text-[#244B69]");
    //   document.querySelector(`label[for="${event.target.id}"]`).classList.add('bg-[#244B69]','text-white');
    // } else {
    //   document.querySelector(`label[for="${event.target.id}"]`).classList.add("text-[#244B69]");
    //   document.querySelector(`label[for="${event.target.id}"]`).classList.remove('bg-[#244B69]','text-white');
    // }
  };
  function isAtLeastOneCheckboxChecked() {
    return Object.values(data).some((checked) => checked);
  }
  const handleNext = () => {
    // Write the validateion if required
    if (!isAtLeastOneCheckboxChecked()) {
      window.scrollTo({top: 0, behavior: 'smooth'})
      toast.warning("Please check at least one option")
      return false;
    }
    onNext({ ...data });
  };
  

  return (
    <div className="mx-auto max-w-7xl md:px-2 px-5 sm:px-6 xl:px-8 bg-[#D9D9D9]/[29%] text-center py-10 mt-5 font-poppins">
      <div className="mx-auto max-w-[732px]">
        <p className="font-medium xl:text-3xl lg:text-2xl text-xl text-[#244B69] mt-5 mb-10">
          Lets understand why you need to prepare your tax return.
        </p>
        <div className=" parent flex justify-center flex-col items-center mt-[2%]">
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="selfEmploment"
              onChange={handleChange}
              name="selfEmploment"
              checked={data.selfEmploment}
            />
            <label
              htmlFor="selfEmploment"
              className=" color1 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer border border-black flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28"
            >
              I earned income by self employment
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="reliefOrRefund"
              onChange={handleChange}
              name="reliefOrRefund"
              checked={data.reliefOrRefund}
            />
            <label
              htmlFor="reliefOrRefund"
              className="color2 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              I’m claiming a tax relief or refund
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="property"
              onChange={handleChange}
              name="property"
              checked={data.property}
            />
            <label
              htmlFor="property"
              className=" color1 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              I’v earned income from property
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="interest"
              onChange={handleChange}
              name="interest"
              checked={data.interest}
            />
            <label
              htmlFor="interest"
              className="color2 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              I earned dividends or interest
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="gains"
              onChange={handleChange}
              name="gains"
              checked={data.gains}
            />
            <label
              htmlFor="gains"
              className="color1 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              I earned capital gains
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="company"
              onChange={handleChange}
              name="company"
              checked={data.company}
            />
            <label
              htmlFor="company"
              className="color2 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              I’m a director of a company
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="earned"
              onChange={handleChange}
              name="earned"
              checked={data.earned}
            />
            <label
              htmlFor="earned"
              className="color1 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              I earned over 100k
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="HMRC"
              onChange={handleChange}
              name="HMRC"
              checked={data.HMRC}
            />
            <label
              htmlFor="HMRC"
              className="color2 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              HMRC sent me a letter and told me to
            </label>
          </div>
          <div className="mb-4 lg:mb-6 xl:mb-10 w-full">
            <input
              type="checkbox"
              className="hidden"
              id="otherReason"
              onChange={handleChange}
              name="otherReason"
              checked={data.otherReason}
            />
            <label
              htmlFor="otherReason"
              className="color1 text-[#244B69] xl:text-2xl lg:text-xl text-base font-medium cursor-pointer flex items-center justify-center max-w-[730] h-16 lg:h-24 xl:h-28 border border-black"
            >
              I have other reasons.
            </label>
          </div>
        </div>
        <button
          onClick={handleNext}
          className="w-full h-[80px] flex justify-center items-center bg-[#244B69]"
        >
          <RightArrow />
        </button>
      </div>
    </div>
  );
}
