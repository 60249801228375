import { useEffect, useState } from "react";
import SingleClient from "./SingleClient";
import SearchFilters from "./crmPopups/SearchFilters";
import clientService from "../../../services/clientService";
import useClient from "../../../hooks/useClient";
import { useNavigate } from "react-router";
import IsObjectEmpty from "../../../utils/IsObjectEmpty";
import filterService from "../../../services/filterService";
import useUser from "../../../hooks/useUser";
import Loader from "../../../assets/common/Loader";
import CrmTabsState from "../../../context/tabs/CrmTabsState";

export default function CRMClients() {
  const navigate = useNavigate()
  const [isDivVisible, setIsDivVisible] = useState(false);
  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  const { setActiveClient } = useClient()
  const { loading, setLoading } = useUser()

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [totalClients, setTotalClients] = useState(0);
  const [limitedClients, setLimitedClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);

  // const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  const updateClients = () => {
    setFilteredClients([])
    fetchLimitedClients()
  }

  useEffect(() => {
    fetchLimitedClients()
  }, [page])

  useEffect(() => {
    setActiveClient({});
  }, []);

  const fetchLimitedClients = async () => {
    setLoading(true)
    clientService.getLimitedClients(page, pageSize).then(data => {
      console.log(data)
      setTotalClients(data.totalCount)
      setTotalPages(data.totalPages);
      setLimitedClients(data.clients)
    }).catch(error => {
      console.log(error)
    }).finally(
      setLoading(false)
    )
  }

  const handleFilteredCleints = async (filteredObject) => {
    console.log(filteredObject)
    const returnedClients = await filterService.filterClient(filteredObject)
    setFilteredClients(returnedClients)
    console.log(returnedClients)
  }

  const fetchDesiredClients = async (e) => {
    const { value } = e.target
    setPageSize(value)
    if (e.key === "Enter" && value <= 50 && value >= 10) fetchLimitedClients()
  }

  const getPageRange = () => {
    const totalPages = Math.ceil(totalClients / pageSize);
    const maxPagesToShow = 5;
    let startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
  };


  return (
    <>
      {
        loading ? <Loader /> :
          <div className="bg-gradient-to-b from-[#D9D9D9]/[40%] to-[#D9D9D9]/[10%] flex w-full flex-col px-3 lg:px-10 py-9">
            <div className="relative">
              <div className="absolute w-96 opacity-10 -right-10 top-10 -z-0 select-none pointer-events-none	">
                <img src="../images/FullColour-Logo.png" alt="" className="w-full -scale-x-100" />
              </div>
              <div className="flex lg:mt-2 justify-between items-center relative z-20 flex-wrap">
                <div className="justify-center text-cyan-900 text-2xl lg:text-4xl font-medium">
                  Clients
                </div>
                <div className="flex justify-between gap-0 max-md:justify-center">
                  <div onClick={toggleDivVisibility} className="cursor-pointer justify-center bg-zinc-200 text-cyan-900 text-xs lg:text-base font-medium border p-2 lg:px-6 lg:py-4 rounded-l-md border-cyan-900 items-center">
                    Search Filter
                  </div>
                  <button onClick={updateClients} className="cursor-pointer border flex flex-col justify-center items-center p-2 lg:px-6 lg:py-4 border-solid border-x-0 border-cyan-900">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/db013d48dab756ffeda605f1939085876b3c026a8773fbdb74ddce37b9a9ace8?"
                      className="aspect-[1.25] object-contain object-center w-[15px] overflow-hidden"
                    />
                  </button>
                  <button onClick={() => navigate(`/addClient`)} className="bg-emerald-500 border-cyan-900 border flex items-center justify-between gap-2.5 p-2 lg:px-6 lg:py-4 rounded-r-md text-white text-xs lg:text-base font-medium">
                    <div>Add New Client</div>
                    <span>+</span>
                  </button>
                </div>
                {isDivVisible && (
                  <SearchFilters isToggle={toggleDivVisibility} handleFilteredCleints={handleFilteredCleints} />
                )}
              </div>
            </div>
            <div className="flex justify-between items-center border-y border-cyan-900 mt-4 lg:mt-7 py-2 lg:py-5">
              <div className="text-emerald-500 text-sm lg:text-lg">
                <span className=" text-cyan-900">Displaying items </span>
                <span className="">{Math.min(((page - 1) * pageSize) + 1, totalClients)}-
                  {Math.min(page * pageSize, totalClients)} out of {totalClients}</span>
                <span className=" text-cyan-900"> in total</span>
              </div>
              <div className="hidden md:flex justify-center items-center gap-2 text-cyan-900 lg:text-lg">
                Show <input className="border border-[#244B69]  text-emerald-500 lg:p-2 w-10 lg:w-12 text-center" value={pageSize} onChange={fetchDesiredClients} onKeyDown={fetchDesiredClients} maxLength={2}/> items per page
              </div>
            </div>
            <div className="border border-gray-500 mt-5">
              <div className="hidden lg:grid grid-cols-8 place-items-center">
                <div className="flex flex-col lg:flex-row items-center w-full h-full justify-center border-r-2 border-[#D9D9D9] gap-2">
                  <div className="text-stone-900 text-base lg:text-lg leading-4 self-center whitespace-nowrap lg:my-auto lg:py-7">
                    Select all
                  </div>
                  <input className="mr-3 w-7 h-7" type="checkbox" />
                </div>
                <div className="text-stone-900 col-span-2 text-base lg:text-xl font-medium flex w-full h-full justify-center border-r-2 border-[#D9D9D9] py-7">
                  Ref
                </div>
                <div className="text-stone-900 text-base lg:text-xl font-medium flex w-full h-full justify-center border-r-2 border-[#D9D9D9] py-7">
                  Name
                </div>
                <div className="text-stone-900 text-base lg:text-xl font-medium flex w-full h-full justify-center border-r-2 border-[#D9D9D9] py-7">
                  NI number
                </div>
                <div className="text-stone-900 col-span-2 text-base lg:text-xl font-medium flex w-full h-full justify-center border-r-2 border-[#D9D9D9] py-7">
                  Email
                </div>
                <div className="text-stone-900 text-base lg:text-xl font-medium flex w-full h-full justify-center text-center border-r-2 border-[#D9D9D9] py-7">
                  Mobile Number
                </div>
              </div>
              <CrmTabsState>
                {!IsObjectEmpty(filteredClients)
                  ? filteredClients.map((client, index) => (
                    <SingleClient key={client.id} client={client} index={index} updateClients={updateClients} />
                  ))
                  : limitedClients.map((client, index) => (
                    <SingleClient key={client.id} client={client} index={index} updateClients={updateClients} />
                  ))}
              </CrmTabsState>

              {IsObjectEmpty(filteredClients) &&
                <div className="w-full flex justify-center items-center h-20 mt-10 gap-10 border-y border-cyan-900">
                  {totalPages > 5 && <button onClick={() => setPage(1)}>{"<<"}</button>}
                  {totalPages > 1 && <button onClick={() => setPage(page - 1)} disabled={page === 1}>Previous</button>}
                  <div className="flex gap-3 max-w-[12rem] overflow-hidden">
                    {getPageRange().map((pageNumber) => (
                      <button
                        key={pageNumber}
                        onClick={() => setPage(pageNumber)}
                        className={` text-white w-7 h-7 rounded-md ${pageNumber === page ? 'bg-[#ecbd41]' : 'bg-[--base-color]'} flex-shrink-0`}
                      >
                        {pageNumber}
                      </button>
                    ))}
                  </div>

                  {totalPages > 1 && <button onClick={() => setPage(page + 1)} disabled={page === totalPages}>Next</button>}
                  {totalPages > 5 && <button onClick={() => setPage(totalPages)}>{">>"}</button>}
                </div>
              }

            </div>
          </div>
      }
    </>
  );
}
