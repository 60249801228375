
import React, { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import ValidateNi from "../../../utils/ValidateNi";

let niValueLength = false;
const SectionTwo = ({ userInfo, setUserInfo, handleChange2, dataError, setDataError, handleKeyDown, inputRefs, niRefs }) => {

  const handleFieldChange = (e) => {
    let { name, value } = e.target;
    value = value.toUpperCase();
    name = name.split("-")[1]
    // console.log(name);
    if (name !== "fifth") {
      if (e.key === "Backspace" && value !== "") {
        setNiFields({ ...niFields, [name]: value.slice(name === "first" ? (0, 1) : (0, -1)) })
      } else {
        setNiFields({ ...niFields, [name]: value })
        if ((e.key === "Enter" || value.length === 2)) {
          // console.log("here", niRefs[name]);
          niRefs[getNextKey(niFields, name)].current.getInputDOMNode().focus();
        } else if (e.key === "Backspace" && name !== "first") {
          niRefs[getPreviousKey(niFields, name)].current.getInputDOMNode().focus();
        }
      }
    } else {
      if (e.key === "Backspace" && niFields.fifth === "" && niFields.fourth !== "") {
        niRefs.fourth.current.getInputDOMNode().focus();
      } else if ((e.key === "Enter" || e.keyCode === 13) && niFields.fifth !== ""){
        inputRefs.current[4].focus()
      } else {
        setNiFields({ ...niFields, [name]: value })
      }
    }
  };

  function getNextKey(obj, currentKey) {
    const keys = Object.keys(obj);
    const currentIndex = keys.indexOf(currentKey);
    if (currentIndex === -1 || currentIndex === keys.length - 1) {
      return null; // currentKey doesn't exist or it's the last key
    } else {
      return keys[currentIndex + 1];
    }
  }

  function getPreviousKey(obj, currentKey) {
    const keys = Object.keys(obj);
    const currentIndex = keys.indexOf(currentKey);
    if (currentIndex === -1 || currentIndex === keys.length - 1) {
      return null; // currentKey doesn't exist or it's the last key
    } else {
      return keys[currentIndex - 1];
    }
  }

  const [niFields, setNiFields] = useState({
    first: "",
    second: "",
    third: "",
    fourth: "",
    fifth: "",
  });

  const [hasMounted, setHasMounted] = useState(false);

  const updateNiValue = () => {
    const niValue =
      Object.values(niFields).reduce((val1, val2) => { return val1.toString() + val2.toString() }, "")
    // setNi(niValue);
    // console.log(niValue);
    if (niValue.length === 9) {
      niValueLength = true;
    }
    if (niValue.length === 9 || niValueLength) {
      let error = ValidateNi(niValue);
      // console.log(error);
      setDataError({...dataError, nic: error})
      setUserInfo({...userInfo, nic: niValue})
      // setNiError(error);
    }
  };

  useEffect(() => {
    // console.log(hasMounted);
    if (hasMounted) {
      // console.log("in effect");
      updateNiValue();
    } else {
      setHasMounted(true);
    }
  }, [niFields])

  return (
    <>
      <div className="font-normal xl:text-2xl text-xl  text-[#244B69] pt-5 pb-5 lg:pb-10">
        2. Input your personal details
      </div>
      <form>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-y-4 gap-x-20 xl:gap-x-[10rem]">
          <div className="flex flex-col">
            <label
              htmlFor="firstname"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              First Name:
            </label>
            <input
              id="firstname"
              type="text"
              placeholder="Enter Your First Name"
              className="bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem]"
              name="firstname"
              value={userInfo.firstname}
              onChange={handleChange2}
              ref={(ref) => (inputRefs.current[0] = ref)}
              onKeyDown={(event) => handleKeyDown(event, 0)}
            />
            {dataError.firstname && <p className="text-red-500">{dataError.firstname}</p>}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="lastname"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              Last Name:
            </label>
            <input
              id="lastname"
              type="text"
              placeholder="Enter Your Last Name"
              className="bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem]"
              name="lastname"
              value={userInfo.lastname}
              onChange={handleChange2}
              ref={(ref) => (inputRefs.current[1] = ref)}
              onKeyDown={(event) => handleKeyDown(event, 1)}
            />
            {dataError.lastname && <p className="text-red-500">{dataError.lastname}</p>}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="address"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              Address:
            </label>
            <input
              id="address"
              type="text"
              placeholder="Area, City"
              className="bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem] w-full"
              name="address"
              value={userInfo.address}
              onChange={handleChange2}
              ref={(ref) => (inputRefs.current[2] = ref)}
              onKeyDown={(event) => handleKeyDown(event, 2)}
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="postcode"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              Postcode
            </label>
            <input
              id="postcode"
              type="text"
              placeholder="Postal Code..."
              className="bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem]"
              name="postcode"
              readOnly
              // ref={(ref) => (inputRefs.current[3] = ref)}
              value={userInfo.postcode}
            // onChange={handleChange2}
            // onKeyDown={(event) => handleKeyDown(event, 3)}
            />
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="dob"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              Date of Birth:
            </label>
            <input
              className="bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem] uppercase"
              name="dob"
              id="dob"
              type="date"
              value={userInfo.dob}
              onChange={handleChange2}
              ref={(ref) => (inputRefs.current[3] = ref)}
              onKeyDown={(event) => handleKeyDown(event, 3)}
            />
            {dataError.dob && <p className="text-red-500">{dataError.dob}</p>}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="nic"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              NI Number:
            </label>
            {/* <InputMask
              mask="aa999999a"
              maskChar=""
              placeholder="AA######A"
              className="bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem] uppercase"
              name="nic"
              id="nic"
              value={userInfo.nic}
              onChange={handleChange2}
              ref={(ref) => (inputRefs.current[4] = ref)}
              onKeyDown={(event) => handleKeyDown(event, 4)}
            /> */}
            <div className="w-full lg:h-[71px] flex">
              <InputMask
                mask="aa"
                maskChar=""
                placeholder="AA"
                className="h-14 md:h-full w-full border-black bg-transparent xl:p-[1.5rem] p-[1rem] border mr-2 text-center uppercase"
                value={niFields.first}
                name="ni-first"
                id="ni-first"
                ref={niRefs.first}
                onChange={handleFieldChange}
                onKeyDown={handleFieldChange}
              />
              <InputMask
                mask="99"
                maskChar=""
                placeholder="11"
                className="h-14 md:h-full w-full border-black bg-transparent xl:p-[1.5rem] p-[1rem] border mr-2 text-center"
                value={niFields.second}
                inputMode="numeric"
                name="ni-second"
                id="ni-second"
                ref={niRefs.second}
                onChange={handleFieldChange}
                onKeyDown={handleFieldChange}
              />
              <InputMask
                mask="99"
                maskChar=""
                placeholder="22"
                className="h-14 md:h-full w-full border-black bg-transparent xl:p-[1.5rem] p-[1rem] border mr-2 text-center"
                value={niFields.third}
                name="ni-third"
                id="ni-third"
                ref={niRefs.third}
                inputMode="numeric"
                onChange={handleFieldChange}
                onKeyDown={handleFieldChange}
              />
              <InputMask
                mask="99"
                maskChar=""
                placeholder="33"
                className="h-14 md:h-full w-full border-black bg-transparent xl:p-[1.5rem] p-[1rem] border mr-2 text-center"
                value={niFields.fourth}
                name="ni-fourth"
                id="ni-fourth"
                ref={niRefs.fourth}
                inputMode="numeric"
                onChange={handleFieldChange}
                onKeyDown={handleFieldChange}
              />
              <InputMask
                mask="a"
                maskChar=""
                placeholder="D"
                className="h-14 md:h-full w-full border-black bg-transparent xl:p-[1.5rem] p-[1rem] border uppercase text-center"
                value={niFields.fifth}
                name="ni-fifth"
                id="ni-fifth"
                ref={niRefs.fifth}
                onChange={handleFieldChange}
                onKeyDown={handleFieldChange}
              />
            </div>
            {dataError.nic && <p className="text-red-500">{dataError.nic}</p>}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="email-address"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              Email Address:
            </label>
            <input
              id="email-address"
              type="text"
              placeholder="Enter your email"
              className="bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem]"
              name="emailAddress"
              value={userInfo.emailAddress}
              onChange={handleChange2}
              ref={(ref) => (inputRefs.current[4] = ref)}
              onKeyDown={(event) => handleKeyDown(event, 4)}
            />
            {dataError.emailAddress && <p className="text-red-500">{dataError.emailAddress}</p>}
          </div>
          <div className="flex flex-col">
            <label
              htmlFor="telephone"
              className="font-bold xl:text-2xl lg:text-xl text-base text-[#7FC2C2] pb-2 lg:pb-5 xl:pb-10"
            >
              Telephone:
            </label>
            <input
              id="telephone"
              type="text"
              placeholder="Enter your number"
              className={`bg-transparent outline-none border border-black lg:p-[1.5rem] p-[1rem]`}
              name="telephone"
              value={userInfo.telephone}
              onChange={handleChange2}
              ref={(ref) => (inputRefs.current[5] = ref)}
              onKeyDown={(event) => handleKeyDown(event, 5)}
            />
            {dataError.telephone && <p className="text-red-500">{dataError.telephone}</p>}

          </div>
        </div>
      </form>
    </>
  );
}

export default SectionTwo;
