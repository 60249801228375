import { useState, useEffect, Fragment } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import clientService from '../../services/clientService';
import claimService from '../../services/claimService';
import FileUpload from '../../assets/common/FileUpload';
import fileService from '../../services/fileService';
import FileUpdate from '../../assets/common/FileUpdate';
import planService from '../../services/planService';
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react";
import Surety from '../../assets/Surety';
import BacktoTop from '../../assets/basic/backtoTop';
import UpgradePlan from './UpgradePlan';
import Priceplan from '../../assets/common/Priceplan';
import Topbtn from '../../assets/basic/Topbtn';
import Tooltip from '../../assets/common/Tooltip';
import useUser from '../../hooks/useUser';
import useClient from '../../hooks/useClient';

export default function UserArea() {
  const navigate = useNavigate()
  const {activeClient} = useClient()
  const [user, setUser] = useState({})
  const [claim, setClaim] = useState({})
  const [plan, setPlan] = useState({})

  const [open, setOpen] = useState(0);

  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const handleClickScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function Icon({ id, open }) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={`${
          id === open ? "rotate-180" : ""
        } h-5 w-5 transition-transform`}
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={2}
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
      </svg>
    );
  }

  useEffect(() => {
      // console.log(activeClient);
      if (Object.keys(activeClient).length > 0) {
        fetchClientInfo()
      }
  }, [activeClient])
  
  const fetchClientInfo = async () => {
    if (activeClient) {
      const returnedClaim = await claimService.getClaim(activeClient.id)
      console.log(activeClient)
      console.log(returnedClaim)
      setUser(activeClient)
      setClaim(returnedClaim[0])
      if (!returnedClaim.error) {
        const returnedPlan = await planService.getPlan(activeClient.id)
        console.log(returnedPlan)
        setPlan(returnedPlan)
      }
    } else {
      navigate('/')
    }
  }

  return (
    <>
      <div className='font-poppins'>
        <div className='text-[#244B69] bg-no-repeat bg-cover bg-[80%_50%]' style={{backgroundImage: 'url(../images/user-area-bg.png)'}}>
          <div className='max-w-7xl mx-auto px-5 lg:px-10 space-y-5 py-10 lg:py-20'>
            <p className='text-lg lg:text-xl'>Hi <span className='font-bold text-amber-500'>{user.first_name} {user.last_name}</span> welcome to your self-assessment portal with Tax Magpie</p>
            <p className='text-red-500 text-lg lg:text-xl'>{claim.status}</p>
            <div className='space-y-2'>
              <p>Your Current Plan is</p>
              <div className='inline-flex items-center bg-white rounded-md md:w-[24rem] w-full drop-shadow-md'>
                <span className='uppercase bg-emerald-500 rounded-md text-white p-5'>{plan.type}</span>
                <span className='flex flex-col px-5'>
                  <span>{plan.price}</span>
                  <span className='text-sm lg:text-base'>({plan.description})</span>
                </span>
              </div>
              <p className='text-sm text-teal-500 pb-5'>If you want to upgrade your plan, Go ahead!</p>
            </div>
            <div className="block md:w-[24rem] w-full font-normal text-xl text-center">
              { (plan.price !== "£220 +VAT")
                ? <UpgradePlan text="Upgrade plan" plan={plan}/>
                : <Tooltip text="Upgrade Plan" content="No more upgrade required"/>
              }
            </div>
          </div>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-5 mx-auto max-w-7xl pb-7 pt-24 px-5 xl:px-0'>
          <div className='flex flex-col items-center justify-center text-center space-y-5 bg-[#244B69] text-white px-10 lg:px-5 py-5 rounded-md'>
            <div className='font-semibold text-base lg:text-sm xl:text-base h-10'>Current allowances and rates</div>
            <button className="text-[#244B69] py-2 px-5 text-sm bg-white text-center rounded-sm">
              Read More
            </button>
          </div>
          <div className='flex flex-col items-center justify-center text-center space-y-5 bg-[#244B69] text-white px-10 lg:px-5 py-5 rounded-md'>
            <p className='font-semibold text-base lg:text-sm xl:text-base h-10'>Helpful guides</p>
            <button
              className="text-[#244B69] py-2 px-5 text-sm bg-white text-center rounded-sm"
              onClick={() => navigate(`/guides`)}>
              Read More
            </button>
          </div>
          <div className='flex flex-col items-center justify-center text-center space-y-5 bg-[#244B69] text-white px-10 lg:px-5 py-5 rounded-md'>
            <p className='font-semibold text-base lg:text-sm xl:text-base h-10'>Calculators</p>
            <button 
              className="text-[#244B69] py-2 px-5 text-sm bg-white text-center rounded-sm"
              onClick={() => navigate('/resources')}>
              Read More
            </button>
          </div>
          <div className='flex flex-col items-center justify-center text-center space-y-5 bg-[#244B69] text-white px-10 lg:px-8 lg:px-5 py-5 rounded-md'>
            <span className='text-base lg:text-xs xl:text-sm h-10'>
              <p>Ask a question or Email Us:</p>
              <p className='text-xs lg:text-[.7rem] text-teal-500'>confused@taxmagpie.co.uk</p>
            </span>
            <button 
              className="text-[#244B69] py-2 px-5 text-sm lg:text-xs bg-white text-center rounded-sm"
              onClick={() => navigate(`/messages`)}>
              Send Message
            </button>
          </div>
          <div className='flex flex-col items-center justify-center text-center space-y-5 bg-[#244B69] text-white px-10 lg:px-5 py-5 rounded-md'>
            <p className='font-semibold text-base lg:text-sm xl:text-base h-10'>Documents</p>
            <button 
              className="text-[#244B69] py-2 px-5 text-sm lg:text-xs bg-white text-center rounded-sm"
              onClick={() => navigate(`/documents`)}>
              Add/Show Documents
            </button>
          </div>
        </div>

        <div className='grid grid-cols-1 lg:grid-cols-3 gap-5 mx-auto max-w-7xl py-28 px-5 xl:px-0'>
          <div className='relative border-[1.5rem] xl:border-[2rem] border-[#9FD2D2] border-opacity-50 text-center rounded-2xl lg:min-h-[50rem] mb-20 lg:mb-0 pb-10 shadow-[4px_6px_9px_0px_rgba(0,0,0,0.25)]'>
            <img className='absolute left-1/4 sm:left-64 lg:left-1/4 -top-24' src='../images/plan1.png' alt="Plan 1: Self-service tax refund"/>
            <p className='px-10 text-emerald-500 text-base xl:text-lg font-semibold mt-20 mb-5'>How to Complete Your Tax Return Online</p>
            <Fragment>
              <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(1)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  1-Check your Email
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Keep an eye on your inbox for an email from us.
                  Inside, you'll find a personalized questionnaire link.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(2)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  2-Access the Questionnaire
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Click the provided link to access your questionnaire.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(3)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  3-Fill in your Details
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Complete the questionnaire with your personal information.<br/>
                  Don't forget to add any extra details you think are important for an accurate tax return.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(4)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  4-Upload documents
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  If you have any documents required for your tax return, you can upload them directly within the questionnaire.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(5)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  5-Review your Return
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  After filling in the questionnaire, you'll see a summary of your tax return online.<br/>
                  No need to print anything.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(6)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  6-Sign and Submit
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Sign your tax return online.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 7} icon={<Icon id={7} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(7)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  7-Confirmation Email
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  You'll receive a confirmation email once your return has been successfully submitted.
                </AccordionBody>
              </Accordion>
            </Fragment>
          </div>
          <div className='relative border-[1.5rem] xl:border-[2rem] border-[#9FD2D2] border-opacity-50 text-center rounded-2xl lg:min-h-[50rem] mb-20 lg:mb-0 pb-10 shadow-[4px_6px_9px_0px_rgba(0,0,0,0.25)]'>
            <img className='absolute left-1/4 sm:left-64 lg:left-1/4 -top-24' src='../images/plan2.png' alt="Plan 2: Expert Review" />
            <p className='px-10 text-emerald-500 text-base xl:text-lg font-semibold mt-20 mb-5'>How to Get Your Tax Return Expertly Reviewed</p>
            <Fragment>
              <Accordion open={open === 8} icon={<Icon id={8} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(8)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  1-Complete the Questionnaire
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Fill out the questionnaire thoroughly.<br/>
                  Provide as much information as possible.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 9} icon={<Icon id={9} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(9)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  2-Expert Analysis
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Our experienced tax agents will review your return.<br/>
                  They'll contact you if they identify potential tax savings or have questions.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 10} icon={<Icon id={10} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(10)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  3-Note on Services
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Please note that this service does not include bookkeeping.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 11} icon={<Icon id={11} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(11)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  4-Review and sign
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  After the review, you'll receive a summary of your return.<br/>
                  Sign and submit it to HMRC.
                </AccordionBody>
              </Accordion>
            </Fragment>
          </div>
          <div className='relative border-[1.5rem] xl:border-[2rem] border-[#9FD2D2] border-opacity-50 text-center rounded-2xl lg:min-h-[50rem] pb-10 shadow-[4px_6px_9px_0px_rgba(0,0,0,0.25)]'>
            <img className='absolute left-1/4 sm:left-64 lg:left-1/4 -top-24' src='../images/plan3.png' alt="Plan 3: Personalized Accountant Assistance" />
            <p className='px-10 text-emerald-500 text-base xl:text-lg font-semibold mt-20 mb-5'>How to Benefit from Personalized Accountant Assistance</p>
            <Fragment>
              <Accordion open={open === 12} icon={<Icon id={12} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(12)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  1-Complete the Questionnaire
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Fill out the questionnaire as instructed.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 13} icon={<Icon id={13} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(13)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  2-Accountant Contact
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  An experienced tax accountant will get in touch with you to schedule a meeting.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 14} icon={<Icon id={14} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(14)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  3-Gather Documents
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Before the meeting, prepare all the necessary documents.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 15} icon={<Icon id={15} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(15)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  4-Meeting and Adjustment
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Your accountant will guide you through the tax return and make necessary adjustment.
                </AccordionBody>
              </Accordion>
              <Accordion open={open === 16} icon={<Icon id={16} open={open} />}>
                <AccordionHeader
                  onClick={() => handleOpen(16)}
                  className=" bg-gray-200 px-5 text-sm xl:text-base py-5 my-1 text-start font-normal"
                >
                  5-Review your Submit
                </AccordionHeader>
                <AccordionBody className="px-5 text-start text-[#244B69] text-sm xl:text-base ">
                  Sign and submit it to HMRC.
                </AccordionBody>
              </Accordion>
            </Fragment>
          </div>
        </div>

        <div className="mx-auto max-w-7xl pb-7 overflow-auto">
          <Surety />
          {/* <BacktoTop /> */}
          <div className='flex justify-end mt-10 mr-10'><Topbtn /></div>
        </div> 
      </div>
    </>
  );
}