import { useState } from "react";
import useCrmTabs from "../../../../hooks/useCrmTabs";

export default function AddUpdateMessages() {
    const { toggleDivVisibility, popupType, addMessage, editMessage, activeContent }= useCrmTabs()
    const [messageText, setMessageText] = useState(activeContent?.text || "")
  return (
        <>
            <div className="w-full h-full bg-black opacity-40 fixed top-0 left-0 z-10"></div>
            <div className="w-4/5 lg:w-[500px] max-md:max-h-[500px] overflow-scroll mx-auto pb-16 bg-white fixed top-0 right-0 z-20">
                <div className="flex items-center justify-between border-b border-cyan-900 p-5 w-full">
                    <div className="flex items-center w-full">
                        <div className=" text-black text-lg">{popupType === "new" ? "New Message" : "Edit Message"}</div>
                    </div>
                    <div onClick={toggleDivVisibility} className="cursor-pointer" >
                        <svg
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M17.1726 5.7832L5.97229 16.9835"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                            <path
                            d="M5.97217 5.78345L17.1725 16.9838"
                            stroke="black"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                        </svg>
                    </div>
                </div>
                <div className="px-5">
                    <div className="mt-7 text-lg">Message</div>     
                    <textarea 
                        onChange={(e) => setMessageText(e.target.value)}
                        className="bg-[#D9D9D9]/[54%] w-full outline-none p-5 text-lg"
                        cols="50"
                        rows="8"
                        value={messageText}
                    >
                    </textarea> 

                    {popupType === "new"
                     ?   <button onClick={() => addMessage(messageText)}
                        className="w-full mb-4 cursor-pointer text-white bg-emerald-500 px-7 py-4 mt-5 rounded-md text-base font-medium"
                        >
                            Send
                        </button> 
                    :   <button onClick={() => editMessage(messageText)}
                        className="w-full mb-4 cursor-pointer text-white bg-emerald-500 px-7 py-4 mt-5 rounded-md text-base font-medium"
                        >
                            Update
                        </button> }
                </div>
            </div>
        </>
  );
}
