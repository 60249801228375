import React from 'react'

const EmailTab = () => {
    return (
        <div className='border border-[#244B69] rounded-md lg:mt-7 p-5'>
            <div className="flex justify-end mb-5">
                <button className="border border-emerald-500 p-2 bg-emerald-500 text-white flex items-center gap-5 rounded-md justify-around lg:w-48">
                    <span className="text-sm lg:text-lg font-normal">Send Email</span>
                </button>
            </div>
            <div className='border-[#244B69]/[30%] border rounded-md flex max-md:flex-col justify-between items-center w-full'>
                <div className='flex gap-10 bg-[#D9D9D9]/[48%] items-center p-5 md:px-7 md:py-3 w-full lg:w-1/2'>
                    <p className='w-1/2'>Email Count:</p>
                    <p>2</p>
                </div>
                <div className='flex gap-10 items-center p-5 md:px-7 md:py-3 w-full lg:w-1/2'>
                    <p className='w-1/2'>Last Email Sent: </p>
                    <p>06-12-2023</p>
                </div>
            </div>
            <div className='mt-10'>
                <p className='font-semibold text-xl mb-2'>All Emails Sent</p>
                <div className='flex flex-col justify-between items-center w-full gap-5 max-h-[600px] overflow-scroll'>
                    <div className='border-[#244B69]/[30%] border rounded-md flex flex-col w-full px-5 py-3'>
                        <p>Created ( 1 day ago ) 15-03-2024 06:00 AM</p>
                        <p>Message: Get Client Claims Information</p>
                        <p>Comms Type: Chase Email</p>
                    </div>
                    <div className='border-[#244B69]/[30%] border rounded-md flex flex-col w-full px-5 py-3'>
                        <p>Created ( 2 days ago ) 15-03-2024 06:00 AM</p>
                        <p>Message: Get Client Claims Information</p>
                        <p>Comms Type: Chase Email</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailTab
