import { useNavigate } from "react-router";
import useCrmTabs from "../../../hooks/useCrmTabs";
import Warning from "./crmPopups/Warning";
import clientService from "../../../services/clientService";
import Backdrop from "../../../assets/common/Backdrop";

const SingleClient = ({ client, index, updateClients }) => {

  const { isWarningVisible, toggleWarningVisibility, deleteClient, deletePopup } = useCrmTabs();
  const navigate = useNavigate()
  const { id, ref, first_name, last_name, ni_number, email_address, phone_number } = client;

  const selectClient = ({ target: { checked } }) => {
    console.log(document.getElementById(`delete-${id}`));
    if (checked) {
      document.getElementById(`delete-${id}`).classList.remove("translate-x-20")
    } else {
      document.getElementById(`delete-${id}`).classList.add("translate-x-20")
    }
  }

  const deleteSelectedClient = () => {
    clientService.remove(client.id).then(data => {
      console.log("update");
      updateClients();
    }).catch(error => {
      console.log(error);
    })
    toggleWarningVisibility()
  }

  console.log(client?.plans[0]?.type);

  return (
    <div className="relative overflow-hidden content-wrapper">
      {/* Desktop View  */}
      <div className={`hidden lg:grid grid-cols-8 place-items-center text-sm lg:text-base text-zinc-600 ${index % 2 === 0 && 'bg-zinc-200'}`}>
        <div className="flex w-full justify-center border-r-2 border-[#D9D9D9] py-4">
          <div className="border border-[#D9D9D9] bg-[#D9D9D9]/30 flex justify-center items-center p-2">
            <input className="lg:w-6 lg:h-6" type="checkbox" onChange={selectClient} />
          </div>
          <button className="border border-[#D9D9D9] border-l-0 bg-[#D9D9D9]/30 flex justify-center items-center p-2 w-10 lg:w-auto" onClick={() => navigate(`/${ref}`)}>
            <svg
              width="32"
              height="19"
              viewBox="0 0 32 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.987183 9.50442C0.987183 9.50442 6.42547 1.34998 15.9425 1.34998C25.4595 1.34998 30.8978 9.50442 30.8978 9.50442C30.8978 9.50442 25.4595 17.6589 15.9425 17.6589C6.42547 17.6589 0.987183 9.50442 0.987183 9.50442Z"
                stroke={ client.plans.length > 0 ? (client?.plans[0]?.type === "Independent" ? "#AD8A56" : "#B4B4B4") : "#244B69"}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.9424 12.5624C18.195 12.5624 20.0211 11.1933 20.0211 9.50445C20.0211 7.81561 18.195 6.44653 15.9424 6.44653C13.6898 6.44653 11.8637 7.81561 11.8637 9.50445C11.8637 11.1933 13.6898 12.5624 15.9424 12.5624Z"
                stroke={ client.plans.length > 0 ? (client?.plans[0]?.type === "Independent" ? "#AD8A56" : "#B4B4B4") : "#244B69"}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        <div className="flex col-span-2 w-full h-full justify-center items-center border-r-2 border-[#D9D9D9] py-4">
          <p className="truncate">{ref}</p>
        </div>
        <div className="flex w-full h-full justify-center items-center border-r-2 border-[#D9D9D9] py-4">
          <p className="truncate capitalize">{first_name} {last_name}</p>
        </div>
        <div className="flex w-full h-full justify-center items-center border-r-2 border-[#D9D9D9] py-4">
          <p className="truncate">{ni_number}</p>
        </div>
        <div className="flex col-span-2 w-full h-full justify-center items-center border-r-2 border-[#D9D9D9] py-4">
          <p className="truncate">{email_address}</p>
        </div>
        <div className="flex w-full h-full justify-center items-center border-r-2 border-[#D9D9D9] py-4">
          <p className="truncate">{phone_number}</p>
        </div>
      </div>

      {/* Mobile View */}
      <div className={`flex lg:hidden justify-between bg-[#D9D9D9]/30 w-full ${index % 2 === 0 && 'bg-zinc-300'} text-[.7rem]`}>
        <div className="flex flex-col w-1/12 justify-center border-r border-[#D9D9D9] p-1">
          <div className="flex justify-center items-center ">
            <input type="checkbox" onChange={selectClient} />
          </div>
        </div>
        <div className="flex flex-wrap w-11/12 font-extralight" onClick={() => navigate(`/${ref}`)}>
          <div className="flex flex-col w-5/12 h-1/2 justify-center items-start border-r border-[#D9D9D9] p-2 truncate">
            <p className="font-semibold">Ref</p>
            <p>{ref}</p>
          </div>
          <div className="flex flex-col w-4/12 h-1/2 justify-center items-start border-r border-[#D9D9D9] p-2 truncate">
            <p className="font-semibold">Name</p>
            <p className="capitalize truncate">{first_name} {last_name}</p>
          </div>
          <div className="flex flex-col w-3/12 h-1/2 justify-center items-start border-r border-[#D9D9D9] p-2 truncate">
            <p className="font-semibold">NI Number</p>
            <p>{ni_number}</p>
          </div>
          <div className="flex flex-col w-6/12 h-1/2 justify-center items-start border-r border-t border-[#D9D9D9] p-2 truncate">
            <p className="font-semibold">Email</p>
            <p>{email_address}</p>
          </div>
          <div className="flex flex-col w-6/12 h-1/2 justify-center items-start border-r border-t border-[#D9D9D9] p-2 truncate">
            <p className="font-semibold">Mobile Number</p>
            <p>{phone_number}</p>
          </div>
        </div>
      </div>

      <button className="absolute right-0 top-0 translate-x-20 transition-all bg-[#B60C0C] rounded-l-md p-4 h-full lg:p-6" id={`delete-${id}`} onClick={(e) => deletePopup(e, client)}>
          <svg
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 5.89938H3H19"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 5.89938V19.8994C17 20.4298 16.7893 20.9385 16.4142 21.3136C16.0391 21.6887 15.5304 21.8994 15 21.8994H5C4.46957 21.8994 3.96086 21.6887 3.58579 21.3136C3.21071 20.9385 3 20.4298 3 19.8994V5.89938M6 5.89938V3.89938C6 3.36895 6.21071 2.86024 6.58579 2.48517C6.96086 2.1101 7.46957 1.89938 8 1.89938H12C12.5304 1.89938 13.0391 2.1101 13.4142 2.48517C13.7893 2.86024 14 3.36895 14 3.89938V5.89938"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M8 10.8994V16.8994"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 10.8994V16.8994"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>

      {isWarningVisible &&
        <div className="w-full h-full flex place-content-center">
          <Backdrop />
          <Warning toggle={toggleWarningVisibility} deleteContent={deleteSelectedClient} />
        </div>
      }
    </div>
  );
}

export default SingleClient;